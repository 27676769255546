 
export const dashboardRoutes = {
  label: 'dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      children: [
        {
          name: 'Main',
          to: '/dashboard',
          exact: true,
          active: true
        } 
      ]
    }
  ]
};
  
  
export const masterRoutes = {
  label: 'Pages',
  children: [ 
    {
      name: 'My Wallet List',
      active: true,
      icon: 'wallet',
      to: '/my-wallet-list',
    }, 
    {
      name: 'My Payment Transaction',
      active: true,
      icon: 'wallet',
      to: '/my-transaction',
    },  
    {
      name: 'Patients',
      active: true,
      icon: 'users',
      children: [
        {
          name: 'Add',
          to: '/patient/add',
          active: true
        },
        {
          name: 'List',
          to: '/patient/list',
          active: true
        }  
      ]
    },
    {
      name: 'Reports',
      active: true,
      icon: 'file',
      children: [
        {
          name: 'Add',
          to: '/report/add',
          active: true
        },
        {
          name: 'List',
          to: '/report/list',
          active: true
        }  
      ]
    },
    {
      name: 'Contact',
      active: true,
      icon: 'info-circle',
      to: '/contact',
    },   
    {
      name: 'Guide',
      active: true,
      icon: 'video',
      to: '/videos',
    },     
  ]
};
 

export default [
  dashboardRoutes,
  masterRoutes 
];

import React from 'react';
import { Link } from 'react-router-dom'; 
import RegistrationForm from './RegistrationForm'; 
import { Button } from 'react-bootstrap';
import AuthCardLayout from 'layouts/AuthCardLayout';
const Registration = () => {
  return ( 
    <AuthCardLayout
      leftSideContent={
        <p className="pt-3 text-black">
          Have an account?
          <br />
          <Button
            as={Link}
            variant="outline-dark"
            className="mt-2 px-4"
            to="/"
          >
            Log In
          </Button>
        </p>
      }
      footer={false}
    >
      <h3>Register</h3>
      <RegistrationForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

/*
const Registration = () => {
  return (
    <AuthSplitLayout bgProps={{ image: bgImg }}>
      <Flex alignItems="center" justifyContent="between">
        <h3>Register</h3>
        <p className="mb-0 fs-10">
          <span className="fw-semibold">Already User? </span>
          <Link to="/login">Login</Link>
        </p>
      </Flex>
      <RegistrationForm layout="split" hasLabel />
    </AuthSplitLayout>
  );
};
*/
export default Registration;

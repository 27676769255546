import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import React, { useState ,useEffect} from 'react';
import { Button, Col, Form, Row,Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ApiCall } from './../includes/Webservice';
import { BaseUrl, encryptAES ,showDBdatetime ,getUserStorageData} from './../includes/Helper'; 
import { checkTokenFunction } from './../includes/CheckToken'; 
//const minimum_amount = 1000;

const PaymentForm = ({ hasLabel, layout }) => {
  const baseurl = BaseUrl();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [isloading, setLoad] = useState(false); 
  const userinfo = getUserStorageData();
 // console.log(userinfo)
 
 const [username, setUsername] = useState(userinfo.user_name);
 const [basic_amount, setBasicAmt] = useState('');
 const [gst_amount, setGstAmt] = useState('');
 const [amount, setAmt] = useState('');
 const [gstno, setGstNo] = useState('');
 const [isPageloading, setPageLoad] = useState(false);
 const [email, setEmail] = useState('');
 const [fullname, setName] = useState('');
 const [country, setCountry] = useState('');
 const [contact, setcontact] = useState('');
 const controller = new AbortController();
 const [page, setPage] = useState(1);
 const [paymode, setpaymode] = useState('test');
 const [minimum_amount, setMinmumAmt] = useState(1000);
 const [stoppayment, setStop] = useState(true);
 checkTokenFunction();
 useEffect(() => {
  
  onLoad(); 
  return () => {
    // Anything in here is fired on component unmount.
    //  console.log('Unmount Component')
    //  controller.abort();
  }

}, [])

 const onLoad = async () => {
  setPageLoad(true) 

 const data ={ } 
  const result = await ApiCall(baseurl + "/payment/user", "post",  1,0, 0, data, '', controller);

  if (result) { 
    setPageLoad(false)
    if (result.status ==  true) {  
      setName(result.data.first_name+result.data.last_name)
      setEmail(result.data.email)
      setcontact(result.data.mobile) 
      setCountry(result.data.country)
      setpaymode(result.paymode)
      setMinmumAmt(result.minimum_amount)
      setGstNo(result.gstno)
      
      if(result.data.address == '' || result.data.city=='' || result.data.state == '' || result.data.pincode =='')
        setStop(true)
      else
        setStop(false)
    } else { 
    }
  } else {
    setPageLoad(false)
  } 

}
  
  // Handler
  const handleSubmit = async e => {
    e.preventDefault();
 
      if (!username) {
        toast.error(`Username missing` );
        return false;
      }        
      if (!basic_amount) {
        toast.error(`Enter amount` );
        return false;
      }   
      if (basic_amount < minimum_amount) {
        toast.error(`Minimum amount must be greater than ${minimum_amount}` );
        return false;
      }   

  if(paymode == 'test') {    
      var RAZOR_PAY_KEY_ID = 'rzp_test_ghq6DRerR5Eqo0'     // test key
   }else{
      var RAZOR_PAY_KEY_ID = 'rzp_live_AmVxvtzODGaUMb'     // test key
   }
   
    const data ={basic_amount : basic_amount , gst_amount : gst_amount ,amount : amount ,gstno :gstno }
    const result = await ApiCall(baseurl + "/payment/order", "post", 1,0,0, data);
    setLoad(true);
    if (result) {
     
      if (result.status == true) {
         //   console.log(result.data)
            const order_id = result.data.id 

            const options = {
            key: RAZOR_PAY_KEY_ID,
            name: "IdentifyX",
            description: "Wallet amount",
            image  : "https://identifyx.in/images/logo.png",
            order_id: order_id,
            prefill : {
              name : fullname,
              email : email,
              contact : contact,
            },
            handler: async (response) => {
                try {  
                 const paymentId = response.razorpay_payment_id;
                 const postdata ={basic_amount : basic_amount , gst_amount : gst_amount ,amount : amount ,order_id : order_id ,gstno :gstno}
              //   console.log('SSS',paymentId)
                const result_payment = await ApiCall(baseurl + "/payment/capture/"+paymentId, "post", 1,0,0, postdata);
              // console.log('AAA',result_payment) 
                        if(result_payment)
                        {
                            //setPayStatus(true)
                            //setPayData(result_payment.data)
                            navigate('/paystatus/'+result_payment.data);
                        }
                } catch (err) {
                  toast.error(err)
                //console.log(err);
                }
            },
            theme: {
                color: "#686CFD",
            },
            };
            const rzp1 = new window.Razorpay(options);
          
            rzp1.open();  

        
      } else {
        setLoad(false);
        toast.error(`${result.message}` );

      }
    } else {
      setLoad(false);
      toast.error(`Some error occured, try again later` );
    } 

    /*

    const API_URL = 'http://localhost:5000/'
    
    const orderUrl = `${API_URL}order`;
    const response = await axios.get(orderUrl);
    const { data } = response;
    const options = {
    key: RAZOR_PAY_KEY_ID,
    name: "IndentifyX",
    description: "Fill your wallet amount",
    order_id: data.id,
    handler: async (response) => {
        try {
            console.log(response)
        const paymentId = response.razorpay_payment_id;
        const url = `${API_URL}capture/${paymentId}`;
        const captureResponse = await axios.post(url, {})
        console.log(captureResponse.data);
        } catch (err) {
        console.log(err);
        }
    },
    theme: {
        color: "#686CFD",
    },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
 
    */
     
  };
  const calAmt=(basicamount)=>{    
      setBasicAmt(basicamount)
      var gstamount = (basicamount*(0.18))
      gstamount = Math.round(gstamount);
      setGstAmt(gstamount.toFixed(2))
      var tot = (parseFloat(basicamount)+parseFloat(gstamount));       
      setAmt(tot.toFixed(2))
  }
  const changeAmt=()=>{
    setPage(1)
  }
 const payNowButton=()=>{
    if (!username) {
      toast.error(`Username missing` );
      return false;
    }        
    if (!basic_amount) {
      toast.error(`Enter amount` );
      return false;
    }   
    if (basic_amount <minimum_amount) {
      toast.error(`Minimum amount must be greater than ${minimum_amount}` );
      return false;
    }  
    setPage(2)
 }

  return ( 
   <>
    {stoppayment ==  true ? 
    <div className='text-center'>Before initiating the payment process, please update your profile. 
      <br/>Thank you !<br/><br/>
      <Link to="/editprofile">Click here to Update Profile</Link></div>
    
    : country != 'India' ?
      <div className='text-center'>Please contact the support on <a href="mailto:contact@identifyx.in">contact@identifyx.in</a> for international payments. 
      <br/>Thank you !<br/><br/>
    </div>
    :
  
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
      <Form.Label> Username <small className='text-danger'>(Wallet amount to be added in this account)</small></Form.Label>
        <Form.Control
          placeholder={!hasLabel ? 'Username' : ''}
          value={username}
          name="username"
          type="text"
          readOnly ={true}
        />
      </Form.Group>
      
      <Form.Group className="mb-3">
      <Form.Label> GST No. <small className='text-danger'>(If you any, eg. 23ABUFA2538E1ZJ)</small></Form.Label>
        <Form.Control
          placeholder={!hasLabel ? ' GST No.' : ''}
          value={gstno}
          name="gstno"
          type="text" 
          maxLength={15}
          onChange={(e)=>setGstNo(e.target.value)}
        />
      </Form.Group> 

      <Form.Group className="mb-3"> 
      <Form.Label> Amount <small className='text-danger'>(Minimum amount must be greater than {minimum_amount})</small></Form.Label>
        <Form.Control
          placeholder={!hasLabel ? 'Amount' : ''}
          value={basic_amount}
          name="basic_amount"
          onChange={(e)=>calAmt(e.target.value)}
          type="number" 
          readOnly ={page==2 ? true : false}
        /> 
      </Form.Group> 
      {page ==2 &&
      <Form.Group className="mb-3"> 
       <Form.Label>GST Amount <small className='text-danger'>(18% GST)</small></Form.Label>
        <Form.Control
          placeholder={!hasLabel ? 'GST Amount' : ''}
          value={gst_amount}
          name="gst_amount"
          type="number" 
          readOnly={true}
        /> 
      </Form.Group> 
    }
     {page ==2 &&
      <Form.Group className="mb-3"> 
       <Form.Label>Total Amount <small className='text-danger'>(Total Amount With GST)</small></Form.Label>
        <Form.Control
          placeholder={!hasLabel ? 'Total Amount' : ''}
          value={amount}
          name="amount"
          type="number"
          readOnly ={true}  
        /> 
      </Form.Group> 
    }
      <Form.Group>
      
      {page ==1 &&
        <Button
          type="button"
          color="primary"
          className="mt-3 w-100 rounded-pill "
          disabled={isloading}
          onClick={payNowButton}
        >
         {isloading ? 'Please wait, we are processing your payment..' : 'Add Amount'}
        </Button>
       }

      {page ==2 &&
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100 rounded-pill btn-success"
          disabled={isloading}
        >
         {isloading ? 'Please wait, we are processing your payment..' : 'Confirm & Pay Now'}
        </Button>
       }
      </Form.Group>
     
      {page ==2 &&
       
        <p className='pt-3 text-center'> 
        <Link onClick={changeAmt}><small>Need to change amount ? </small></Link><br/>
        By clicking Confirm & Pay Now button you agree to the <Link to="https://identifyx.in/Terms-Conditions.html" target='_blank'>Terms & Conditions </Link></p>
        
     }

    </Form>   
  }
   </>
  );
};

 

export default PaymentForm;

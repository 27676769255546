import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { Button } from 'react-bootstrap';
import { ApiCall } from '../../../includes/Webservice';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate, showFileType, ValidFileType, FileUrlPath, states_array } from '../../../includes/Helper';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FalconCardHeader from 'components/common/FalconCardHeader';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { nanoid } from 'nanoid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PaymentForm from 'pages/PaymentForm';
const PaymentNow = () => {
    const params = useParams();

    return (

        <FormExample />

    );
};

function FormExample({ patientid }) {


    React.useEffect(() => {

    }, [])


    return (

        <>
            <div className="flex-center min-vh-50 row">
                <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-8 col-sm-10">
                    <div className='card'>
                        <div className='p-4 p-sm-5 card-body'>
                           <div className="justify-content-between align-items-center mb-4"><h5 className='text-center'>Add Amount In Your Wallet</h5></div>
                              <PaymentForm />
                        </div>  
                    </div>
                </div>
            </div>
        </>

    );
}

export default PaymentNow;

import React,{forwardRef} from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate, taskPriorityTextColor } from '../../../includes/Helper';
import { ApiCall } from '../../../includes/Webservice';
import { Row, Col,Form } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import IconButton from 'components/common/IconButton';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import Spinner from 'react-bootstrap/Spinner'; 
const PaymentList = (hospitalid) => {

    const DateCustomInput = forwardRef(({ value, onClick ,placeholder }, ref) => (
       
        <div className="position-relative"> 
            <Form.Control
                ref={ref}
                onClick={onClick}
                value={value}
                onChange={() => { }} 
                placeholder={placeholder}
            />
             
        </div>
    ));
    const month_ago = new Date();
    month_ago.setMonth(month_ago.getMonth() - 3);
    const navigate = useNavigate();
    const baseurl = BaseUrl();
    const controller = new AbortController();
    const [data, setData] = React.useState([]);
    const [isPageloading, setPageLoad] = React.useState(false);
    const [type,setType] = React.useState('');
    const [doctor_id, setDoc] = React.useState({value : '' ,label : 'All '});
    const [createby, setCreateBy] = React.useState({value : '' ,label : 'All '});
    const [fromdate, setFromDate] = React.useState(month_ago);
    const [todate, setToDate] = React.useState( new Date());
    const [createby_array,setCrateByArray] = React.useState([]);
    const [doctor_array,setDocArray] = React.useState([]);
    const userinfo = getUserStorageData();
    React.useEffect(() => {
        onLoad();
       
        if(userinfo.user_type == 'SUPERADMIN')
        getCreateby();

        return () => {
            // Anything in here is fired on component unmount.
            //    console.log('Unmount Component')
            controller.abort();
        }

    }, [])

    const getCreateby = async () => {
     //   setPageLoad(true)
         const data = {};
         const result = await ApiCall(baseurl + "/payment/createby", "post",  1,0, 0, data, '', controller);
        if (result) {
         //   setPageLoad(false)
            if (result.status == true) {  
                const arr = result.data;
                const emp_arr = [] 
                var newObj = {value : '' ,label : 'All '}  
                emp_arr.push(newObj) 
                for (let i = 0; i < arr.length; i++) {   
                        var newObj = {value : arr[i]._id ,label : (arr[i].first_name+' '+arr[i].last_name)}  
                        emp_arr.push(newObj)                      
                } 
                setCrateByArray(emp_arr) 
 

            } else {
                //  navigate('/');
                toast.info(result.message);
              }
          } else {           
            //  setPageLoad(false);
              toast.error(`Some error occured, try again later` );
              //navigate('/');
          }
      }
 
    const onLoad = async () => {
        setPageLoad(true)
         const data = {  createby :createby.value , fromdate : fromdate ,todate:todate};
         const result = await ApiCall(baseurl + "/payment/list", "post",  1,0, 0, data, '', controller);
        if (result) {
            setPageLoad(false)
            if (result.status == true) {                 
                    setData(result.data)
            } else { 
              toast.info(result.message);
            }
        } else {           
            setPageLoad(false)
            toast.error(`Some error occured, try again later` ); 
        }
    } 

    const columns = [
        {            
            Header: 'S. No.',
            disableSortBy: true,
            headerProps: { style: { width: '100px' }, className: 'text-900' },
            Cell: rowData => {
                return rowData.row.index + 1;
            }
        },
        {
            accessor: 'invoice_no',
            Header: 'Invoice No',
            headerProps: { className: 'text-900' } 
         },
        {
            accessor: 'order_id',
            Header: 'Order ID',
            headerProps: { className: 'text-900' } 
         },
         {
             accessor: 'payment_id',
             Header: 'Payment ID',
             headerProps: { className: 'text-900' } 
          },
        {
           accessor: 'order_amount',
           Header: 'Amount',
           headerProps: { className: 'text-900' } 
        },
        {
            accessor: 'payment_status',
            Header: 'Status',
            headerProps: { className: 'text-900' } 
         },
         {
            accessor: 'method',
            Header: 'Method',
            headerProps: { className: 'text-900' } 
         },
        { 
            Header: 'Hospital / Doctor Name',
            headerProps: { className: 'text-900' } ,
            Cell: rowData => {           
                const { createby } = rowData.row.original;
                return <div>  { createby.first_name+' '+createby.last_name } </div>
            }
        },
        { 
              Header: 'Date',
              headerProps: { className: 'text-900' } ,
              Cell: rowData => {
               const { createdate } = rowData.row.original;
               return <div>{showDBdatetime(createdate)}</div>
              }
          },
          { 
            Header: '#',
            headerProps: { className: 'text-900' },
            Cell: rowData => { 
                const { _id } = rowData.row.original;
                return <div><Link className='me-2' target='_blank' to={`${baseurl}/payment/view/${_id}`}><FontAwesomeIcon   icon={'print'} /></Link>   </div> 
                } 
            }  
    ];  

    const search=()=>{ 
        onLoad()
    }

    return (
        <>
            <Row className="g-3 mb-3">

                <Col>
                    <FalconComponentCard>
                        <FalconCardHeader
                            title="Payment Transaction List"
                            light={false}
                            endEl={<Breadcrumb className='fs-11'>
                                <Breadcrumb.Item active><Link to="/dashboard"> Dashboard </Link></Breadcrumb.Item>
                                <Breadcrumb.Item active>Payment Transaction List </Breadcrumb.Item> 
                                
                            </Breadcrumb>}  
                        />
   {
                        isPageloading ?
                        <div className='text-center py-5'><Spinner animation="grow"   /></div>
                        : 
                        <FalconComponentCard.Body>
 
                            <AdvanceTableWrapper
                                columns={columns}
                                data={data}
                                sortable
                                pagination
                                perPage={25}
                            >
                                {!(hospitalid.hospitalid)  ?
                                <Row>
                                    {/*}
                                <Col xs={12} sm={2} lg={2} className='mb-2'> 
                                 <Form.Label>Type </Form.Label>
                                               
                                    <Form.Select aria-label="Status"
                                        value={type}
                                        onChange={(e) => setType(e.target.value)}
                                    > 
                                        <option key={1} value={''}> All </option>
                                        <option key={2} value={'ADD'}> Add </option>
                                        <option key={3} value={'USED'}> Used </option>
                                            
                                    </Form.Select> 
                               </Col> {*/}
 
                             {userinfo.user_type == 'SUPERADMIN' ?
                                 <Col xs={12} sm={4} lg={4} className='mb-2'> 
                                 <Form.Label>Hospital/Doctor  </Form.Label>
                                     <Select  
                                    closeMenuOnSelect={true} 
                                    options={createby_array}
                                    placeholder='Select User'
                                    classNamePrefix="react-select"
                                    value={createby}
                                    onChange={e => setCreateBy(e)}
                                    />
                               </Col>
                               : ''
                            }
                               <Col xs={12} sm={12} lg={2} >
                               <Form.Label>From Date </Form.Label>
                                        <DatePicker
                                            aria-label="From Date"
                                            selected={fromdate}
                                            onChange={(e) => setFromDate(e)}
                                            formatWeekDay={day => day.slice(0, 3)}
                                            className='form-control'
                                            placeholderText="From Date"
                                            dateFormat="dd/MM/yyyy"
                                            fixedHeight
                                            customInput={<DateCustomInput   />}
                                           
                                        /> 
                                    </Col>

                                    <Col xs={12} sm={12} lg={2} >
                                    <Form.Label>To Date </Form.Label>
                                        <DatePicker
                                            aria-label="To Date"
                                            selected={todate}
                                            onChange={(e) => setToDate(e)}
                                            formatWeekDay={day => day.slice(0, 3)}
                                            className='form-control'
                                            placeholderText="To Date"
                                            dateFormat="dd/MM/yyyy"
                                            fixedHeight
                                            customInput={<DateCustomInput   />}
                                           
                                        /> 
                                    </Col>   

                                 <Col xs={12} sm={2} lg={2} > 
                                 <Form.Label> </Form.Label>
                                            <div className="d-grid"> 
                                                <IconButton 
                                                    variant={'falcon-primary'}
                                                    className='mb-1'
                                                    icon="search"  
                                                    onClick={search}
                                                > Search</IconButton>
                                            </div>
                                           
                                    </Col>
                                </Row> 
                                : '' 
                                } 
                                <AdvanceTable
                                    table
                                    headerClassName="bg-200 text-nowrap align-middle"
                                    rowClassName="align-middle white-space-nowrap"
                                    tableProps={{
                                        bordered: true,
                                        striped: true,
                                        className: 'fs-10 mb-0 overflow-hidden'
                                    }}
                                />
                                <div className="mt-3">
                                    <AdvanceTablePagination
                                        table
                                    />
                                </div>
                                 
                            </AdvanceTableWrapper>
                        </FalconComponentCard.Body>
}
                    </FalconComponentCard>
                </Col>
            </Row>
        </>
    );
};

export default PaymentList;

import React, { useState } from 'react';
import { Col, Form, Row ,ListGroup} from 'react-bootstrap';
import { toast } from 'react-toastify';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { Button } from 'react-bootstrap';
import { ApiCall } from '../../../includes/Webservice';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate } from '../../../includes/Helper';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { checkTokenFunction } from '../../../includes/CheckToken'; 
import Spinner from 'react-bootstrap/Spinner'; 
import SubtleBadge from 'components/common/SubtleBadge';
import Flex from 'components/common/Flex';
const Setting = () => {
    const params = useParams();
    checkTokenFunction()
    return (
        <Row className="g-3">
            <FalconComponentCard>
                <FalconCardHeader
                    title={"Setting"}
                    light={false}  
                    endEl={<Breadcrumb className='fs-11'>
                        <Breadcrumb.Item active><Link to="/dashboard"> Dashboard </Link></Breadcrumb.Item>
                        <Breadcrumb.Item active> Setting  </Breadcrumb.Item> 
                        
                    </Breadcrumb>}                
                />

                <FalconComponentCard.Body>
                    <FormExample />
                </FalconComponentCard.Body>
            </FalconComponentCard>




        </Row>
    );
};

function FormExample({ }) {

    const [validated, setValidated] = useState(false);
    const baseurl = BaseUrl();
    const navigate = useNavigate();
    const controller = new AbortController();
    const [isloading, setLoad] = useState(false);
    const [setting_name, setSettingName] = useState('Report Form is Under Maintenance');
    const [setting_type, setSettingType] = useState('Report_Form_Maintenance');
    const [setting_value, setSettingValue] = useState(0);

    const [setting_name1, setSettingName1] = useState('Enable Generate Quick Report Button');
    const [setting_type1, setSettingType1] = useState('Quick_Report_Button');
    const [setting_value1, setSettingValue1] = useState(0);

    React.useEffect(() => { 
        getSetting()
    }, [])

    const handleSubmit = (event) => {
 
            saveData(); 
    };

    const getSetting = async () => {
        const data = {}
        const result = await ApiCall(baseurl + "/setting/get", "post",  1,0, 0, data, '', controller);

        if (result) {            
            if (result.status == true) {    
                    if(result.data.length > 0)
                    {
                      for(var i=0; i< result.data.length ; i++)  
                        {
                            if(result.data[i].setting_type == setting_type)
                            {
                                setSettingValue(result.data[i].setting_value) 
                            }
                            if(result.data[i].setting_type == setting_type1)
                            {
                                setSettingValue1(result.data[i].setting_value) 
                            }
                        }
                    
                    }
            } else {
                navigate('/');
                toast.error(result.message);
            }
        } else {

            toast.error(`Some error occured, try again later` );
            navigate('/');
        }

    }

    

    const saveData = async () => {
  
        var url = baseurl + "/setting/set";
        
        setLoad(true);

        const data ={
            setting_name:setting_name,
            setting_type:setting_type,
            setting_value : setting_value,
            setting_name1:setting_name1,
            setting_type1:setting_type1,
            setting_value1 : setting_value1
        } 
        
        const result = await ApiCall(url, "post",  1,0, 0, data, '', controller);
  
        if (result) {
            setLoad(false);
            if (result.status == true) {               
                toast.success(result.message); 
            }else{
                toast.error(result.message);
            }
        } else {
            setLoad(false);
            toast.error(`Some error occured, try again later` );
            navigate('/');
        }

    } 
    const setActive = () => {
        if (setting_value == 0)
            setSettingValue(1);
        else
          setSettingValue(0);
    }
    const setActive1 = () => {
        if (setting_value1 == 0)
            setSettingValue1(1);
        else
          setSettingValue1(0);
    }
    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">

                <ListGroup>
                    <ListGroup.Item>
                        <Flex justifyContent="between" alignItems="center">
                        {setting_name}
                       
                        <div  bg='primary' className='me-2'>  
                            <Form.Check
                                    type='checkbox'
                                    id='is_active'
                                    label='Tick for Yes'
                                    onChange={setActive}
                                    checked={setting_value==1 ? true : false}
                            />    
                        </div>
                        </Flex>
                    </ListGroup.Item>
        {/*}
                    <ListGroup.Item>
                        <Flex justifyContent="between" alignItems="center">
                        {setting_name1}
                       
                        <div  bg='primary' className='me-2'>  
                            <Form.Check
                                    type='checkbox'
                                    id='is_active'
                                    label='Tick for Yes'
                                    onChange={setActive1}
                                    checked={setting_value1==1 ? true : false}
                            />    
                        </div>
                        </Flex>
                    </ListGroup.Item>
        {*/}        
                </ListGroup>
 
           
            </Row>

            <Form.Group className="mb-12 text-center">
                <Button disabled={isloading} variant="outline-primary" type="submit">  
                {isloading ? 
                <>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className='me-2'
                    /> Loading… </>
                 : 'Submit'}
                </Button>
            </Form.Group>

        </Form>
    );
}

export default Setting;

import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { Button } from 'react-bootstrap';
import { ApiCall } from '../../../includes/Webservice';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate } from '../../../includes/Helper';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FalconCardHeader from 'components/common/FalconCardHeader';
const ApiUrl = () => {
    const params = useParams();

    return (
        <Row className="g-3">
            <FalconComponentCard>
                <FalconCardHeader
                    title={params.api_id ? "Edit API" : "Add API"}
                    light={false}
                    endEl={<Breadcrumb className='fs-11'>
                        <Breadcrumb.Item active><Link to="/dashboard"> Dashboard </Link></Breadcrumb.Item>
                        <Breadcrumb.Item active><Link to="/apiurl/list"> API  </Link></Breadcrumb.Item> 
                        <Breadcrumb.Item active>
                       {params.api_id  ? 'Edit' :'Add'}
                        </Breadcrumb.Item>
                    </Breadcrumb>}
                />

                <FalconComponentCard.Body>
                    <FormExample apiid={params.api_id} />
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </Row>
    );
};

function FormExample({ apiid }) {

    const [validated, setValidated] = useState(false);
    const [api_type, setApiType] = useState(1);
    const [api_name, setApiName] = useState('');
    const [api_id, setApiId] = useState('');
    const [is_active, setIsActive] = useState(0);
    const [api_url, setApiUrl] = useState('');
    const baseurl = BaseUrl();
    const navigate = useNavigate();
    const controller = new AbortController();
    const [isloading, setLoad] = useState(false);

    React.useEffect(() => {
        if (apiid)
        editData(apiid);
    }, [])

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === true) {
            saveData();
        }
        setValidated(true);
    };

    const editData = async (api_id) => {
 
        const data ={
            api_id:api_id 
        } 
        const result = await ApiCall(baseurl + "/apiurl/get", "post",  1,0, 0, data, '', controller);

        if (result) {
            // setPageLoad(false)
            if (result.status == true) {             
                setApiId(result.data._id)
                setApiType(result.data.api_type)
                setApiName(result.data.api_name) 
                setApiUrl(result.data.api_url) 
                setIsActive(result.data.is_active) 
            } else {
                navigate('/');
                toast.error(result.message);
            }
        } else {
            // setPageLoad(false)
            toast.error(`Some error occured, try again later` );
            navigate('/');
        }

    }

    const saveData = async () => {
 
        if (api_type.trim() == '' || api_name.trim() == '' || api_url.trim() == '') {
            toast.error('Please enter all required fields');
            return false;
        }  

        if (api_id == '')
            var url = baseurl + "/apiurl/add";
        else
            var url = baseurl + "/apiurl/update";
            
        setLoad(true);

        const data ={
            api_type:api_type,
            api_name:api_name,
            api_url : api_url,
            api_id : api_id,
            is_active:is_active
        } 
        
        const result = await ApiCall(url, "post",  1,0, 0, data, '', controller);
  
        if (result) {
            setLoad(false);
            if (result.status == true) {               
                toast.success(result.message);
                navigate('/apiurl/list');
            }else{
                toast.error(result.message);
            }
        } else {
            setLoad(false);
            toast.error(`Some error occured, try again later` );
            navigate('/');
        }

    } 

    const setActive = () => {
        if (is_active == 0)
            setIsActive(1);
        else
        setIsActive(0);
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="api_type">
                    <Form.Label>API Type <span className='text-danger'>*</span></Form.Label>
                    <Form.Select
                        required 
                        value={api_type}
                        onChange={(e) => setApiType(e.target.value)}
                    >
                            <option value={''}>Select</option>
                         
                             <option key={1} value={'api_convert_image'}>{'Convert Diacom to PNG'} </option>
                             <option key={2} value={'api_check_image'}>{'Check Image'} </option>
                             <option key={3} value={'api_process_image'}>{'Process Image'} </option>
                             <option key={4} value={'api_convert_zip'}>{'Zip API'} </option>
                          
                        </Form.Select>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="api_url">
                    <Form.Label>API URL <span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                        required
                        type="text"
                        maxLength={300}
                        placeholder="API URL"
                        defaultValue={api_url}
                        onChange={(e) => setApiUrl(e.target.value)}
                    />
                </Form.Group>
                
               
                <Form.Group as={Col} md="6" controlId="api_name">
                    <Form.Label>API Name & Remark <span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                        required
                        type="text"
                        maxLength={100}
                        placeholder="API Name"
                        defaultValue={api_name}
                        onChange={(e) => setApiName(e.target.value)}
                    />
                </Form.Group>
           
                <Form.Group as={Col} md="6" controlId="is_active">
                    <Form.Label>Is Active  <span className='text-danger'>*</span></Form.Label>

                    <Form.Check
                            type='checkbox'
                            id='is_active'
                            label='Tick for Yes'
                            onChange={setActive}
                            checked={is_active==1 ? true : false}
                     /> 
                   
                </Form.Group> 

                
            </Row>

            <Form.Group className="mb-12 text-center">
                <Button disabled={isloading} variant="outline-primary" type="submit">  {isloading ? 'Loading…' : 'Submit'}</Button>
            </Form.Group>

        </Form>
    );
}

export default ApiUrl;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col ,Image} from 'react-bootstrap'; 
import { ApiCall } from './../includes/Webservice';
import { BaseUrl, encryptAES ,country_array} from './../includes/Helper';
import Spinner from 'react-bootstrap/Spinner'; 
import docImg from 'assets/img/doctor.png';
import hosImg from 'assets/img/hospital.png';

const RegistrationForm = ({ hasLabel }) => {
  // State
  const [isloading, setLoad] = useState(false);
  const baseurl = BaseUrl();
  const navigate = useNavigate();
  const [reg_for, setRegFor] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    user_type: 'hospital',
    hospital_name: '',
    first_name: '',
    last_name: '',
    email: '',
    mobile: '',
    username: '',
    password: '',
    confirmPassword: '',
    country: 'India',
    isAccepted: false
  });

  // Handler
  const handleSubmit =async e => {
    e.preventDefault();

    if (!formData.user_type.trim() || !formData.hospital_name.trim() || !formData.first_name.trim() || !formData.last_name.trim() || !formData.email.trim() || !formData.mobile.trim() || !formData.username.trim() || !formData.password.trim() || !formData.confirmPassword.trim() || !formData.isAccepted  || !formData.country) 
    {
        toast.error(`Enter all required fields` ); 
        return false;
      }
      
      if(formData.password.trim() !=  formData.confirmPassword.trim() )
      {
        toast.error('Password & confirm password does not match ');
        return false; 
      }  
    
    setLoad(true);
    
    const data = {
        user_type : formData.user_type,
        hospital_name : formData.hospital_name,
        first_name : formData.first_name,
        last_name : formData.last_name,
        email : formData.email,
        mobile : formData.mobile,
        username : formData.username,
        password : formData.password,
        country :  formData.country
    }
    const result = await ApiCall(baseurl + "/user/signup", "post", 0,0,0, data);

    if (result) {
      setLoad(false);
      if (result.status == true) {
        toast.success(`Thank you for the registration with us, please login now` ); 
        navigate('/')
      } else {
        toast.error(`${result.message}` );
      }
    } else {
      setLoad(false);
      toast.error(`Some error occured, try again later` );
    }

  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const setregister=(type)=>{
    formData.user_type = type
    setRegFor(true)
  }
  const startregister=()=>{    
    formData.user_type = ''
    setRegFor(false)
  }

  return (
<>
   {reg_for == false ? 
    <Row className="g-2 mb-3">
        <Col className='mb-6 text-center'>
          <Link onClick={()=>setregister('hospital')}>
            <div className="bg-white mt-5 p-3 h-100 border border-1"> 
                <Image
                  thumbnail
                  fluid
                  roundedCircle
                  className="mb-3 shadow-sm"
                  src={hosImg}
                  width={100}
                />
            
              <h6 className="mb-1">
                <span> Hospital/Imaging Center/Diagnostic Lab </span>
              </h6>
            </div>
          </Link>
        </Col>
        <Col className='mb-6 text-center'> 
          <Link  onClick={()=>setregister('doctor')}> 
            <div className="bg-white mt-5 p-3 h-100 border border-1"> 
                <Image
                  thumbnail
                  fluid
                  roundedCircle
                  className="mb-3 shadow-sm"
                  src={docImg}
                  width={100}
                />
            
              <h6 className="mb-1">
                <span > Doctor/Radiologist</span>
              </h6> 
            </div>  
          </Link>
        </Col>
    </Row>
:
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      
      <Form.Group className="mb-3">
        <Form.Label ><span className="text-uppercase"> {formData.user_type} Registration  </span> <Link onClick={startregister}>Change</Link></Form.Label>
   {/*}     <Form.Select 
          value={formData.user_type}
          name="user_type"
          onChange={handleFieldChange}
          type="text">
            <option value={'hospital'}>Hospital</option>
            <option value={'doctor'}>Doctor</option>
        </Form.Select>  {*/}
      </Form.Group>

      <Row className="g-2 mb-3">
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>First Name</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'First Name' : ''}
            value={formData.first_name}
            name="first_name"
            onChange={handleFieldChange}
            type="text"
          />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Last Name</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Last Name' : ''}
            value={formData.last_name}
            name="last_name"
            onChange={handleFieldChange}
            type="text"
          />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Hospital/Clinic Name</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Hospital/Clinic Name' : ''}
          value={formData.hospital_name}
          name="hospital_name"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>
  
      <Row className="g-2 mb-3">
        <Form.Group as={Col} sm={6}>
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Mobile No.</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Mobile No.' : ''}
            value={formData.mobile}
            name="mobile"
            maxLength={10}
            onChange={handleFieldChange}
            type="text"
          />
        </Form.Group>
      </Row>
      <Form.Group className="mb-3">
      {hasLabel && <Form.Label>Username</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Username' : ''}
          value={formData.username}
          name="username"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>
      <Row className="g-2 mb-3">
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Password' : ''}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Confirm Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Confirm Password' : ''}
            value={formData.confirmPassword}
            name="confirmPassword"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3">
      {hasLabel && <Form.Label>Country</Form.Label>}
            <Form.Select
                placeholder={!hasLabel ? 'Country' : ''} 
                value={formData.country}
                name="country"
                onChange={handleFieldChange}
            >              
                {
                    country_array.map((item, index) => (
                        <option key={index + 1} value={item}>{item} </option>
                    ))
                }
                </Form.Select>                  
        </Form.Group>

      <Form.Group className="mb-3">
        <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
          <Form.Check.Input
            type="checkbox"
            name="isAccepted"
            checked={formData.isAccepted}
            onChange={e =>
              setFormData({
                ...formData,
                isAccepted: e.target.checked
              })
            }
          />
          <Form.Check.Label className="form-label">
            I accept the <Link to="#!">terms</Link> and{' '}
            <Link to="#!">privacy policy</Link>
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>

      <Form.Group className="mb-4">
        <Button
          className="w-100"
          type="submit"
          disabled={
            !formData.user_type ||
            !formData.first_name ||
            !formData.last_name ||
            !formData.hospital_name ||
            !formData.username ||
            !formData.email ||
            !formData.mobile ||
            !formData.password ||
            !formData.confirmPassword ||
            !formData.isAccepted ||
            !formData.country
            || isloading
          }
        >

      {isloading ? 
          <>
          <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className='me-2'
              /> Please wait..</>
            : 'Register'} 
          
        </Button>
      </Form.Group>
         
    </Form>
    }
    </>
  );
};

RegistrationForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default RegistrationForm;

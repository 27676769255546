import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { Card, Col, Row, Dropdown } from 'react-bootstrap';
import TaskPriorityChart from './most-leads/TaskPriorityChart';
import { BaseUrl, taskPriorityTextColor } from '../../../includes/Helper';
import { ApiCall } from '../../../includes/Webservice';
import classNames from 'classnames';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, Link } from 'react-router-dom';
import { getColor } from 'helpers/utils';
import Spinner from 'react-bootstrap/Spinner';

const LeadItem = ({ item, isLast }) => {
    return (
        <Flex
            justifyContent="between"
            alignItems="center"
            className={classNames('border-bottom py-3', {
                'border-bottom-0 pb-0': isLast
            })}
        >
            <Flex>
                <h6 className={`mb-0 ${taskPriorityTextColor(item._id)}`}>{item._id}</h6>
            </Flex>
            <h6 className={`mb-0 ${taskPriorityTextColor(item._id)}`}>{item.no_of_reports}</h6>
        </Flex>
    );
};

const TaskPriority = () => {
 
    const navigate = useNavigate();
    const baseurl = BaseUrl();
    const controller = new AbortController();
    const [statusCountFor, setStatusCountFor] = React.useState('Assigned To Me');
    const [data, setData] = React.useState([]);
    const [colors, setColors] = React.useState([]);
    const [chartData, setChartData] = React.useState([]);
    const [isPageloading, setPageLoad] = React.useState(true);
    const [total, setTotal] = React.useState(10);
    
    React.useEffect(() => {

        loadChart(statusCountFor);
        return () => {
            // Anything in here is fired on component unmount.
            //    console.log('Unmount Component')
            controller.abort();
        }

    }, [])

    const loadChart = (statusCountForVal) => {
        getData(statusCountForVal);
    }
    const setUType = (value) => {
        setStatusCountFor(value)
        loadChart(value)
    }
    const getData = async (statusCountForVal) => {

        setPageLoad(true)
        
        const data = {};
        const result = await ApiCall(baseurl + "/dashboard/report_type", "post",  1,0, 0, data, '', controller);
 
        if (result) { 
            setPageLoad(false)
            if (result.status == true) { 
                setData(result.data)
                var total = 0;
                var colors = []
                var chartData = []
                for (var i = 0; i < result.data.length; i++) 
                { 
                    const textclass = taskPriorityTextColor(result.data[i]._id).replace('text-', '')
                    colors.push(getColor(textclass))

                    chartData.push({ value: result.data[i].no_of_reports, name: result.data[i]._id })
                    total +=  result.data[i].no_of_reports;
                }
                setTotal(total)
                setColors(colors)
                setChartData(chartData) 
            }
        }
    } 

    return (
        <Card className="h-100">
            <FalconCardHeader
                title="Report Type-Wise Summary"
                titleTag="h6"
                className="border-200 border-bottom py-2"
                
            />
            {isPageloading &&
                <div className='text-center mb-5 mt-5'>
                    <Spinner animation="grow" />
                </div>
            }
            {isPageloading == false &&
                < Card.Body as={Row} className="align-items-center" >
                    <Col md={12} xxl={12} className="mb-xxl-1">
                        <TaskPriorityChart colors={colors} chartData={chartData} total={total} />
                    </Col>
                    <Col xxl={12} md={12}>
                        <hr className="mx-ncard mb-0 d-md-none d-xxl-block" />
                        {data.map((item, index) => (
                            <LeadItem
                                key={item._id}
                                item={item}
                                isLast={index === data.length - 1}
                            />
                        ))}
                    </Col>
                </Card.Body >
            }
            {/*}<FalconCardFooterLink title="Primary" size="sm" to="#!" />{*/}

        </Card >
    );
};

LeadItem.propTypes = {
    item: PropTypes.shape({
        _id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        no_of_reports: PropTypes.number.isRequired
    }),
    isLast: PropTypes.bool.isRequired
};

export default TaskPriority;

import React ,{forwardRef} from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate, taskPriorityTextColor } from '../../../includes/Helper';
import { ApiCall } from '../../../includes/Webservice';
import { Row, Col,Form } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import IconButton from 'components/common/IconButton';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Spinner from 'react-bootstrap/Spinner'; 
import { checkTokenFunction } from '../../../includes/CheckToken'; 
const ReportList = () => {
    checkTokenFunction();
    const DateCustomInput = forwardRef(({ value, onClick ,placeholder }, ref) => (
       
        <div className="position-relative"> 
            <Form.Control
                ref={ref}
                onClick={onClick}
                value={value}
                onChange={() => { }} 
                placeholder={placeholder}
            />
             
        </div>
    ));
    const month_ago = new Date();
    month_ago.setMonth(month_ago.getMonth() - 3);
    const userinfo = getUserStorageData();
    const navigate = useNavigate();
    const baseurl = BaseUrl();
    const controller = new AbortController();
    const [data, setData] = React.useState([]);
    const [isPageloading, setPageLoad] = React.useState(false);
    const [report_type, setReportType] = React.useState('');
    const [fromdate, setFromDate] = React.useState(month_ago);
    const [todate, setToDate] = React.useState( new Date());    
    const [gen_report, setGenReport] = React.useState('');
    const [search_text, setSerachText] = React.useState('');
    const [patient_array,setPatientArray] = React.useState([]);
    const [patient_id, setPatientId] = React.useState({value : '' ,label : 'All '});
    const [create_array,setCreateArray] = React.useState([]);
    const [createby, setCreateBy] = React.useState({value : '' ,label : 'All '});
    const [category_array,setCategoryArray] = React.useState([]);
    const [category_id, setCategoryId] = React.useState({value : '' ,label : 'All '});
    const [report_type_array,setReportTypeArray] =React.useState([]); 

    React.useEffect(() => {
        onLoad();
        getPatients();
        return () => {
            // Anything in here is fired on component unmount.
            //    console.log('Unmount Component')
            controller.abort();
        }

    }, [])

    const getPatients = async () => {
     //   setPageLoad(true)
         const data = {};
         const result = await ApiCall(baseurl + "/report/onload", "post",  1,0, 0, data, '', controller);
        if (result) {
         //   setPageLoad(false)
            if (result.status == true) {  

                setReportTypeArray(result.report_type)
                const arr = result.patient_data;
                const cat = result.category_data;
                const create = result.create_data;

                const emp_arr = [] 
                var newObj = {value : '' ,label : 'All '}  
                emp_arr.push(newObj) 
                for (let i = 0; i < arr.length; i++) {   
                        var newObj = {value : arr[i]._id ,label : (arr[i].first_name+' '+arr[i].last_name)}  
                        emp_arr.push(newObj)                      
                } 
                setPatientArray(emp_arr) 

                const cat_arr = [] 
                var newObj = {value : '' ,label : 'All '}  
                cat_arr.push(newObj) 
                for (let i = 0; i < cat.length; i++) {   
                        var newObj = {value : cat[i]._id ,label : (cat[i].category_name)}  
                        cat_arr.push(newObj)                      
                }                
                setCategoryArray(cat_arr)

                const create_arr = [] 
                var newObj = {value : '' ,label : 'All '}  
                create_arr.push(newObj) 
                for (let i = 0; i < create.length; i++) {  
                        if(create[i].hospital_name)                         
                        var newObj = {value : create[i]._id ,label : (create[i].hospital_name)}  
                        else
                        var newObj = {value : create[i]._id ,label : 'SUPERADMIN'}  
                        create_arr.push(newObj)                      
                } 
                setCreateArray(create_arr) 

            } else {
                //  navigate('/');
                toast.info(result.message);
              }
          } else {           
            //  setPageLoad(false);
              toast.error(`Some error occured, try again later` );
              //navigate('/');
          }
      }

    const onLoad = async () => {
        setPageLoad(true)
         const data = { search : search_text, report_type :report_type, fromdate : fromdate ,todate:todate ,patient_id :patient_id.value ,category_id :category_id.value ,createby: createby.value , gen_report : gen_report};
         const result = await ApiCall(baseurl + "/report/list", "post",  1,0, 0, data, '', controller);
        if (result) {
            setPageLoad(false)
            if (result.status == true) {                   
                    setData(result.data)
            } else {
              //  navigate('/');
              toast.info(result.message);
            }
        } else {           
            setPageLoad(false)
            toast.error(`Some error occured, try again later` );
            navigate('/');
        }
    }
    const deleteConfirm = (_id) => {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure want to delete this record ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteData(_id)
                },
                {
                    label: 'No',
                    //onClick: () => alert('Click No')
                }
            ]
        });
    };

    const deleteData= async(report_id)=>{ 

        const data = {report_id : report_id};
        const result = await ApiCall(baseurl + "/report/delete", "post",  1,0, 0, data, '', controller);
       if (result) {
         //  setPageLoad(false)
           if (result.status == true) { 
                   toast.success(result.message);
                   onLoad()
           } else {
                 toast.error(result.message);
            //  navigate('/');
           }
       } else {
        //   setPageLoad(false);
           toast.error(`Some error occured, try again later` );
           navigate('/');
       }
    }

    const columns = [
        {
            accessor: 'report_id',
            Header: 'S. No.',
            disableSortBy: true,
            headerProps: { style: { width: '100px' }, className: 'text-900' },
            Cell: rowData => {
                return rowData.row.index + 1;
            }
        }, 
        {
               accessor: 'reg_no',
              Header: 'Reg No',
              headerProps: { className: 'text-900' } ,
              
          },
        {
            accessor: 'patient_id.first_name',
            Header: 'Patient Name',
            headerProps: { className: 'text-900' } ,
            Cell: rowData => {
                const { patient_id } = rowData.row.original;
                return <div> {!(patient_id) ? '' :  patient_id.first_name +' '+patient_id.last_name}</div>
               }
        },
        {
            accessor: 'report_type',
            Header: 'Report Type',
            headerProps: { className: 'text-900' } 
        },
        {
            accessor: 'ref_doctor_name',
            Header: 'Ref. Doctor Name',
            headerProps: { className: 'text-900' } 
        },
        {
            accessor: 'category_id.category_name',
            Header: 'Category Name',
            headerProps: { className: 'text-900' } ,
            Cell: rowData => {
                const { category_id } = rowData.row.original;
                return <div>{category_id.category_name}</div>
               }
        },
        {
              accessor: 'createby.hospital_name',
              Header: 'Hospital Name',
              headerProps: { className: 'text-900' } ,
              Cell: rowData => {
                  const { createby } = rowData.row.original;
                  return <div>{!(createby.hospital_name) ? 'SUPERADMIN' : createby.hospital_name}</div>
                 }
        },
        {
            accessor: 'createdate',
           Header: 'Create Date',
           headerProps: { className: 'text-900' } ,
           Cell: rowData => {
            const { createdate } = rowData.row.original;
            return <div>{showDBdatetime(createdate)}</div>
           }
       }, 
       { 
        Header: '#',
        headerProps: { className: 'text-900' },
        Cell: rowData => { 
            const { _id } = rowData.row.original;
            return <div><Link className='me-2' target='_blank' to={`${baseurl}/report/view/${_id}`}><FontAwesomeIcon   icon={'print'} /></Link> <Link className='me-2' to={`/report/view/${_id}`}><FontAwesomeIcon   icon={'eye'} /></Link>  <Link onClick={()=>deleteConfirm(_id)}><FontAwesomeIcon className='text-danger' icon={'trash'} /></Link></div> 
            } 
        } 
    ];  

    const search=()=>{ 
        onLoad()
    }

    return (
        <>
            <Row className="g-3 mb-3">

                <Col>
                    <FalconComponentCard>
                        <FalconCardHeader
                            title="Report List"
                            light={false}
                            endEl={<Link to="/report/add"><IconButton icon="plus" className='fs-11'  > Add </IconButton></Link> }  
                        />
                        {
                        isPageloading ?
                        <div className='text-center py-5'><Spinner animation="grow"   /></div>
                        : 
                        <FalconComponentCard.Body>
 
                            <AdvanceTableWrapper
                                columns={columns}
                                data={data}
                                sortable
                                pagination
                                perPage={25}
                            >
                                <Row className='mb-2'>

                                <Col xs={12} sm={3} lg={3} className='mb-1'>
                                      <Form.Label>Search Reg No. & Ref. Doctor Name</Form.Label>
                                    
                                        <Form.Control
                                            type="text" 
                                            defaultValue={search_text}
                                            onChange={(e) => setSerachText(e.target.value)}
                                        /> 
                                 </Col>

                                 <Col xs={12} sm={3} lg={3} className='mb-1'> 
                                 <Form.Label>Patient </Form.Label>
                                     <Select  
                                    closeMenuOnSelect={true} 
                                    options={patient_array}
                                    placeholder='Select User'
                                    classNamePrefix="react-select"
                                    value={patient_id}
                                    onChange={e => setPatientId(e)}
                                    />
                               </Col>

                               <Col xs={12} sm={3} lg={3} className='mb-1'> 
                                 <Form.Label>Category</Form.Label>
                                     <Select  
                                    closeMenuOnSelect={true} 
                                    options={category_array}
                                    placeholder='Select Category'
                                    classNamePrefix="react-select"
                                    value={category_id}
                                    onChange={e => setCategoryId(e)}
                                    />
                               </Col>

                                    <Col xs={12} sm={3} lg={3} className='mb-1'> 
                                    <Form.Label>Report Type </Form.Label>
                                                
                                        <Form.Select aria-label="Status"
                                            value={report_type}
                                            onChange={(e) => setReportType(e.target.value)}
                                        > 
                                            <option key={0} value={''}> All </option>
                                              
                                            {
                                                report_type_array.map((item, index) => (
                                                    <option key={index + 1} value={item.report_type}>{item.report_type} </option>
                                                ))
                                            }

                                        </Form.Select>
                                                    
                                    </Col>
                                    {userinfo.user_type == 'SUPERADMIN' ?
                              <Col xs={12} sm={3} lg={3} className='mb-1'> 
                                 <Form.Label>Hospital Name</Form.Label>
                                     <Select  
                                    closeMenuOnSelect={true} 
                                    options={create_array}
                                    placeholder='Select User'
                                    classNamePrefix="react-select"
                                    value={createby}
                                    onChange={e => setCreateBy(e)}
                                    />
                               </Col>
                                    : ''
                                    }
                                    <Col xs={12} sm={12} lg={2} >
                                     <Form.Label>From Date </Form.Label>
                                        <DatePicker
                                            aria-label="From Date"
                                            selected={fromdate}
                                            onChange={(e) => setFromDate(e)}
                                            formatWeekDay={day => day.slice(0, 3)}
                                            className='form-control'
                                            placeholderText="From Date"
                                            dateFormat="dd/MM/yyyy"
                                            fixedHeight
                                            customInput={<DateCustomInput   />}
                                           
                                        /> 
                                    </Col>

                                    <Col xs={12} sm={12} lg={2} >
                                    <Form.Label>To Date </Form.Label>
                                        <DatePicker
                                            aria-label="To Date"
                                            selected={todate}
                                            onChange={(e) => setToDate(e)}
                                            formatWeekDay={day => day.slice(0, 3)}
                                            className='form-control'
                                            placeholderText="To Date"
                                            dateFormat="dd/MM/yyyy"
                                            fixedHeight
                                            customInput={<DateCustomInput   />}
                                           
                                        /> 
                                    </Col>  

                                 <Col xs={12} sm={3} lg={3} className='mb-1'> 
                                    <Form.Label>Generated Report </Form.Label>
                                                
                                        <Form.Select aria-label="Status"
                                            value={gen_report}
                                            onChange={(e) => setGenReport(e.target.value)}
                                        > 
                                            <option key={0} value={''}> All </option>
                                            <option key={1} value={'General'}>{'General'} </option>
                                            <option key={2} value={'Quick'}>{'Quick'} </option> 
                                        </Form.Select>
                                                    
                                    </Col>    

                                 <Col xs={12} sm={2} lg={2} > 
                                 <Form.Label> </Form.Label>
                                            <div className="d-grid"> 
                                                <IconButton 
                                                    variant={'falcon-primary'}
                                                    className='mb-1'
                                                    icon="search"  
                                                    onClick={search}
                                                > Search</IconButton>
                                            </div>
                                           
                                    </Col>
                                </Row> 
  
                                <AdvanceTable
                                    table
                                    headerClassName="bg-200 text-nowrap align-middle"
                                    rowClassName="align-middle white-space-nowrap"
                                    tableProps={{
                                        bordered: true,
                                        striped: true,
                                        className: 'fs-10 mb-0 overflow-hidden'
                                    }}
                                />
                                <div className="mt-3">
                                    <AdvanceTablePagination
                                        table
                                    />
                                </div>
                                {/*}
                                <div className="mt-3">
                                    <AdvanceTableFooter
                                        rowCount={data.length}
                                        table
                                           rowInfo
                                        navButtons
                                      rowsPerPageSelection
                                    />
                                </div>
                                {*/}
                            </AdvanceTableWrapper>
                        </FalconComponentCard.Body>
}
                    </FalconComponentCard>

                </Col>
            </Row>


        </>
    );
};

export default ReportList;

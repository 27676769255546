import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { Button } from 'react-bootstrap';
import { ApiCall } from '../../../includes/Webservice';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate ,showFileType, ValidFileType ,FileUrlPath ,states_array } from '../../../includes/Helper';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FalconCardHeader from 'components/common/FalconCardHeader';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { nanoid } from 'nanoid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkTokenFunction } from '../../../includes/CheckToken'; 
import Spinner from 'react-bootstrap/Spinner'; 
const Patient = () => {
    const params = useParams();
    checkTokenFunction();
    return (
        <Row className="g-3">
            <FalconComponentCard>
                <FalconCardHeader
                    title={params.patient_id ? "Edit Patient" : "Add Patient"}
                    light={false} 
                    endEl={<Breadcrumb className='fs-11'>
                        <Breadcrumb.Item active><Link to="/dashboard"> Dashboard </Link></Breadcrumb.Item>
                        <Breadcrumb.Item active><Link to="/patient/list"> Patient  </Link></Breadcrumb.Item> 
                        <Breadcrumb.Item active>
                       {params.patient_id  ? 'Edit' :'Add'}
                        </Breadcrumb.Item>
                    </Breadcrumb>}
                />

                <FalconComponentCard.Body>
                    <FormExample patientid={params.patient_id} />
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </Row>
    );
};

function FormExample({ patientid }) {

    
    const [validated, setValidated] = useState(false);
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('Male');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [mainCountry, setMainCountry] = useState('');    
    const [country, setCountry] = useState('');    
    const [pincode, setPincode] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [is_active, setIsActive] = useState(0);
    const [patient_id, setPatientId] = useState('');
    const baseurl = BaseUrl();
    const fileurl = FileUrlPath();
    const navigate = useNavigate();
    const controller = new AbortController();
    const [isloading, setLoad] = useState(false);
    const [isPageloading, setPageLoad] = React.useState(false);
    const [selectedfile, SetSelectedFile] = useState([]);
    const [Files, SetFiles] = useState([]);
    const [logo_file, SetLogoFile] = useState('');
    const [logo_name, SetLogoName] = useState('');

    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    React.useEffect(() => {
        onload()
        if (patientid)
        editData(patientid);
    }, [])

    const onload = async () => {
        setPageLoad(true)
         const data = {};
         const result = await ApiCall(baseurl + "/patient/onload", "post",  1,0, 0, data, '', controller);
        if (result) {
            setPageLoad(false)
            if (result.status == true) { 
                if(result.data){
                 setMainCountry(result.data)
                 setCountry(result.data)
                }else{
                    setMainCountry('India')
                    setCountry('India')
                }
            } else { 
                toast.info(result.message);
              }
          } else {           
              setPageLoad(false);
              toast.error(`Some error occured, try again later` ); 
          }
      }

    const onFileChange = (e) => {
        // --For Multiple File Input

        if(selectedfile.length > 0)
        {
             
            toast.error('Only 1 photo can be added, if want to add new photo remove old one');
            return false;
        }
        let images = [];
        for (let i = 0; i < e.target.files.length; i++) {
            images.push((e.target.files[i]));
            let reader = new FileReader();
            let file = e.target.files[i];
            // console.log(e.target.files[i].type)

            if (!(ValidFileType(e.target.files[i].type))) {
                //alert("File type not allowed") 
                toast.error('File type not allowed');
                return false;
            }
            reader.onloadend = () => {
                SetSelectedFile((preValue) => {
                    return [
                        ...preValue,
                        {
                            id: nanoid(),
                            file: e.target.files[i],
                            filename: e.target.files[i].name,
                            filetype: e.target.files[i].type,
                            fileimage: reader.result,
                            //datetime: e.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
                            filesize: filesizes(e.target.files[i].size)
                        }
                    ]
                });
            }
            if (e.target.files[i]) {
                reader.readAsDataURL(file);
            }
        }
    }

    const RemoveFile = (id) => {
        //if(window.confirm("Are you sure you want to remove this file?")){
        const result = selectedfile.filter((data) => data.id !== id);
        SetSelectedFile(result);  
    }

    const DeleteFile = async () => {
        if (window.confirm("Are you sure you want to delete this file?")) {
           
            const data = {patient_id : patientid};
            const result = await ApiCall(baseurl + "/patient/deletefile", "post",  1,0, 0, data, '', controller);
            setLoad(true)
            if (result) {
                setLoad(false)
                if (result.status == true) {
                       // console.log(result)
                        toast.success(result.message);
                        editData(patientid);
                        SetLogoName('');
                        SetLogoFile('');
                } else {
                    toast.error(result.message);
                }
            } else {
                setLoad(false);
                toast.error(`Some error occured, try again later`, {
                    theme: 'colored'
                });
                navigate('/');
            }
           
        } 
    }

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === true) {
            saveData();
        }
        setValidated(true);
    };

    const editData = async (patient_id) => {
 
        const data ={
            patient_id:patient_id 
        } 
        const result = await ApiCall(baseurl + "/patient/get", "post",  1,0, 0, data, '', controller);

        if (result) { 
            // setPageLoad(false)
            if (result.status == true) {
                
                setPatientId(result.data._id)
                setFirstName(result.data.first_name)
                setLastName(result.data.last_name)
                setAge(result.data.age)
                setGender(result.data.gender)
                setEmail(result.data.email)
                setMobile(result.data.mobile)
                setAddress(result.data.address)
                setCity(result.data.city)
                setState(result.data.state)
                setPincode(result.data.pincode)
                setIsActive(result.data.is_active) 
                if(result.data.country)
                setCountry(result.data.country)
                else
                setCountry('India')
                //if(result.data[0].logo_name !=null)
                //SetLogoName(result.data[0].logo_name)
                //if(result.data[0].logo_file !='' && result.data[0].logo_file !=null)
                  //  SetLogoFile(fileurl+'/'+result.data[0].logo_file)
            } else {
                toast.error(result.message);
                navigate('/');
            }
        } else {
            // setPageLoad(false)
            navigate('/');
            toast.error(`Some error occured, try again later`, {
                theme: 'colored'
              });
        } 
    }

    const saveData = async () => {
 
        if (first_name.trim() == ''  || age.toString().trim() == '' || gender.toString().trim() == '' ) {
            toast.error('Please enter all required fields');
            return false;
        } 
        if (patient_id == '')            
            var url = baseurl + "/patient/add";
        else
            var url = baseurl + "/patient/update";

        const username  = 'patient_'+( Math.random().toString(16).slice(2));
          
        let formData = new FormData()
        formData.append('patient_id', patient_id)
        formData.append('first_name', first_name)
        formData.append('last_name', last_name)
        formData.append('address', address)
        formData.append('age', age)
        formData.append('gender', gender)
        formData.append('city', city)
        formData.append('state', state)
        formData.append('country', country)
        formData.append('pincode', pincode)
        formData.append('email', email)
        formData.append('mobile', mobile)
        formData.append('username', username)
        formData.append('password', '12345')
        formData.append('is_active', is_active)        
        formData.append('filupload', selectedfile.length)
        if(selectedfile.length > 0)
        formData.append('uploadfile', selectedfile[0].file)

        const udata =  getUserStorageData();  
        formData.append('user_id', udata.user_id)
        formData.append('user_name', udata.user_name)
        formData.append('user_type', udata.user_type)

        const data = formData;   
        
        const result = await ApiCall(url, "post",  1, 0, 1, data, '', controller);
        setLoad(true);
        if (result) {
            setLoad(false);
            if (result.status == true) {               
                toast.success(result.message);
                navigate('/patient/list');
            }else{
                toast.error(result.message);
            }
        } else {
            setLoad(false);
            toast.error(`Some error occured, try again later`, {
                theme: 'colored'
              });
            navigate('/');
        } 
    } 

    const setActive = () => {
        if (is_active == 0)
            setIsActive(1);
        else
            setIsActive(0);
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Form.Group as={Col} className="mb-2" md="6" controlId="first_name">
                    <Form.Label>Full Name <span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                        required
                        type="text"
                        maxLength={300}
                        placeholder="Full Name"
                        defaultValue={first_name}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                </Form.Group>

                <Form.Group as={Col} className="mb-2"  md="3" controlId="age">
                    <Form.Label>Gender <span className='text-danger'>*</span></Form.Label>
                    <Form.Select
                        required
                        defaultValue={gender}
                        onChange={(e) => setGender(e.target.value)}
                    >         
                    <option value={''}>Select</option>          
                    <option value={'Male'}>Male</option>
                    <option value={'Female'}>Female</option> 
                    </Form.Select>
                </Form.Group>

                <Form.Group as={Col} className="mb-2"  md="3" controlId="age">
                    <Form.Label>Age (In Years)<span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                        required
                        type="text"
                        maxLength={3}
                        placeholder="Age"
                        defaultValue={age}
                        onChange={(e) => setAge(e.target.value)}
                    />                   
                </Form.Group>

                

                <Form.Group as={Col} className="mb-2"  md="6" controlId="last_name" style={{display:'none'}}>
                    <Form.Label>Last Name <span className='text-danger'>*</span></Form.Label>
                    <Form.Control 
                        type="text"
                        maxLength={300}
                        placeholder="Last Name"
                        defaultValue={last_name}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </Form.Group>

                <Form.Group as={Col}  className="mb-2" md="6" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control 
                        type="email"
                        maxLength={250}
                        placeholder="Email"
                        defaultValue={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />                   
                </Form.Group>
                
                <Form.Group as={Col} className="mb-2"  md="6" controlId="mobile">
                    <Form.Label>Mobile </Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Mobile"
                        defaultValue={mobile}                        
                        onChange={(e) => setMobile(e.target.value)}
                    />                   
                </Form.Group>

                
           
                <Form.Group as={Col} className="mb-2"  md="6" controlId="address" style={{display:'none'}}>
                    <Form.Label>Address  </Form.Label>
                    <Form.Control 
                        type="text"
                        maxLength={500}
                        placeholder="Address"
                        defaultValue={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />                   
                </Form.Group>
            
                <Form.Group as={Col} className="mb-2"  md="3" controlId="city" style={{display:'none'}}>
                    <Form.Label>City  </Form.Label>
                    <Form.Control 
                        type="text"
                        maxLength={300}
                        placeholder="City"
                        defaultValue={city}
                        onChange={(e) => setCity(e.target.value)}
                    />                   
                </Form.Group>
            
                <Form.Group as={Col} className="mb-2"  md="3" controlId="state" style={{display:'none'}}>
                    <Form.Label>State/Province  </Form.Label>
                   
                   {mainCountry=='India' ?
                    <Form.Select 
                        type="text" 
                        placeholder="State"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                    >
                        <option value={''}>Select</option>
                        {
                            states_array.map((item, index) => (
                                <option key={index + 1} value={item}>{item} </option>
                            ))
                        }
                        </Form.Select>
                        :
                        <Form.Control 
                            type="text"
                            placeholder="State"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                        /> 
                        }
                </Form.Group>
            
                
                <Form.Group as={Col} className="mb-2"  md="3" controlId="country" style={{display:'none'}}>
                    <Form.Label>Country   </Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Country"
                        defaultValue={country} 
                        readOnly
                    />                   
                </Form.Group>

                <Form.Group as={Col} className="mb-2"  md="3" controlId="pincode" style={{display:'none'}}>
                    <Form.Label>Pincode/Zipcode  </Form.Label>
                    <Form.Control 
                        type="text"
                        maxLength={6}
                        placeholder="Pincode"
                        defaultValue={pincode}
                        onChange={(e) => setPincode(e.target.value)}
                    />                   
                </Form.Group>
 
            </Row>
  
            <Form.Group className="mb-12 text-center">
                <Button disabled={isloading} variant="outline-primary" type="submit">  {isloading ? 
                <>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className='me-2'
                    /> Loading… </>
                 : 'Submit'}</Button>
            </Form.Group>

        </Form>
    );
}

export default Patient;

 
import Lottie from 'lottie-react';
import React, { useState ,useEffect} from 'react'; 
import { Col, Image, Row } from 'react-bootstrap';
 
import sleeping404 from 'assets/img/animated-icons/sleeping-404.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import maintenance from 'assets/img/maintenance.png';
const Maintenance = ({ hasLabel, layout }) => { 
     
 useEffect(() => {
   
  

}, [])
 

  return ( 
<>
    
    <Row className="align-items-center">
   
   
     
  </Row>
     
    <div className="flex-center min-vh-50 row">
        <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-8 col-sm-10">
            <div className='card'>
                <div className='p-4 p-sm-5 card-body'>
                 
                  <div className='text-center'>
                     <Col lg={12} className="justify-content-center mb-2">
                       
                        <Image
                        thumbnail
                        fluid
                        roundedCircle
                        className="mb-3 shadow-sm"
                        src={maintenance}
                        width={180}
                    />
 
                    </Col> 
                    <div className="justify-content-between align-items-center mb-4">
                      <h4 className='text-center mb-3'>Big Improvements Underway.</h4>
                      <h5 className='text-center mb-3'>
                    Our Platform Will Be Back and Better Than Ever Shortly !</h5>
                    <Link to='/dashboard'>Go to Dashboard</Link>
                    </div> 
                    </div>  
                </div>  
            </div>
        </div>
    </div>
    </>   
  );
};

 

export default Maintenance;

import React from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate, taskPriorityTextColor } from '../../../includes/Helper';
import { ApiCall } from '../../../includes/Webservice';
import { Row, Col ,Button} from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify'; 
import Spinner from 'react-bootstrap/Spinner'; 
import { checkTokenFunction } from '../../../includes/CheckToken'; 
const MyPaymentList = () => {
    const navigate = useNavigate();
    const baseurl = BaseUrl();
    const controller = new AbortController();
    const [data, setData] = React.useState([]);
    const [isPageloading, setPageLoad] = React.useState(false);
    const [wallet_balance,SetWalletBal] =React.useState(0);
    checkTokenFunction();
    React.useEffect(() => {
        onLoad();
        return () => {
            // Anything in here is fired on component unmount.
            //    console.log('Unmount Component')
            controller.abort();
        }

    }, [])

    const onLoad = async () => {
        setPageLoad(true)
         const udata =  getUserStorageData();    
 
         const data = {hospital_id :  udata.user_id};
         const result = await ApiCall(baseurl + "/payment/list", "post",  1,0, 0, data, '', controller);
        if (result) {
            setPageLoad(false)
            if (result.status == true) {                  
                    setData(result.data)
                    SetWalletBal(result.wallet_balance)
            } else {
              //  navigate('/');
              toast.info(result.message);
            }
        } else {           
            setPageLoad(false)
            toast.error(`Some error occured, try again later` );
           // navigate('/');
        }
    }
 

    const columns = [
        {            
            Header: 'S. No.',
            disableSortBy: true,
            headerProps: { style: { width: '100px' }, className: 'text-900' },
            Cell: rowData => {
                return rowData.row.index + 1;
            }
        },
        {
            accessor: 'invoice_no',
            Header: 'Invoice No.',
            headerProps: { className: 'text-900' } 
         },
        {
            accessor: 'order_id',
            Header: 'Order ID',
            headerProps: { className: 'text-900' } 
         },
         {
             accessor: 'payment_id',
             Header: 'Payment ID',
             headerProps: { className: 'text-900' } 
          },
       
        {
            accessor: 'payment_status',
            Header: 'Status',
            headerProps: { className: 'text-900' } 
         }, 
         {
            accessor: 'method',
            Header: 'Method',
            headerProps: { className: 'text-900' } 
         },
        { 
              Header: 'Date',
              headerProps: { className: 'text-900' } ,
              Cell: rowData => {
               const { createdate } = rowData.row.original;
               return <div>{showDBdatetime(createdate)}</div>
              }
          } ,
          {
            accessor: 'order_amount',
            Header: 'Amount',
            headerProps: {  className: 'text-900' },
            Cell: rowData => {
             const { order_amount } = rowData.row.original;
             return <div className='text-end'>{order_amount.toFixed(2)}</div>
            } 
         },
          { 
            Header: 'Payment Invoice',
            headerProps: { className: 'text-900' },
            Cell: rowData => { 
                const { _id } = rowData.row.original;
                return <div className='text-center'><Link className='me-2' target='_blank' to={`${baseurl}/payment/view/${_id}`}><FontAwesomeIcon   icon={'print'} /></Link>   </div> 
                } 
            }   
    ];  

    return (
        <>
            <Row className="g-3 mb-3">
           
                <Col>
                    <FalconComponentCard>
                        <FalconCardHeader
                            title= {`My Payment Transaction List [ Wallet Balance :  ${wallet_balance} Tokens]`}
                            light={false}
                            endEl={
                                <div>
                                    <Link className='me-2 btn btn-falcon-primary btn-sm' to="/paynow">Add Amount In Wallet</Link>
                                </div>
                            }
                             
                        />
                      {
                        isPageloading ?
                        <div className='text-center py-5'><Spinner animation="grow"   /></div>
                        : 
                        <FalconComponentCard.Body>
 
                            <AdvanceTableWrapper
                                columns={columns}
                                data={data}
                                sortable
                                pagination
                                perPage={25}
                            >
                                 
                                <AdvanceTable
                                    table
                                    headerClassName="bg-200 text-nowrap align-middle"
                                    rowClassName="align-middle white-space-nowrap"
                                    tableProps={{
                                        bordered: true,
                                        striped: true,
                                        className: 'fs-10 mb-0 overflow-hidden'
                                    }}
                                />
                                <div className="mt-3">
                                    <AdvanceTablePagination
                                        table
                                    />
                                </div>
                                 
                            </AdvanceTableWrapper>
                        </FalconComponentCard.Body>
                       }
                    </FalconComponentCard>

                </Col> 
            </Row>


        </>
    );
};

export default MyPaymentList;

import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import Background from 'components/common/Background';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';

const StatisticsCard = ({ stat, ...rest }) => {
  const {
    title,
    value,
    decimal,
    suffix,
    prefix,
    valueClassName,
    linkText,
    link,
    image,
    icon,
    baseborder,
    className
  } = stat;
  return (
    <Card className={classNames(className, baseborder, 'overflow-hidden')} {...rest}>
      <Background image={image} className="bg-card" />
      <Card.Body className="position-relative"> 

        <div className="row d-flex justify-content-between align-items-top">
          <div className={classNames(valueClassName, 'display-4 fs-3 mb-1 pe-2 col-xxl col-sm-8 col-8 fw-normal font-sans-serif'
          )}
          >
            <h6> {title} </h6>
            <div><CountUp
              start={0}
              end={value}
              duration={2.75}
              suffix={suffix}
              prefix={prefix}
              separator=","
              decimals={decimal ? 2 : 0}
              decimal="."
            /></div>

          </div>
          <div className="col-auto col-4 col-sm-4"><div className="avatar avatar-4x1">
            <img className="border rounded img-fluid" src={icon} /></div>
          </div>
        </div>

        <Link to={link} className="fw-semibold fs-10 text-nowrap block">
          {linkText}
          <FontAwesomeIcon
            icon="angle-right"
            className="ms-1"
            transform="down-1"
          />
        </Link>

      </Card.Body>
    </Card>
  );
};

StatisticsCard.propTypes = {
  stat: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    decimal: PropTypes.bool,
    suffix: PropTypes.string.isRequired,
    prefix: PropTypes.string.isRequired,
    valueClassName: PropTypes.string,
    linkText: PropTypes.string,
    link: PropTypes.string,
    badgeText: PropTypes.string,
    badgeBg: PropTypes.string,
    image: PropTypes.string,
    className: PropTypes.string
  })
};

export default StatisticsCard;

import React,{forwardRef} from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate, taskPriorityTextColor } from '../../../includes/Helper';
import { ApiCall } from '../../../includes/Webservice';
import { Row, Col ,FloatingLabel,Form } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify'; 
import IconButton from 'components/common/IconButton';
import DatePicker from 'react-datepicker';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Spinner from 'react-bootstrap/Spinner'; 
const HospitalList = () => {

    const DateCustomInput = forwardRef(({ value, onClick ,placeholder }, ref) => (
       
        <div className="position-relative"> 
            <Form.Control
                ref={ref}
                onClick={onClick}
                value={value}
                onChange={() => { }} 
                placeholder={placeholder}
            />
             
        </div>
    ));
    const month_ago = new Date();
    month_ago.setMonth(month_ago.getMonth() - 3);

    const navigate = useNavigate();
    const baseurl = BaseUrl();
    const controller = new AbortController();
    const [data, setData] = React.useState([]);
    const [isPageloading, setPageLoad] = React.useState(false);
    const [search_text, setSerachText] = React.useState('');
    const [user_status, setUserStatus] = React.useState('');
    const [fromdate, setFromDate] = React.useState(month_ago);
    const [todate, setToDate] = React.useState(new Date());
    React.useEffect(() => {
        onLoad();

        return () => {
            // Anything in here is fired on component unmount.
            //    console.log('Unmount Component')
            controller.abort();
        }

    }, [])

    const onLoad = async () => {
        setPageLoad(true)
         const data = {search:search_text , user_status :user_status , fromdate : fromdate ,todate:todate};
         const result = await ApiCall(baseurl + "/hospital/list", "post",  1,0, 0, data, '', controller);
        if (result) {
            setPageLoad(false)
            if (result.status == true) {                   
                    setData(result.data)
            } else {
              //  navigate('/');
              toast.info(result.message);
            }
        } else {           
            setPageLoad(false);
            toast.error(`Some error occured, try again later` );
            navigate('/');
        }
    }

    const deleteConfirm = (_id) => {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure want to delete this record ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteData(_id)
                },
                {
                    label: 'No',
                    //onClick: () => alert('Click No')
                }
            ]
        });
    };

    const deleteData= async(hospital_id)=>{
       
        const data = {hospital_id : hospital_id};
        const result = await ApiCall(baseurl + "/hospital/delete", "post",  1,0, 0, data, '', controller);
       if (result) {
        //   setPageLoad(false)
           if (result.status == true) {
                   console.log(result)
                   toast.success(result.message);
                   onLoad()
           } else {
                  toast.error(result.message);
           }
       } else {
       //    setPageLoad(false);
           toast.error(`Some error occured, try again later`, {
            theme: 'colored'
          });
           navigate('/');
       }
    }

    const columns = [
        {
            accessor: 'user_id',
            Header: 'S. No.',
            disableSortBy: true,
            headerProps: { style: { width: '100px' }, className: 'text-900' },
            Cell: rowData => {
                return rowData.row.index + 1;
            }
        },
        {
            accessor: 'hospital_name',
            Header: 'Hospital Name',
            headerProps: { className: 'text-900' } 
        },
        {
            accessor: 'first_name',
            Header: 'Contact Person',
            headerProps: { className: 'text-900' },
            Cell: rowData => {
                const { first_name,last_name} = rowData.row.original;
                return <div>{first_name} {last_name}  </div>
               }  
        },
        {
          accessor: 'email',
           Header: 'Email',
           headerProps: { className: 'text-900' } 
       },
        {
            accessor: 'mobile',
            Header: 'Mobile',
            headerProps: { className: 'text-900' } 
        },
        {
           accessor: 'address',
           Header: 'Address',
           headerProps: { className: 'text-900' },
           Cell: rowData => {
            const { address,city,state,pincode,country } = rowData.row.original;
            return <div className="text-wrap" style={{ width: '280px' }}>{address} <br/>{city}, {state} , {country} - {pincode}</div>
           } 
       }, 
        {
            accessor: 'username',
            Header: 'Username',
            headerProps: { className: 'text-900' } 
        }, 
        {
            accessor: 'wallet_balance',
            Header: 'Wallet Balance',
            headerProps: { className: 'text-900' } 
        }, 
        {
              accessor: 'createby',
              Header: 'Created By',
              headerProps: { className: 'text-900' } ,
              Cell: rowData => {
                  const { createby } = rowData.row.original;
                  return <div>
                     {createby ?
                        <span> {!(createby.hospital_name) ? 'SUPERADMIN' : createby.hospital_name}
                        </span>
                        : 'Self'
                        }
                    </div>
                 }
          },
        {
              accessor: 'createdate',
              Header: 'Create Date',
              headerProps: { className: 'text-900' } ,
              Cell: rowData => {
               const { createdate } = rowData.row.original;
               return <div>{showDBdatetime(createdate)}</div>
              }
          },
        { 
            accessor: 'is_active',
            Header: 'Active',
            headerProps: { className: 'text-900' },
            Cell: rowData => {
                const { is_active } = rowData.row.original;
                return  <p className={is_active == 1 ? `text-success` : `text-danger`} >{is_active == 1 ? `Active` : `Inactive`} </p>
            } 
        },
        { 
             accessor: 'last_login',
              Header: 'Last Login',
              headerProps: { className: 'text-900' } ,
              Cell: rowData => {
               const { last_login } = rowData.row.original;
               return <div>{showDBdatetime(last_login)}</div>
              }
          },
       { 
        Header: ' ',
        headerProps: { className: 'text-900' },
        Cell: rowData => {
           
            const { _id } = rowData.row.original;
            return <div>    <Link to={'/wallet/add/' + _id}><FontAwesomeIcon  icon={'wallet'} /></Link>  &nbsp;&nbsp;&nbsp; <Link to={'/hospital/edit/' + _id}><FontAwesomeIcon icon={'edit'} /></Link> &nbsp;&nbsp;&nbsp;<Link   onClick={()=>deleteConfirm(_id)}><FontAwesomeIcon className='text-danger' icon={'trash'} /></Link>
            </div>
        }
    }
    ]; 
   
const search=()=>{ 
    onLoad()
}

const filterStatus=(e,tag)=>{
    if(tag=='user_status')
        setUserStatus(e.target.value) 
     onLoad();

}

    return (
        <>
            <Row className="g-3 mb-3">

                <Col>
                    <FalconComponentCard>
                        <FalconCardHeader
                            title="Hospital List"
                            light={false}
                            endEl={<Link to="/hospital/add"><IconButton icon="plus" className='fs-11'  > Add </IconButton></Link> }  
                            
                        />
  {
                        isPageloading ?
                        <div className='text-center py-5'><Spinner animation="grow"   /></div>
                        : 
                        <FalconComponentCard.Body>
 
                            <AdvanceTableWrapper
                                columns={columns}
                                data={data}
                                sortable
                                pagination
                                perPage={25}
                            >
                             <Row>
                                <Col xs={12} sm={4} lg={4} className='mb-2'>
                                      <Form.Label>Search Name, Mobile, Email, Contact etc.. </Form.Label>
                                    
                                        <Form.Control
                                            type="text" 
                                            defaultValue={search_text}
                                            onChange={(e) => setSerachText(e.target.value)}
                                        /> 
                                 </Col>

                                 <Col xs={12} sm={2} lg={2} className='mb-2'> 
                                 <Form.Label>Status </Form.Label>
                                               
                                                    <Form.Select aria-label="Status"
                                                        value={user_status}
                                                        onChange={(e) => filterStatus(e, 'user_status')}
                                                    > 
                                                        <option key={1} value={''}> All </option>
                                                        <option key={2} value={'Active'}> Active </option>
                                                        <option key={3} value={'InActive'}> InActive </option>
                                                            
                                                    </Form.Select>
                                                 
                               </Col>

                               <Col xs={12} sm={12} lg={2} >
                                        <Form.Label>From Date </Form.Label>
                                        <DatePicker
                                            aria-label="From Date"
                                            selected={fromdate}
                                            onChange={(e) => setFromDate(e)}
                                            formatWeekDay={day => day.slice(0, 3)}
                                            className='form-control'
                                            placeholderText="From Date"
                                            dateFormat="dd/MM/yyyy"
                                            fixedHeight
                                            customInput={<DateCustomInput   />}
                                           
                                        /> 
                                    </Col>

                                    <Col xs={12} sm={12} lg={2} >
                                    <Form.Label>To Date </Form.Label>
                                        <DatePicker
                                            aria-label="To Date"
                                            selected={todate}
                                            onChange={(e) => setToDate(e)}
                                            formatWeekDay={day => day.slice(0, 3)}
                                            className='form-control'
                                            placeholderText="To Date"
                                            dateFormat="dd/MM/yyyy"
                                            fixedHeight
                                            customInput={<DateCustomInput   />}
                                           
                                        /> 
                                    </Col>   

                                 <Col xs={12} sm={2} lg={2} > 
                                      <Form.Label> </Form.Label>
                                            <div className="d-grid"> 
                                                <IconButton 
                                                    variant={'falcon-primary'}
                                                    className='mb-1'
                                                    icon="search"  
                                                    onClick={search}
                                                > Search </IconButton>
                                            </div>
                                           
                                        </Col>
                            </Row> 
                                <AdvanceTable
                                    table
                                    headerClassName="bg-200 text-nowrap align-middle"
                                    rowClassName="align-middle white-space-nowrap"
                                    tableProps={{
                                        bordered: true,
                                        striped: true,
                                        className: 'fs-10 mb-0 overflow-hidden'
                                    }}
                                />
                                <div className="mt-3">
                                    <AdvanceTablePagination
                                        table
                                    />
                                </div>
                                {/*}
                                <div className="mt-3">
                                    <AdvanceTableFooter
                                        rowCount={data.length}
                                        table
                                           rowInfo
                                        navButtons
                                      rowsPerPageSelection
                                    />
                                </div>
                                {*/}
                            </AdvanceTableWrapper>
                        </FalconComponentCard.Body>
}
                    </FalconComponentCard>

                </Col>
            </Row>


        </>
    );
};

export default HospitalList;

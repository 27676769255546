import FalconComponentCard from 'components/common/FalconComponentCard';
import { chartJsDefaultTooltip } from 'helpers/chartjs-utils';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import TaskProgressDropdown from 'components/common/TaskProgressDropdown';
import { getColor, rgbaColor } from 'helpers/utils';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Button, Card, Form, Col } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { BaseUrl, showDataLength } from '../../../includes/Helper';
import { ApiCall } from '../../../includes/Webservice';
import Spinner from 'react-bootstrap/Spinner';
 

const CategoryProgressChart = () => {

    const navigate = useNavigate();
    const baseurl = BaseUrl();
    const controller = new AbortController();
    const [data, setData] = React.useState([]);
    const [options, setOptions] = React.useState([]);
    const [isPageloading, setPageLoad] = React.useState(true);

    React.useEffect(() => {

         loadChart();
        return () => {
            // Anything in here is fired on component unmount.
            //    console.log('Unmount Component')
            controller.abort();
        }

    }, [])

    const getStatus = async () => {

        setPageLoad(true)
        const data = {};
        const result = await ApiCall(baseurl + "/dashboard/categorywisechart", "post",  1,0, 0, data, '', controller);

        if (result) {
            setPageLoad(false)
            if (result.status == true) {   
               // console.log(result.data.category)
              //  console.log(result.data.reportdata)

              var bgArray = [rgbaColor(getColor('secondary'), 0.2),
                rgbaColor(getColor('warning'), 0.2),
                rgbaColor(getColor('info'), 0.2),
                rgbaColor(getColor('success'), 0.2),
                rgbaColor(getColor('info'), 0.2),
                rgbaColor(getColor('primary'), 0.2)];

                var borArray = [ getColor('secondary'),
                getColor('warning'),
                getColor('info'),
                getColor('success'),
                getColor('info'),
                getColor('primary')];

                 var labels = [];
                var bgColors = [];
                var borColors = []; 

                for (var i = 0; i < result.data.category.length; i++) {
                    labels.push(showDataLength(result.data.category[i].category_name,10))
                    const n = (i%5);
              //      console.log(n)
                   // console.log(bgArray[n])
                    bgColors.push(bgArray[n])
                    borColors.push(borArray[n])
                }
                var datas =  result.data.reportdata ; //result.data.task_data;

                const data = {
                    labels: labels,
                    datasets: [
                        {
                            label:  'Category Wise Chart' ,
                            data: datas,
                            backgroundColor: bgColors,
                            borderColor: borColors,
                            borderWidth: 1
                        }
                    ]
                };
                const options = {
                    plugins: {
                        tooltip: chartJsDefaultTooltip(),
                        legend: {
                            labels: {
                                color: getColor('gray-500')
                            }
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: getColor('gray-500')
                            },
                            grid: {
                                color: getColor('gray-300'),
                                drawBorder: false
                            }
                        },
                        y: {
                            ticks: {
                                color: getColor('gray-500')
                            },
                            grid: {
                                color: getColor('gray-300'),
                                drawBorder: false,
                            }
                        }
                    }
                };

                setData(data)
                setOptions(options)

            } else {
                //navigate('/');
                setPageLoad(false)
            }
        } else {
            setPageLoad(false)
        }
    }
    const loadChart = () => {

        getStatus();
    }
    
    /*
    const changeStatusFor = (e, tag) => {
        if (tag == 'Type') {
            setStatusCountFor(e.target.value)
            loadChart(e.target.value, statusCountPeriod);
        } if (tag == 'Period') {
            setStatusCountPeriod(e.target.value)
            loadChart(statusCountFor, e.target.value);
        }
    } */

    // console.log(data);

    return (
        <FalconComponentCard className="h-100">
            <FalconCardHeader
                title="Category Wise Chart"
                titleTag="h6"
                className="py-2"
                light 
            />

            {isPageloading == true ?
                <div className='text-center mb-5 mt-5'>
                    <Spinner animation="grow" />
                </div>
                :
                <FalconComponentCard.Body>
                    <Bar
                        data={data}
                        options={options}
                        height={1000}
                        width={1618}
                    />
                </FalconComponentCard.Body>
            }

        </FalconComponentCard>
    );
};

export default CategoryProgressChart;

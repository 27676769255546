import React, { useState } from 'react';
import { Col, Form, Row ,Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { Button } from 'react-bootstrap';
import { ApiCall } from '../../../includes/Webservice';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate } from '../../../includes/Helper';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { faTrophy } from '@fortawesome/free-solid-svg-icons'; 
import Background from 'components/common/Background';
import corner7 from 'assets/img/illustrations/corner-7.png';
import SubtleBadge from 'components/common/SubtleBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPlayer from 'react-player/youtube';

const src1  = 'https://www.youtube.com/watch?v=cOruuZUM8bk';// 'https://www.youtube.com/watch?v=3rpt6G8L9C8&t=1s';
const src2  = 'https://www.youtube.com/watch?v=wUiJazZ9jq4';
const src3  = 'https://www.youtube.com/watch?v=9KyOLbm3E-k';

const Video = () => { 

    return (
        <Row className="g-3">
              <Col lg={12} xxl={6}>
                <Card className="mb-3 border h-100">
                <Background
                    image={corner7}
                    className="bg-card"
                    style={{
                    borderTopRightRadius: '0.375rem',
                    borderBottomRightRadius: '0.375rem'
                    }}
                />
                <Card.Body className="pb-0 position-relative">
                 
                    <h3 className="mt-3 text-warning">Guide Videos</h3>
                    <p className="mb-4">
                    Join our youtube channel <a target='_blank' href='https://www.youtube.com/@IdentifyX_AI' > IdentifyX AI - Revolutionizing Radiology with AI
 </a>
                    </p>

                    <Row className="p-2">
                    <h4 className='mt-3 mb-3'>  How to use the platform </h4>
                        <Col xs={12} lg={4} className="">
                        <ReactPlayer url={src1} controls={true} height={200} className="react-player" />
                        
                        </Col>
                        <Col  xs={12} lg={4}  className="">
                        
                        </Col> 
                        <Col  xs={12} lg={4}  className="">
                        
                        </Col> 
                    </Row> 

                    <Row className="p-2">
                    <h4 className='mt-3 mb-3'> Radiology Case review   </h4>
                         
                        <Col  xs={12} lg={4}  className="">
                        <ReactPlayer url={src2} controls={true} height={200} className="react-player" /> 
                        </Col> 
                        <Col  xs={12} lg={4}  className="">
                          <ReactPlayer url={src3} controls={true} height={200} className="react-player" /> 
                        </Col> 
                        <Col  xs={12} lg={4}  className="">
                        
                        </Col> 
                    </Row> 

                    
                </Card.Body>
                 
                </Card>
            </Col>
    </Row>
    );
};
 

export default Video;

import React from 'react';
import PropTypes from 'prop-types';
import { Nav, Row, Col } from 'react-bootstrap';
import { getFlatRoutes } from 'helpers/utils';
import NavbarNavLink from './NavbarNavLink';
const NavbarDropdownModules = ({ items }) => {
    const routes = getFlatRoutes(items);

    return (
        <>
            <Row>
                <Col xs={12} xxl={6}>
                    <Nav className="flex-column">
                        <NavbarNavLink title="Settings" />
                        {routes.settings.slice(0, 11).map(route => (
                            <NavbarNavLink key={route.name} route={route} />
                        ))}
                    </Nav>
                </Col>
            </Row>
        </>
    );
};

NavbarDropdownModules.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
            name: PropTypes.string.isRequired,
            to: PropTypes.string,
            children: PropTypes.array
        }).isRequired
    ).isRequired
};

export default NavbarDropdownModules;

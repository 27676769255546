import React from 'react';
import ProfileBanner from 'components/pages/user/profile/Banner';
import ProfileIntro from 'components/pages/user/profile//ProfileIntro';

import { Tab, Nav, Row, Col, Table, Form, Button } from 'react-bootstrap';
import { checkTokenFunction } from './../includes/CheckToken'; 
const Profile = () => {
  checkTokenFunction();
  return (
    <>
      <ProfileBanner />

    </>
  );
};

export default Profile;

import ReactEChartsCore from 'echarts-for-react/lib/core';
import { PieChart } from 'echarts/charts';
import {
    GridComponent,
    LegendComponent,
    TitleComponent,
    TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    PieChart,
    CanvasRenderer,
    LegendComponent
]);
//console.log(colors)
const getOptions = (colors, chartData) => ({
    color: colors,
    tooltip: {
        trigger: 'item',
        padding: [7, 10],
        backgroundColor: getColor('gray-100'),
        borderColor: getColor('gray-300'),
        textStyle: { color: getColor('gray-1100') },
        borderWidth: 1,
        transitionDuration: 0,
        formatter: function (params) {
            return `<strong>${params.data.name}:</strong> ${params.percent}%`;
        }
    },
    legend: { show: false },
    series: [
        {
            name: 'Total',
            type: 'pie',
            radius: ['100%', '50%'],
            avoidLabelOverlap: false,
            itemStyle: {
                borderWidth: 2,
                borderColor: getColor('gray-100')
            },
            label: {
                show: false
            },
            emphasis: {
                scale: false
            },
            labelLine: { show: false },
            data: chartData
        }
    ]
});

const MostLeadsChart = ({ colors, chartData, total }) => {
    const chartRef = useRef(null);

    return (
        <div className="position-relative py-2">
            <ReactEChartsCore
                ref={chartRef}
                echarts={echarts}
                option={getOptions(colors, chartData)}
                style={{ height: '12.5rem' }}
            />
            <div className="position-absolute top-50 start-50 translate-middle text-center">
                <p className="fs-10 mb-0 text-400 font-sans-serif fw-medium">Total</p>
                <p className="fs-6 mb-0 font-sans-serif fw-medium mt-n2">{total}</p>
            </div>
        </div>
    );
};

MostLeadsChart.propTypes = {
    colors: PropTypes.array,
    chartData: PropTypes.array
};

export default MostLeadsChart;

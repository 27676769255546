import React from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate, taskPriorityTextColor } from '../../../includes/Helper';
import { ApiCall } from '../../../includes/Webservice';
import { Row, Col } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import IconButton from 'components/common/IconButton';

import FalconCardHeader from 'components/common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner'; 
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { checkTokenFunction } from '../../../includes/CheckToken'; 
const CategoryList = () => {
    const navigate = useNavigate();
    const baseurl = BaseUrl();
    const controller = new AbortController();
    const [data, setData] = React.useState([]);
    const [isPageloading, setPageLoad] = React.useState(false);
    checkTokenFunction()
    React.useEffect(() => {
        onLoad();

        return () => {
            // Anything in here is fired on component unmount.
            //    console.log('Unmount Component')
            controller.abort();
        }

    }, [])
 
    const onLoad = async () => {
        setPageLoad(true)
         const data = {};
         const result = await ApiCall(baseurl + "/category/list", "post",  1,0, 0, data, '', controller);
        if (result) {
            setPageLoad(false)
            if (result.status == true) {                   
                    setData(result.data)
            } else {
              //  navigate('/');
              toast.info(result.message);
            }
        } else {           
            setPageLoad(false)
            toast.error(`Some error occured, try again later` );
            navigate('/');
        }
    }

    const deleteConfirm = (category_id) => {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure want to delete this record ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteData(category_id)
                },
                {
                    label: 'No',
                    //onClick: () => alert('Click No')
                }
            ]
        });
    };

    const deleteData= async(category_id)=>{
        //console.log(category_id)

        const data = {category_id : category_id};
        const result = await ApiCall(baseurl + "/category/delete", "post",  1,0, 0, data, '', controller);
       if (result) {
       //    setPageLoad(false)
           if (result.status == true) {
                   console.log(result)
                   toast.success(result.message);
                   onLoad()
           } else {
                 toast.error(result.message);
               //navigate('/');
           }
       } else {
         //  setPageLoad(false);
           toast.error(`Some error occured, try again later` );
           navigate('/');
       }
    }

    const columns = [
        {
            accessor: 'category_id',
            Header: 'S. No.',
            disableSortBy: true,
            headerProps: { style: { width: '100px' }, className: 'text-900' },
            Cell: rowData => {
                return rowData.row.index + 1;
            }
        },
        {
            accessor: 'category_name',
            Header: 'Category Name',
            headerProps: { className: 'text-900' } 
        },
        {
             accessor: 'is_active',
             Header: 'Active',
             headerProps: { className: 'text-900' },
             Cell: rowData => {
                 const { is_active } = rowData.row.original;
                 return  <p className={is_active == 1 ? `text-success` : `text-danger`} >{is_active == 1 ? `Active` : `Inactive`} </p>
             } 
         }, 
       {
          accessor: 'createby',
          Header: 'Created By',
          headerProps: { className: 'text-900' } ,
          Cell: rowData => {
              const { createby } = rowData.row.original;
              return <div>{!(createby.first_name) ? 'SUPERADMIN' : createby.first_name}</div>
             }
      },
       {
           accessor: 'createdate',
          Header: 'Create Date',
          headerProps: { className: 'text-900' } ,
          Cell: rowData => {
           const { createdate } = rowData.row.original;
           return <div>{showDBdatetime(createdate)}</div>
          }
      },
       { 
        Header: ' ',
        headerProps: { className: 'text-900' },
        Cell: rowData => {
           
            const { _id } = rowData.row.original;
            return <div> <Link to={'/category/edit/' + _id}><FontAwesomeIcon icon={'edit'} /></Link> &nbsp;&nbsp;&nbsp;<Link   onClick={()=>deleteConfirm(_id)}><FontAwesomeIcon className='text-danger' icon={'trash'} /></Link>
            </div>
        }
    }
    ];  

    return (
        <>
            <Row className="g-3 mb-3">

                <Col>
                    <FalconComponentCard>
                        <FalconCardHeader
                            title="Category List"
                            light={false}
                            endEl={<Link to="/category/add"><IconButton icon="plus" className='fs-11'  > Add </IconButton></Link> }  
                        />
  {
                        isPageloading ?
                        <div className='text-center py-5'><Spinner animation="grow"   /></div>
                        : 
                        <FalconComponentCard.Body>
 
                            <AdvanceTableWrapper
                                columns={columns}
                                data={data}
                                sortable
                                pagination
                                perPage={25}
                            >
                            {/*}    <Row className="flex-end-center mb-3">
                                    <Col xs="auto" sm={6} lg={4}>
                                        <AdvanceTableSearchBox table />
                                    </Col>
    </Row>{*/}
                                <AdvanceTable
                                    table
                                    headerClassName="bg-200 text-nowrap align-middle"
                                    rowClassName="align-middle white-space-nowrap"
                                    tableProps={{
                                        bordered: true,
                                        striped: true,
                                        className: 'fs-10 mb-0 overflow-hidden'
                                    }}
                                />
                                <div className="mt-3">
                                    <AdvanceTablePagination
                                        table
                                    />
                                </div>
                                {/*}
                                <div className="mt-3">
                                    <AdvanceTableFooter
                                        rowCount={data.length}
                                        table
                                           rowInfo
                                        navButtons
                                      rowsPerPageSelection
                                    />
                                </div>
                                {*/}
                            </AdvanceTableWrapper>
                        </FalconComponentCard.Body>
}
                    </FalconComponentCard>

                </Col>
            </Row>


        </>
    );
};

export default CategoryList;

import coverSrc from 'assets/img/generic/12.jpg';
import React, { useState, useEffect } from 'react';
import ProfileBanner from '../ProfileBanner';
import { Tab, Nav, Row, Col, Table, Form, Button } from 'react-bootstrap';
import { ApiCall } from './../../../../includes/Webservice';
import { BaseUrl, FileUrlPath } from './../../../../includes/Helper';
import { Link, useNavigate } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'; 
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex'; 
const Banner = () => { 
  const baseurl = BaseUrl();
  const [isPageloading, setPageLoad] = useState(false);
  const [first_name, setFirstName] = useState('');
  const [hospital_name, setHospitalName] = useState('');
  const [last_name, setLastName] = useState('');
  const [username, setUserName] = useState('');
  const [usertype, setUserType] = useState('');
  const [email, setEmail] = useState('');
  const [t_contact_number, setMobile] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [pincode, setPincode] = useState('');
  const [address, setAddress] = useState('');
  const [wallet_balance, setWalletBal] = useState(0);
  const [country, setCounty] = useState('');  
  const controller = new AbortController();
  const navigate = useNavigate();
  const file_url = FileUrlPath();
  const [avatar, setAvtar] = useState('');
  useEffect(() => {
    onLoad(); 
    return () => {
      // Anything in here is fired on component unmount.
      //  console.log('Unmount Component')
      //  controller.abort();
    }

  }, [])


  const onLoad = async () => {
    setPageLoad(true)
   
    //const extraparam = "&api_page=get_profile.php";
   // const result = await ApiCall(baseurl + "/mobile/taskmanagement/api.php", "get", extraparam, 1, 0, 0, '', controller);

   const data ={ } 
    const result = await ApiCall(baseurl + "/user/get", "post",  1,0, 0, data, '', controller);

    if (result) { 
      setPageLoad(false)
      if (result.status ==  true) { 
        // console.log(result.data)
        setFirstName(result.data.first_name)
        setLastName(result.data.last_name)
        setUserName(result.data.username);
        setUserType(result.data.user_type)
        setEmail(result.data.email)
        setMobile(result.data.mobile)
        setCity(result.data.city)
        setAddress(result.data.address)
        setState(result.data.state)
        setPincode(result.data.pincode)
        setCounty(result.data.country)
        setWalletBal(result.data.wallet_balance)
        setHospitalName(result.data.hospital_name)
        
        if(result.file_data.file) 
        setAvtar(result.file_data.file)    

      } else {
      //  navigate('/');
      }
    } else {
      setPageLoad(false)
    }
 

  }

  return (

    <ProfileBanner>
      {
        isPageloading ?
        <div className='text-center py-5'><Spinner animation="grow"   /></div>
          :
          <>
             <Row>
                <Col lg={8}>
             <ProfileBanner.Body>
                <Row>
                  <Col lg={8}>
                    <h4 className="mb-1">
                      {username} <span className="fs-10 fw-normal"> ({usertype})</span>
                    </h4> 
            
                    <div className="border-dashed border-bottom my-4 d-lg-none" />
                  </Col>
                  
                </Row>
                <Row>
                  <Table className="fs-10 mt-3">

                    <tbody>
                  
                    <tr key={1}>
                        <td className="bg-100" style={{ width: '30%' }}>
                        First Name
                        </td>
                        <td>{first_name}</td>
                      </tr>

                      <tr key={2}>
                        <td className="bg-100" style={{ width: '30%' }}>
                        Last Name
                        </td>
                        <td>{last_name}</td>
                      </tr>
                      {(usertype == 'HOSPITAL' || usertype == 'DOCTOR') &&
                      <tr key={9}>
                        <td className="bg-100" style={{ width: '30%' }}>
                        Hospital/Clinic Name
                        </td>
                        <td>{hospital_name}</td>
                      </tr>
                      }
                      <tr key={3}>
                        <td className="bg-100" style={{ width: '30%' }}>
                          Email
                        </td>
                        <td>{email}</td>
                      </tr>
                      
                      <tr key={4}>
                        <td className="bg-100" style={{ width: '30%' }}>
                          Contact No.
                        </td>
                        <td>{t_contact_number}</td>
                      </tr>
                      <tr key={5}>
                        <td className="bg-100" style={{ width: '30%' }}>
                          Address
                        </td>
                        <td>{address}</td>
                      </tr>
                      <tr key={6}>
                        <td className="bg-100" style={{ width: '30%' }}>
                          City
                        </td>
                        <td>{city}</td>
                      </tr>
                      <tr key={7}>
                        <td className="bg-100" style={{ width: '30%' }}>
                        State/Province
                        </td>
                        <td>{state}</td>
                      </tr>
                      <tr key={8}>
                        <td className="bg-100" style={{ width: '30%' }}>
                        Pincode/Zipcode
                        </td>
                        <td>{pincode}</td>
                      </tr>
                      <tr key={10}>
                        <td className="bg-100" style={{ width: '30%' }}>
                          Country
                        </td>
                        <td>{country}</td>
                      </tr>
                      
                    </tbody>
                  </Table>
                </Row>
              </ProfileBanner.Body>
            </Col>
            <Col lg={4}  className="fs-10 mt-5"> 
           
              <ProfileBanner.Body>
            <Flex alignItems="center" className="mb-5">
            <div className="flex-1">
                  <Avatar
                    className="cursor-pointer"
                    size="5xl" 
                    fluid 
                    src={avatar}                    
                    mediaClass="img-thumbnail"
                  />  
                  </div>
          </Flex>
           
              <Link to="/editprofile"   className="link-900">
                <Flex alignItems="center" className="mb-2">
                  
                  <div className="flex-1">
                    <h6 className="mb-0">Edit Profile </h6>
                  </div>
                </Flex>
              </Link>

              <Link to="/changelogo"   className="link-900">
                <Flex alignItems="center" className="mb-2">
                  
                  <div className="flex-1">
                    <h6 className="mb-0">Change Logo </h6>
                  </div>
                </Flex>
              </Link>

              <Link to="/changepass"   className="link-900">
                <Flex alignItems="center" className="mb-2">
                  
                  <div className="flex-1">
                    <h6 className="mb-0">Change Password</h6>
                  </div>
                </Flex>
              </Link>

              
              </ProfileBanner.Body>

                </Col>
                </Row>
          </>
      }

    </ProfileBanner >

  );
};

export default Banner;

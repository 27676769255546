import React from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate, taskPriorityTextColor } from '../../../includes/Helper';
import { ApiCall } from '../../../includes/Webservice';
import { Row, Col } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner'; 
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const CountryWiseUser = () => {
    const navigate = useNavigate();
    const baseurl = BaseUrl();
    const controller = new AbortController();
    const [data, setData] = React.useState([]);
    const [isPageloading, setPageLoad] = React.useState(false);

    React.useEffect(() => {
        onLoad();

        return () => {
            // Anything in here is fired on component unmount.
            //    console.log('Unmount Component')
            controller.abort();
        }

    }, [])

    const onLoad = async () => {
        setPageLoad(true)
         const data = {};
         const result = await ApiCall(baseurl + "/user/countrywise", "post",  1,0, 0, data, '', controller);
        if (result) {
            setPageLoad(false)
            if (result.status == true) {                   
               // console.log(result.data)
                    setData(result.data)
            } else {
              //  navigate('/');
              toast.info(result.message);
            }
        } else {           
            setPageLoad(false)
            toast.error(`Some error occured, try again later` );
            navigate('/');
        }
    }
 
    
    const columns = [
        {
            accessor: 'type_id',
            Header: 'S. No.',
            disableSortBy: true,
            headerProps: { style: { width: '100px' }, className: 'text-900' },
            Cell: rowData => {
                return rowData.row.index + 1;
            }
        },
        {
            accessor: 'country',
            Header: 'Country',
            headerProps: { className: 'text-900' } 
        },
        {
            accessor: 'doctors',
            Header: 'Doctors',
            headerProps: { className: 'text-900' } 
        }, 
        {
            accessor: 'hospitals',
            Header: 'Hospitals',
            headerProps: { className: 'text-900' } 
        }, 
        {
            accessor: 'patients',
            Header: 'Patients',
            headerProps: { className: 'text-900' } 
        }, 
        
       
    ];  

    return (
        <>
            <Row className="g-3 mb-3">

                <Col>
                    <FalconComponentCard>
                        <FalconCardHeader
                            title="Country Wise User List"
                            light={false} 
                            endEl={<Breadcrumb className='fs-11'>
                                <Breadcrumb.Item active><Link to="/dashboard"> Dashboard </Link></Breadcrumb.Item>
                                <Breadcrumb.Item active> Country Wise User List  </Breadcrumb.Item> 
                                
                            </Breadcrumb>}                
                        />
                        {
                        isPageloading ?
                        <div className='text-center py-5'><Spinner animation="grow"   /></div>
                        : 
                        <FalconComponentCard.Body>
 
                            <AdvanceTableWrapper
                                columns={columns}
                                data={data}
                                sortable
                                pagination
                                perPage={25}
                            >
                           
                                <AdvanceTable
                                    table
                                    headerClassName="bg-200 text-nowrap align-middle"
                                    rowClassName="align-middle white-space-nowrap"
                                    tableProps={{
                                        bordered: true,
                                        striped: true,
                                        className: 'fs-10 mb-0 overflow-hidden'
                                    }}
                                />
                                <div className="mt-3">
                                    <AdvanceTablePagination
                                        table
                                    />
                                </div>
                               
                            </AdvanceTableWrapper>
                        </FalconComponentCard.Body>
}
                    </FalconComponentCard>

                </Col>
            </Row>


        </>
    );
};

export default CountryWiseUser;

import React, { useState } from 'react';
import { Col, Form, Row ,Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { Button } from 'react-bootstrap';
import { ApiCall } from '../../../includes/Webservice';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate } from '../../../includes/Helper';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { faTrophy } from '@fortawesome/free-solid-svg-icons'; 
import Background from 'components/common/Background';
import corner7 from 'assets/img/illustrations/corner-7.png';
import SubtleBadge from 'components/common/SubtleBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Contact = () => { 

    return (
        <Row className="g-3">
              <Col lg={12} xxl={6}>
                <Card className="mb-3 border h-100">
                <Background
                    image={corner7}
                    className="bg-card"
                    style={{
                    borderTopRightRadius: '0.375rem',
                    borderBottomRightRadius: '0.375rem'
                    }}
                />
                <Card.Body className="pb-0 position-relative">
                 
                    <h3 className="mt-3 text-warning">Need a help?</h3>
                    <p className="mb-0">
                    We are one step away from you , get in touch on below email or mobile number.
                    </p>
                </Card.Body>
                <Card.Footer className="position-relative pt-0">
                    <span className="fw-semibold">Mail us at :  </span>
                    <a href="mailto:contact@identifyx.in">contact@identifyx.in</a>
                      <br/><br/>
                    <span className="fw-semibold">Call us on :  </span>
                    <a href="phone:6262818177"> +91-6262-8181-77</a>
                </Card.Footer>
                </Card>
            </Col>
    </Row>
    );
};
 

export default Contact;


import * as CryptoJS from 'crypto-js';

export function encryptAES(text, key = '') {
        key = process.env.REACT_APP_SECURE_LOCAL_STORAGE_HASH_KEY;
        return CryptoJS.AES.encrypt(text, key).toString();
}
export function decryptAES(encryptedBase64, key = '') {
        key = process.env.REACT_APP_SECURE_LOCAL_STORAGE_HASH_KEY;
        // console.log(key)
        const decrypted = CryptoJS.AES.decrypt(encryptedBase64, key);
        if (decrypted) {
                try {
                        //  console.log(decrypted);
                        const str = decrypted.toString(CryptoJS.enc.Utf8);
                        if (str.length > 0) {
                                return str;
                        } else {
                                return 'error 1';
                        }
                } catch (e) {
                        return 'error 2';
                }
        }
        return 'error 3';
}
export function BaseUrl() { 
       
        const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;
      //  console.log( apiUrl)
        return apiUrl;// process.env.REACT_APP_API_URL;   
       
}
export function FileUrlPath() {
        const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;
        return apiUrl ; //process.env.REACT_APP_API_URL;  

}
export function showDataLength(data, data_length) {
        if (data.length > data_length) {
                return data.substring(0, data_length) + '..';
        } else {
                return data;
        }

}
export function setFields(value) {
        if(value == 'undefined')
          return '';
        else 
         return value ;
}
export function setNumFields(value) {
        if(value == 'undefined')
          return 0;
        else 
         return value ;
}

export function taskPriorityTextColor(priority_id) {
         
        var textclass = 'text-info'
        if (priority_id == 'MRI') textclass = 'text-danger'
        else if (priority_id == 'CT') textclass = 'text-warning'
        else if (priority_id == 'XRAY') textclass = 'text-primary'
        return textclass;
}
export function ShowDateFormat(date) {
        //2023-11-30 18:14:00.000
        let datestr1 = date.split(' ');
        let datestr = datestr1[0].split('-');
        return datestr[2] + '/' + datestr[1] + '/' + datestr[0];
}

export function showJSdate(date) {
        var datetime = date.getFullYear() + "-" +
                ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
                ("00" + date.getDate()).slice(-2) + " " +
                date.toLocaleTimeString();
        return datetime;
}

export function convertDBdate(date) {
        //2023-11-30 18:14:00.000
        var datetime = date.getFullYear() + "-" +
                ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
                ("00" + date.getDate()).slice(-2);
        return datetime;
}

export function convertDBtime(date) {
        //2023-11-30 18:14:00.000
        var datetime = ("00" + (date.getHours())).slice(-2) + ':' +
                ("00" + (date.getMinutes())).slice(-2) + ':' +
                ("00" + (date.getSeconds())).slice(-2);
        return datetime;
}
export function showDBdatetime(datetime) {
        if(datetime)
        {
                const date = new Date(datetime);
                var showdate = ("00" + date.getDate()).slice(-2) + "/" + (("00" + (date.getMonth() + 1)).slice(-2)) + "/" + date.getFullYear() + ' ' + date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                return showdate;
        }else{
                return ''; 
        }
}
export function showDBdate(datetime) {
        const date = new Date(datetime);
        var showdate = ("00" + date.getDate()).slice(-2) + "/" + (("00" + (date.getMonth() + 1)).slice(-2)) + "/" + date.getFullYear();
        return showdate;
}
export function showDBTime(datetime) {
        const date = new Date(datetime);
        var showdate = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        ;
        return showdate;
}
const month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export function showCaldate(datetime) {
        const date = new Date(datetime);
        // const month = MONTH_NAMES[date.getMonth()];
        var showdate = ("00" + date.getDate()).slice(-2) + ", " + month_names_short[date.getMonth()] + " " + date.getFullYear();
        return showdate;
}
export function timeAgoSinceDate(datetime) {
        return timeAgo(new Date(datetime))
        // const date = new Date(datetime);
        //   return showDBdatetime(date);

        /*
       if (seconds < 5) {
        return 'Just now';
      } else if (seconds <= 60) {
        return seconds+' seconds ago';
      } else if (minuts <= 1) {
        return (minuts) ? '1 minute ago' : 'A minute ago';
      } else if (minuts <= 60) {
        return minuts+' minutes ago';
      } else if (hours <= 1) {
        return (hours) ? '1 hour ago' : 'An hour ago';
      } else if (hours <= 60) {
        return hours+' hours ago';
      }else {
        return showDBdatetime(date);
      }
      */
}

/*
export const report_type_array=[
        {value : 90, label: "MRI"},
        {value : 60, label:"CT"},
        {value : 20, label:"XRAY"}
] */


const MONTH_NAMES = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
];
 

export function getFormattedDate(date, prefomattedDate = false, hideYear = false) {
        const day = date.getDate();
        const month = MONTH_NAMES[date.getMonth()];
        const year = date.getFullYear();
        const hours = date.getHours();
        let minutes = date.getMinutes();
        const localtime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

        if (minutes < 10) {
                // Adding leading zero to minutes
                minutes = `0${minutes}`;
        }

        if (prefomattedDate) {
                // Today at 10:20
                // Yesterday at 10:20
                return `${prefomattedDate} at ${localtime}`; //${ hours }:${ minutes }
        }

        if (hideYear) {
                // 10 January, at 10:20 AM
                return `${day} ${month}, at ${localtime}`; //${ hours }:${ minutes }
        }

        // 10 January 2017, at 10:20 AM
        return `${day} ${month} ${year}, at ${localtime}`; //${ hours }:${ minutes } 
}


// --- Main function
export function timeAgo(dateParam) {
        if (!dateParam) {
                return null;
        }

        const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
        const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
        const today = new Date();
        const yesterday = new Date(today - DAY_IN_MS);
        const seconds = Math.round((today - date) / 1000);
        const minutes = Math.round(seconds / 60);
        const isToday = today.toDateString() === date.toDateString();
        const isYesterday = yesterday.toDateString() === date.toDateString();
        const isThisYear = today.getFullYear() === date.getFullYear();


        if (seconds < 5) {
                return 'Just now';
        } else if (seconds < 60) {
                return `${seconds} seconds ago`;
        } else if (seconds < 90) {
                return 'about a minute ago';
        } else if (minutes < 60) {
                return `${minutes} minutes ago`;
        } else if (isToday) {
                return getFormattedDate(date, 'Today'); // Today at 10:20
        } else if (isYesterday) {
                return getFormattedDate(date, 'Yesterday'); // Yesterday at 10:20
        } else if (isThisYear) {
                return getFormattedDate(date, false, true); // 10. January at 10:20
        }

        return getFormattedDate(date); // 10. January 2017. at 10:20
}

export function getUserStorageData() {
        const userdata = localStorage.getItem('abs_userdata');

        if (userdata) {
                const user = JSON.parse(decryptAES(userdata))
               // console.log(user)
                if (user)
                        return user;
                else
                        return false;
        } else {
                return false;
        }
}

export function showFileType() {
        const text = "( File types allowed are -  jpeg, jpg, png ) ";  
        return text;
}
export function ValidFileType(fileType) {
        
        const text = ['image/jpeg', 'image/jpg',  'image/png' ];  
        if (text.includes(fileType)) {
                return true;
        } else {
                return false;
        } 
}

export function showFileTypeReport() {
        const text = "( File types allowed are -  jpeg, jpg, png, dcm ) ";  
        return text;
}
export function ValidFileTypeReport(fileName) {

        const file_extensionArr = fileName.split('.');
        const fileType = file_extensionArr.at(-1);
         // console.log(fileType);
        const text = ['dcm', 'jpeg', 'jpg', 'png' ];
        if (text.includes(fileType)) {
                return true;
        } else {
                return false;
        }          
}

export   function convertReportText(content) {

        content = content.replaceAll('Findings:', '\n\n\Findings:\n'); 
        content = content.replaceAll('Incidental Note:', '\n\n\Incidental Note:\n'); 
        content = content.replaceAll('Impression:', '\n\nImpression:\n'); 
        content = content.replaceAll('Differential Diagnosis:', '\n\nDifferential Diagnosis:\n'); 

        content = content.replaceAll('Specific Information:', '\n\nSpecific Information:\n'); 
        content = content.replaceAll('Conclusion:', '\n\nConclusion:\n'); 
        content = content.replaceAll('Concluding Remarks:', '\n\nConcluding Remarks:\n'); 
        content = content.replaceAll('Recommendations:', '\n\n\Recommendations:\n'); 
        content = content.replaceAll('Conclusion and Diagnostic Possibilities:', '\n\n\Conclusion and Diagnostic Possibilities:\n'); 
        content = content.replaceAll('Differential diagnosis:', '\n\n\Differential diagnosis:\n'); 
        content = content.replaceAll('Please note:', '\n\n\Please note:\n'); 
        content = content.replaceAll('*', ''); 
        content = content.replaceAll('#', ''); 

        content = content.replaceAll('Concluding remarks:', '\n\n\Concluding remarks:\n'); 
        content = content.replaceAll('Specific information:', '\n\n\Specific information:\n'); 
        content = content.replaceAll('Possible Diagnostic Avenues:', '\n\n\Possible Diagnostic Avenues:\n'); 
		
        content = content.replaceAll('Differential Diagnosis -', '\n\n\Differential Diagnosis -\n'); 
        content = content.replaceAll('Findings -', '\n\n\Findings -\n'); 
        content = content.replaceAll('Impression -', '\n\n\Impression -\n'); 

        content = content.replaceAll('Differential Diagnosis -', '\n\n\Differential Diagnosis -\n'); 
        content = content.replaceAll('Specific Information -', '\n\n\Specific Information -\n'); 
        content = content.replaceAll('Concluding Remarks -', '\n\n\Concluding Remarks -\n'); 
        content = content.replaceAll('Possible Diagnostic Avenues -', '\n\n\Possible Diagnostic Avenues -\n');  

        content = content.replaceAll(' - ', '\n\n- '); 
/*
        content = content.replaceAll('1.', '\n1.'); 
        content = content.replaceAll('2.', '\n2.'); 
        content = content.replaceAll('3.', '\n3.'); 
        content = content.replaceAll('4.', '\n4.'); 
        content = content.replaceAll('5.', '\n5.'); 
        content = content.replaceAll('6.', '\n6.'); 
        content = content.replaceAll('7.', '\n7.'); 
        content = content.replaceAll('8.', '\n8.'); 
        content = content.replaceAll('9.', '\n9.'); 
        content = content.replaceAll('10.', '\n10.');
        content = content.replaceAll('11.', '\n11.'); 
        content = content.replaceAll('12.', '\n12.'); 
        content = content.replaceAll('13.', '\n13.'); 
        content = content.replaceAll('14.', '\n14.'); 
        content = content.replaceAll('15.', '\n15.');    
  */      
        return content;
}


export const country_array =  [
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cape Verde",
        "Cayman Islands",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos (Keeling) Islands",
        "Colombia",
        "Comoros",
        "Congo",
        "Congo, the Democratic Republic of the",
        "Cook Islands",
        "Costa Rica",
        "Cote d'Ivoire",
        "Croatia (Hrvatska)",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "East Timor",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Ethiopia",
        "Falkland Islands (Malvinas)",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "France Metropolitan",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard and Mc Donald Islands",
        "Holy See (Vatican City State)",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran (Islamic Republic of)",
        "Iraq",
        "Ireland",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea, Democratic People's Republic of",
        "Korea, Republic of",
        "Kuwait",
        "Kyrgyzstan",
        "Lao, People's Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libyan Arab Jamahiriya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macau",
        "Macedonia, The Former Yugoslav Republic of",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia, Federated States of",
        "Moldova, Republic of",
        "Monaco",
        "Mongolia",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "Netherlands Antilles",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Reunion",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia (Slovak Republic)",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "Spain",
        "Sri Lanka",
        "St. Helena",
        "St. Pierre and Miquelon",
        "Sudan",
        "Suriname",
        "Svalbard and Jan Mayen Islands",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan, Province of China",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "United States Minor Outlying Islands",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Vietnam",
        "Virgin Islands (British)",
        "Virgin Islands (U.S.)",
        "Wallis and Futuna Islands",
        "Western Sahara",
        "Yemen",
        "Yugoslavia",
        "Zambia",
        "Zimbabwe"
        ];

export const states_array = [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttarakhand",
        "Uttar Pradesh",
        "West Bengal",
        "Andaman and Nicobar Islands",
        "Chandigarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Lakshadweep",
        "Puducherry"
    ];
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import { BaseUrl, getUserStorageData, FileUrlPath } from './../../../includes/Helper';

const ProfileDropdown = () => {

  const [user_name, setUserName] = React.useState(null);
  const [user_type, setUserType] = React.useState(null);
  
  const [photo_url, setPhotoUrl] = React.useState('');
  const file_url = FileUrlPath();
  const navigate = useNavigate();
  React.useEffect(() => {
 
    const udata = getUserStorageData();  
    if (udata) {
      setUserName(udata.user_name)
      setUserType(udata.user_type)
      //console.log(udata.user_logo)
      if (udata.user_logo != '')
        setPhotoUrl(udata.user_logo) 
        //setPhotoUrl(file_url + '/taskmng/master/demouser.jpg')
      //else        
    } 
  })

  const logout = () => {
    localStorage.clear(); 
     navigate('/');
  }


  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={photo_url} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">

          <Dropdown.Item className="fw-bold text-primary" href="#!">
            <FontAwesomeIcon icon="user" className="me-1" />
            <span>{user_name}</span>  (  <span>{user_type}</span> )
          </Dropdown.Item>
           
          <Dropdown.Divider />

          <Dropdown.Item as={Link} to="/myprofile">
            My Profile
          </Dropdown.Item>

          <Dropdown.Item as={Link} to="/changepass">
            Change Password
          </Dropdown.Item>

          <Dropdown.Item as={Link} to="/" onClick={logout}>
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;

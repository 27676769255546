 
export const dashboardRoutes = {
  label: 'dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      children: [
        {
          name: 'Main',
          to: '/dashboard',
          exact: true,
          active: true
        } 
      ]
    }
  ]
};
  
  
export const masterRoutes = {
  label: 'Pages',
  children: [ 
    {
        name: 'Enquiry',
        active: true,
        icon: 'envelope',
        badge: {
          type: 'success',
          text: 'New'
        },
        children: [
          {
            name: 'Identifyx.in',
            to: '/enquiry/identifyx',
            active: true
          },
          {
            name: 'Radsquad.in',
            to: '/enquiry/radsquad',
            active: true
          }  
        ]
      }, 
         
  ]
};
 

export default [
  dashboardRoutes,
  masterRoutes 
];

import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { Button ,Table ,Image } from 'react-bootstrap';
import { ApiCall } from '../../../includes/Webservice';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate } from '../../../includes/Helper';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FalconCardHeader from 'components/common/FalconCardHeader';
import createMarkup from 'helpers/createMarkup'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'react-bootstrap/Spinner'; 
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery'; 
import { checkTokenFunction } from '../../../includes/CheckToken'; 
const ReportView = () => {
    const params = useParams();
    checkTokenFunction();
    return (
        <Row className="g-3">
            <FalconComponentCard>
                <FalconCardHeader
                    title={params.report_id && "View Report" }
                    light={false}
                    endEl={<Breadcrumb className='fs-11'>
                        <Breadcrumb.Item active><Link to="/dashboard"> Dashboard </Link></Breadcrumb.Item>
                        <Breadcrumb.Item active><Link to="/report/list"> Report  </Link></Breadcrumb.Item> 
                        <Breadcrumb.Item active>
                       {params.report_id  ? 'View' :'Add'}
                        </Breadcrumb.Item>
                    </Breadcrumb>}
                />

                <FalconComponentCard.Body>
                    <FormExample report_id={params.report_id} />
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </Row>
    );
};

function FormExample({ report_id }) {

    const [validated, setValidated] = useState(false);
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [category_name, setCategory] = useState('');
    const [report_type, setRepType] = useState('');
    const [report_response, setrepRes] = useState('');
    const [createdate, setCreateDate] = useState(''); 
    const [ref_doctor_name,setRefDocName] =useState('');
    const [reg_no, setRegNo] = useState(''); 
    const baseurl = BaseUrl();
    const navigate = useNavigate();
    const controller = new AbortController();
    const [isloading, setLoad] = useState(false);
    const [isPageloading, setPageLoad] = React.useState(false);
    const [images, setImages] = useState([]);  
    const [rediologist_name, setDocName] = useState('');
    const [rediologist_designation, setDegName] = useState('');
    const [response, setResponse] = useState('');
    const [isEdit, setEdit] = useState(0);

    React.useEffect(() => {
       
        onLoad();
    }, []) 
  

  const onLoad = async () => {
    setPageLoad(true)
   
    //const extraparam = "&api_page=get_profile.php";
   // const result = await ApiCall(baseurl + "/mobile/taskmanagement/api.php", "get", extraparam, 1, 0, 0, '', controller);

   const data ={ 
    report_id:report_id 
   } 
    const result = await ApiCall(baseurl + "/report/get", "post",  1,0, 0, data, '', controller);

    if (result) { 
        setPageLoad(false)
      if (result.status ==  true) { 
        var str  = (result.data.report_response)
        setResponse(str)
        var newstr = str.replaceAll('\n', '<br/>'); 
        setrepRes(newstr)
        setRepType(result.data.report_type)
        setCreateDate(showDBdatetime(result.data.createdate));         
        setCategory(result.data.category_id.category_name)
        setRegNo(result.data.reg_no)
        setRefDocName(result.data.ref_doctor_name)
        setDocName(result.data.rediologist_name)
        setDegName(result.data.rediologist_designation)
        if(result.data.patient_id)
        {
            setFirstName(result.data.patient_id.first_name)
            setLastName(result.data.patient_id.last_name)
            setAge(result.data.patient_id.age)
            setGender(result.data.patient_id.gender)
        }else{
            setAge(result.data.age)
            setGender(result.data.gender)
        }
     
 
        var imgarray = []
        for(var i=0;i<result.imagedata.length;i++)
             imgarray.push(result.imagedata[i].file)
 
           setImages(imgarray)
            
      }  
    } else {
        setPageLoad(false)
    } 

  }

  const sendEmail = async () => {
    setLoad(true)
    const data ={
        report_id:report_id 
    } 
    const result = await ApiCall(baseurl + "/report/sendreport", "post",  1,0, 0, data, '', controller);

    if (result) {
        setLoad(false)
        if (result.status == true) { 
            toast.success(result.message) 
        } else { 
            toast.error(result.message);
        }
    } else {
        setLoad(false)
        toast.error(`Some error occured, try again later` );
        navigate('/');
    }

}

const editData=()=>{
    setEdit(1)
}
const saveData= async()=>{

    if (response.trim() == '') {
        toast.error('Please enter report text');
        return false;
    } 
    const data ={
        response:response, 
        report_id : report_id
    } 
    var url = baseurl + "/report/update";
    const result = await ApiCall(url, "post",  1,0, 0, data, '', controller);

    if (result) {
        setLoad(false);
        if (result.status == true) 
        {               
            toast.success(result.message); 
            setEdit(0) 
            var newstr = response.replaceAll('\n', '<br/>'); 
            setrepRes(newstr)
        }else{
            toast.error(result.message);
        }
    } else {
        setLoad(false);
        toast.error(`Some error occured, try again later` ); 
    } 
}
const cancelData=()=>{
    setEdit(0)
}
    return (
      
            <Row className="mb-3">
                {
                        isPageloading ?
                        <Spinner animation="grow"   />
                        : 
            <Table className="fs-10 mt-3">

                <tbody>

                <tr key={1}>
                    <td className="bg-100" width={'15%'}>  <strong> PATIENT'S NAME </strong> </td>
                    <td width={'35%'}>{first_name} {last_name}</td>
                    <td className="bg-100" width={'15%'}> <strong> AGE/SEX </strong></td>
                    <td width={'35%'}>{age}/{gender}</td>
                </tr>

                <tr key={2}>
                    <td className="bg-100" > <strong>REF. BY </strong></td>
                    <td>{ref_doctor_name}</td>
                    <td className="bg-100" > <strong> DATE  </strong></td>
                    <td>{createdate}</td>
                </tr>

                <tr key={3}>
                    <td className="bg-100" ><strong>PART SCANNED </strong> </td>
                    <td>{category_name}</td>
                    <td className="bg-100" >  <strong> REG. NO. </strong> </td>
                    <td>{reg_no}</td>
                </tr>

                <tr key={4}>
                    <td colSpan={4} className="text-center"  >
                         <h3>{report_type}: {category_name} </h3>
                    </td> 
                </tr>

                <tr key={5}>
                    <td colSpan={4} className="text-center"  >
                            {isEdit==0 &&
                             <Button  size="sm" className='me-3' variant="outline-warning" onClick={editData} disabled={isloading}  type="submit">  {isloading ? 'Loading…' : 'Edit'}</Button>
                            }

                            {isEdit==1 &&
                             <Button  size="sm" className='me-3' variant="outline-success" onClick={saveData} disabled={isloading}   type="submit">  {isloading ? 'Loading…' : 'Submit'}</Button>
                            }
                            {isEdit==1 &&  
                             <Button  size="sm" variant="outline-danger" className='me-3' onClick={cancelData} disabled={isloading}  type="submit">  {isloading ? 'Loading…' : 'Cancel'}</Button>
                            }
                        </td> 
                </tr>

                <tr key={6}>
                    <td colSpan={4} className="text-left"  >
                    {isEdit==1 ?
                    <Form.Control
                        as="textarea"
                        rows={15}
                        required
                        placeholder="API Response"
                        value={response} 
                        onChange={(e) => setResponse(e.target.value)}
                    />
                    :
                     <p className="mb-1 fw-semibold" dangerouslySetInnerHTML={createMarkup(report_response)} />
                    }                   
                    </td> 
                </tr>

                <tr key={7}>
                    <td colSpan={4} className="text-right"  >
                     {rediologist_name}
                    </td> 
                </tr>

                <tr key={8}>
                    <td colSpan={4} className="text-right"  >
                     {rediologist_designation}
                    </td> 
                </tr>

                <tr key={9}>
                         
                        <td colSpan={4} className="text-left" >  
                     {/*}
                        <div className="row kb-attach-box mb-3">
                            {
                                images.map((data, index) => {
                                    const {  name ,file ,path} = data;
  
                                    return (
                                        <div className="col-md-3 file-atc-box" key={index}>   
                                        {
                                            name.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                                <div className="file-image"> <img src={file} alt="" /> </div> :
                                                <div className="file-image">
                                                    <FontAwesomeIcon icon="file-alt" />
                                                </div> 
                                        }      
                                        </div>
                                         
                                    )
                                })
                            }
                        </div>{*/} 
                      

                        <FalconLightBoxGallery images={images}>
                                    {setImgIndex => (
                                        <Row className="g-2">
                                     { images.map((item, index) => (
                                        <Col key={index} xs={6} className='file-image'> 
                                            <Image
                                            className="cursor-pointer"
                                            src={item}
                                            fluid
                                            rounded
                                            onClick={() => setImgIndex(index)}
                                            />
                                        </Col>
                                            ))}
                                    
                                        </Row>
                                    )}
                                    </FalconLightBoxGallery>

                        </td>
                    </tr>

                    <tr key={10}>
                         
                   <td colSpan={4} className="text-center" >  
                    <Button disabled={isloading} onClick={sendEmail} variant="outline-primary" type="button">  {isloading ? 'Loading…' : 'Send Email to Patient'}</Button>
                    
                    </td>
                    </tr>

                </tbody>
                </Table>
}
            </Row> 
 
    );
}

export default ReportView;

import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { Button } from 'react-bootstrap';
import { ApiCall } from '../../../includes/Webservice';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate } from '../../../includes/Helper';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { checkTokenFunction } from '../../../includes/CheckToken'; 
import Spinner from 'react-bootstrap/Spinner'; 
const Category = () => {
    const params = useParams();
    checkTokenFunction()
    return (
        <Row className="g-3">
            <FalconComponentCard>
                <FalconCardHeader
                    title={params.category_id ? "Edit Category" : "Add Category"}
                    light={false}
                    endEl={<Breadcrumb className='fs-11'>
                        <Breadcrumb.Item active><Link to="/dashboard"> Dashboard </Link></Breadcrumb.Item>
                        <Breadcrumb.Item active><Link to="/category/list"> Category  </Link></Breadcrumb.Item> 
                        <Breadcrumb.Item active>
                       {params.category_id  ? 'Edit' :'Add'}
                        </Breadcrumb.Item>
                    </Breadcrumb>}
                />

                <FalconComponentCard.Body>
                    <FormExample categoryid={params.category_id} />
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </Row>
    );
};

function FormExample({ categoryid }) {

    const [validated, setValidated] = useState(false);
    const [category_name, setCategoryName] = useState('');
    const [category_value, setCategoryValue] = useState('');
    const [category_id, setCategoryId] = useState('');
    const baseurl = BaseUrl();
    const navigate = useNavigate();
    const controller = new AbortController();
    const [isloading, setLoad] = useState(false);
    const [is_active, setIsActive] = useState(1);
    React.useEffect(() => { 
        if (categoryid)
        editCategory(categoryid);
    }, [])

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === true) {
            saveData();
        }
        setValidated(true);
    };

    const editCategory = async (category_id) => {
 
        const data ={
            category_id:category_id 
        } 
        const result = await ApiCall(baseurl + "/category/get", "post",  1,0, 0, data, '', controller);

        if (result) {
            // setPageLoad(false)
            if (result.status == true) {             
                setCategoryId(result.data._id)
                setCategoryName(result.data.category_name)
                setIsActive(result.data.is_active) 
            } else {
                navigate('/');
                toast.error(result.message);
            }
        } else {
            // setPageLoad(false)
            toast.error(`Some error occured, try again later` );
            navigate('/');
        }

    }

    const saveData = async () => {
 
        if (category_name.trim() == '') {
            toast.error('Please enter all required fields');
            return false;
        }

        if (category_id == '')
            var url = baseurl + "/category/add";
        else
            var url = baseurl + "/category/update";
            
       
        setLoad(true);

        const data ={
            category_name:category_name,
            is_active:is_active,
            category_id : category_id
        } 
        
        const result = await ApiCall(url, "post",  1,0, 0, data, '', controller);
  
        if (result) {
            setLoad(false);
            if (result.status == true) {               
                toast.success(result.message);
                navigate('/category/list');
            }else{
                toast.error(result.message);
            }
        } else {
            setLoad(false);
            toast.error(`Some error occured, try again later` );
            navigate('/');
        }

    } 
    const setActive = () => {
        if (is_active == 0)
            setIsActive(1);
        else
            setIsActive(0);
    }
    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="category_name">
                    <Form.Label>Category Name <span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                        required
                        type="text"
                        maxLength={100}
                        placeholder="Category Name"
                        defaultValue={category_name}
                        onChange={(e) => setCategoryName(e.target.value)}
                    />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="category_name">
                <Form.Label> Status  <span className='text-danger'>*</span></Form.Label>
                <Form.Check
                            type='switch'
                            id='is_active'
                            label='Tick for Active'
                            onChange={setActive}
                            checked={is_active==1 ? true : false}
                    />   
              </Form.Group>
           {/*}
                <Form.Group as={Col} md="6" controlId="category_name">
                    <Form.Label>Category Value <span className='text-danger'>*</span></Form.Label>

                    <Form.Control
                        required
                        type="text"
                        maxLength={100}
                        placeholder="Category Value To Be Print In Report"
                        defaultValue={category_value}
                        onChange={(e) => setCategoryValue(e.target.value)}
                    />
                   
                </Form.Group> {*/}
            </Row>

            <Form.Group className="mb-12 text-center">
                <Button disabled={isloading} variant="outline-primary" type="submit">  
                {isloading ? 
                <>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className='me-2'
                    /> Loading… </>
                 : 'Submit'}
                </Button>
            </Form.Group>

        </Form>
    );
}

export default Category;

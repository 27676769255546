import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { toast } from 'react-toastify';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate, taskPriorityTextColor } from '../../../includes/Helper';
import { ApiCall } from '../../../includes/Webservice';
import { useNavigate, Link } from 'react-router-dom';
import StatisticsCard from 'components/dashboards/saas/stats-cards/StatisticsCard';
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png'; 

import CategoryProgressChart from './CategoryProgressChart';
import WeeklyProgressChart from './WeeklyProgressChart'
import RecentReports from './RecentReports'
import HospitalReports from './HospitalReports'
import DoctorReports from './DoctorReports'
import ReportTypePriority from './ReportTypePriority'

var statsData = [ 
  {
    title: 'Identifyx.in',
    value: 0,
    decimal: false,
    suffix: '',
    prefix: '',
    valueClassName: 'text-success',
    badgeBg: 'success',
    badgeText: '0.0%',
    link: '/enquiry/identifyx',
    linkText: 'See all',
    image: bg2, 
    baseborder: 'border-bottom border-success border-bottom-2'
  },
  {
    title: 'Radsquad.in',
    value: 0,
    decimal: false,
    suffix: '',
    prefix: '',
    valueClassName: 'text-danger',
    badgeBg: 'danger',
    badgeText: '0.0%',
    link: '/enquiry/radsquad',
    linkText: 'See all',
    image: bg2, 
    baseborder: 'border-bottom border-danger border-bottom-2'
  }   
];

const HospitalDashboard = () => {

  const navigate = useNavigate();
  const baseurl = BaseUrl();
  const controller = new AbortController();
  const [data, setData] = React.useState([]);
  const [isPageloading, setPageLoad] = React.useState(false);
  const userdata = getUserStorageData();


  const onLoad = async () => {
    setPageLoad(true)
     const data = {};
     const result = await ApiCall(baseurl + "/dashboard/count", "post",  1,0, 0, data, '', controller);
    if (result) {
        setPageLoad(false)
        if (result.status == true) {

                  statsData = [ 
                  {
                    title: 'Identifyx.in',
                    value: result.data.identifyx_cnt,
                    decimal: false,
                    suffix: '',
                    prefix: '',
                    valueClassName: 'text-success',
                    badgeBg: 'success',
                    badgeText: '0.0%',
                    link: '/enquiry/identifyx',
                    linkText: 'See all',
                    image: bg2, 
                    baseborder: 'border-bottom border-success border-bottom-2'
                  }, 
                  {
                    title: 'Radsquad.in',
                    value: result.data.radsquad_cnt,
                    decimal: false,
                    suffix: '',
                    prefix: '',
                    valueClassName: 'text-danger',
                    badgeBg: 'danger',
                    badgeText:  '0.0%',
                    link: '/enquiry/radsquad',
                    linkText: 'See all',
                    image: bg1, 
                    baseborder: 'border-bottom border-danger border-bottom-2'
                  }  
                 ]; 

        } else {
          //  navigate('/');
          toast.info(result.message);
        }
    } else {           
        setPageLoad(false);
     ///   toast.error(`Some error occured, try again later` );
        //navigate('/');
    }
}

  
React.useEffect(() => {
    onLoad();
    //deleteUnUsedFiles();
     return () => {
         // Anything in here is fired on component unmount.
         //    console.log('Unmount Component')
         controller.abort();
     }
 
 }, [])
 
  return (
    <>

      <Row className="g-3 mb-3">
     
        <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
       
          <Row className="g-3 mb-3"> 
            {statsData.slice(0, 2).map(stat => (
              <Col key={stat.title} sm={6}>
                <StatisticsCard stat={stat} style={{ minWidth: '12rem' }} />
              </Col>
            ))}
          </Row>
        
        </Col>

       
 
      </Row>
 
    </>
  );
};

export default HospitalDashboard;

import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { Button ,Table ,Image } from 'react-bootstrap';
import { ApiCall } from '../../../includes/Webservice';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate } from '../../../includes/Helper';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FalconCardHeader from 'components/common/FalconCardHeader';
import createMarkup from 'helpers/createMarkup'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'react-bootstrap/Spinner'; 
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery'; 
import { checkTokenFunction } from '../../../includes/CheckToken'; 
const ReportLogView = () => {
    const params = useParams();
    checkTokenFunction();
    return (
        <Row className="g-3">
            <FalconComponentCard>
                <FalconCardHeader
                    title={params.report_id && "Report Log View" }
                    light={false}
                  
                />

                <FalconComponentCard.Body>
                    <FormExample report_id={params.report_id} />
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </Row>
    );
};

function FormExample({ report_id }) {

    const [validated, setValidated] = useState(false);
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [category_name, setCategory] = useState('');
    const [report_type, setRepType] = useState('');
    const [report_response, setrepRes] = useState('');
    const [createdate, setCreateDate] = useState(''); 
    const [ref_doctor_name,setRefDocName] =useState('');
    const [reg_no, setRegNo] = useState(''); 
    const baseurl = BaseUrl();
    const navigate = useNavigate();
    const controller = new AbortController();
    const [isloading, setLoad] = useState(false);
    const [isPageloading, setPageLoad] = React.useState(false);
    const [images, setImages] = useState([]);  
    const [rediologist_name, setDocName] = useState('');
    const [rediologist_designation, setDegName] = useState('');
    

    React.useEffect(() => {
       
        onLoad();
    }, []) 
  

  const onLoad = async () => {
    setPageLoad(true)
   
    //const extraparam = "&api_page=get_profile.php";
   // const result = await ApiCall(baseurl + "/mobile/taskmanagement/api.php", "get", extraparam, 1, 0, 0, '', controller);

   const data ={ 
    report_id:report_id 
   } 
    const result = await ApiCall(baseurl + "/report/logdetail", "post",  1,0, 0, data, '', controller);

    if (result) { 
        setPageLoad(false)
      if (result.status ==  true) { 
      //  console.log(result.data)
        
        var str  = (result.data.report_response)
        if(str)
        {
            var newstr = str.replaceAll('\n', '<br/>'); 
            setrepRes(newstr)
        }else{
            setrepRes(str)
        }
        setRepType(result.data.report_type)
        setCreateDate(showDBdatetime(result.data.createdate));
        setFirstName(result.data.patient_id.first_name)
        setLastName(result.data.patient_id.last_name)
        setAge(result.data.patient_id.age)
        setGender(result.data.patient_id.gender)
        setCategory(result.data.category_id.category_name)
        setRegNo(result.data.reg_no)
        setRefDocName(result.data.ref_doctor_name)
        setDocName(result.data.rediologist_name)
        setDegName(result.data.rediologist_designation)
     //   console.log(result.imagedata)
   //     setImages(result.imagedata)
 
        var imgarray = []
        for(var i=0;i<result.imagedata.length;i++)
             imgarray.push(result.imagedata[i].file)
 
           setImages(imgarray)
            
      }  
    } else {
        setPageLoad(false)
    } 

  }

    
    return (
      
            <Row className="mb-3">
                {
                        isPageloading ?
                        <Spinner animation="grow"   />
                        : 
            <Table className="fs-10 mt-3">

                <tbody>

                <tr key={1}>
                    <td className="bg-100" width={'15%'}>  <strong> PATIENT'S NAME </strong> </td>
                    <td width={'35%'}>{first_name} {last_name}</td>
                    <td className="bg-100" width={'15%'}> <strong> AGE/SEX </strong></td>
                    <td width={'35%'}>{age}/{gender}</td>
                </tr>

                <tr key={2}>
                    <td className="bg-100" > <strong>REF. BY </strong></td>
                    <td>{ref_doctor_name}</td>
                    <td className="bg-100" > <strong> DATE  </strong></td>
                    <td>{createdate}</td>
                </tr>

                <tr key={3}>
                    <td className="bg-100" ><strong>PART SCANNED </strong> </td>
                    <td>{category_name}</td>
                    <td className="bg-100" >  <strong> REG. NO. </strong> </td>
                    <td>{reg_no}</td>
                </tr>

                <tr key={4}>
                    <td colSpan={4} className="text-center"  >
                         <h3>{report_type}: {category_name} </h3>
                    </td> 
                </tr>

                  
                <tr key={6}>
                    <td colSpan={4} className="text-left"  >
                     
                     <p className="mb-1 fw-semibold" dangerouslySetInnerHTML={createMarkup(report_response)} />
                                   
                    </td> 
                </tr>

                <tr key={7}>
                    <td colSpan={4} className="text-right"  >
                     {rediologist_name}
                    </td> 
                </tr>

                <tr key={8}>
                    <td colSpan={4} className="text-right"  >
                     {rediologist_designation}
                    </td> 
                </tr>

                <tr key={9}>
                         
                        <td colSpan={4} className="text-left" >  
                     {/*}
                        <div className="row kb-attach-box mb-3">
                            {
                                images.map((data, index) => {
                                    const {  name ,file ,path} = data;
  
                                    return (
                                        <div className="col-md-3 file-atc-box" key={index}>   
                                        {
                                            name.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                                <div className="file-image"> <img src={file} alt="" /> </div> :
                                                <div className="file-image">
                                                    <FontAwesomeIcon icon="file-alt" />
                                                </div> 
                                        }      
                                        </div>
                                         
                                    )
                                })
                            }
                        </div>{*/} 
                      

                        <FalconLightBoxGallery images={images}>
                                    {setImgIndex => (
                                        <Row className="g-2">
                                     { images.map((item, index) => (
                                        <Col key={index} xs={6} className='file-image'> 
                                            <Image
                                            className="cursor-pointer"
                                            src={item}
                                            fluid
                                            rounded
                                            onClick={() => setImgIndex(index)}
                                            />
                                        </Col>
                                            ))}
                                    
                                        </Row>
                                    )}
                                    </FalconLightBoxGallery>

                        </td>
                    </tr>
 

                </tbody>
                </Table>
}
            </Row> 
 
    );
}

export default ReportLogView;

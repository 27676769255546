import React, { useState } from 'react';
import { Col, Form, Row ,Table} from 'react-bootstrap';
import { toast } from 'react-toastify';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { Button } from 'react-bootstrap';
import { ApiCall } from '../../../includes/Webservice';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate ,showFileType, ValidFileType ,FileUrlPath ,states_array } from '../../../includes/Helper';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FalconCardHeader from 'components/common/FalconCardHeader';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { nanoid } from 'nanoid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
const PaymentStatus = () => {
    const params = useParams();

    return (
        <Row className="g-3">
            <FalconComponentCard>
                <FalconCardHeader
                    title={  "Payment Status"}
                    light={false} 
                />

                <FalconComponentCard.Body>
                    <FormExample  payment_id={params.paymentId} />
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </Row>
    );
};

function FormExample({ payment_id }) {
 
   const [payment_status, setPayStatus] = useState(false); 
   const [payment_data, setPayData] = useState({}); 
   const [amount, setAmt] = useState(0); 
   
   const baseurl = BaseUrl();
   const navigate = useNavigate();
   const controller = new AbortController();
    React.useEffect(() => {
        getData(payment_id)
    }, [])

    
    const getData = async (payment_id) => {
 
        const data ={
            payment_id:payment_id 
        } 
        const result = await ApiCall(baseurl + "/payment/get", "post",  1,0, 0, data, '', controller);

        if (result) { 
           
            if (result.status == true) {
                
                 setPayStatus(true)
                 setPayData(result.data)
                 if(result.data.amount)
                 setAmt((result.data.amount).toFixed(2))

            } else {
                toast.error(result.message);
               // navigate('/');
            }
        } else {
            // setPageLoad(false)
           // navigate('/');
            toast.error(`Some error occured, try again later`, {
                theme: 'colored'
              });
        } 
    }
 

    return (
         
            <Row className="mb-3">                 
                <Col  sm={12} md={12} lg={12} xl={12} xxl={12} >
                <Table className="fs-10 mt-3">

                        <tbody>

                        <tr key={6}>
                            <td colSpan={2}>
                            <h4>Your payment process is done, below is the payment status !</h4>
                            </td>
                        </tr>    

                        <tr key={1}>
                            <td className="bg-100" style={{ width: '30%' }}>
                            Payment Status
                            </td>
                            <td>{payment_data.payment_status}</td>
                        </tr>

                        <tr key={2}>
                            <td className="bg-100" style={{ width: '30%' }}>
                            Amount
                            </td>
                            <td>{amount}</td>
                        </tr>

                        <tr key={3}>
                            <td className="bg-100" style={{ width: '30%' }}>
                            Order Id
                            </td>
                            <td>{payment_data.order_id}</td>
                        </tr>

                        <tr key={4}>
                            <td className="bg-100" style={{ width: '30%' }}>
                            Paymemy Id
                            </td>
                            <td>{payment_data.payment_id}</td>
                        </tr>

                        <tr key={5}>
                            <td className="bg-100" style={{ width: '30%' }}>
                            Date
                            </td>
                            <td>{showDBdatetime(payment_data.date)}</td>
                        </tr>

                        <tr key={7}>
                            <td colSpan={2}>
                            <Link to="/my-transaction">Check Transaction History</Link>
                            </td>
                        </tr>

                        </tbody>

                        </Table>
                    </Col>   
            </Row>
    );
}

export default PaymentStatus;

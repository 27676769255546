import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ApiCall } from './../includes/Webservice';
import { BaseUrl, encryptAES } from './../includes/Helper';
import Spinner from 'react-bootstrap/Spinner'; 
const LoginForm = ({ hasLabel, layout }) => {
  const baseurl = BaseUrl();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [isloading, setLoad] = useState(false);
  // State
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    remember: false
  });

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();


    if (!formData.username || !formData.password) {
      toast.error(`Enter username and password`, {
        theme: 'colored'
      });
      // setError(true);
      return false;
    }  

    setLoad(true);
    
    const data = {
      username : formData.username,
      password : formData.password
    }
    const result = await ApiCall(baseurl + "/user/signin", "post", 0,0,0, data);

    if (result) {
      setLoad(false);
      if (result.status == true) {
       const userdata = encryptAES(JSON.stringify(result.data.user));
       //console.log(result)
        localStorage.setItem("abs_userdata", userdata);
        localStorage.setItem("abs_token", result.data.token)
        navigate('/dashboard')

        toast.success(`Logged in as ${formData.username}`, {
          theme: 'colored'
        }); 

      } else {

        toast.error(`${result.message}`, {
          theme: 'colored'
        });

      }
    } else {
      setLoad(false);
      toast.error(`Some error occured, try again later`, {
        theme: 'colored'
      });
    }

  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Username</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Username' : ''}
          value={formData.username}
          name="username"
          onChange={handleFieldChange}
          type="username"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          {/*}
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Remember me
            </Form.Check.Label>
          </Form.Check>
            {*/}
        </Col>

        <Col xs="auto">
         
          <Link
            className="fs-10 mb-0"
            to={`/forgot-password`}
          >
            Forgot Password?
          </Link>
         
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.username || !formData.password || isloading}
        >

        {isloading ? 
          <>
          <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className='me-2'
              /> Please wait..</>
            : 'Log in'} 
          
        </Button>
      </Form.Group>


    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;

import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { toast } from 'react-toastify';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate, taskPriorityTextColor } from '../../../includes/Helper';
import { ApiCall } from '../../../includes/Webservice';
import { useNavigate, Link } from 'react-router-dom';
import StatisticsCard from 'components/dashboards/saas/stats-cards/StatisticsCard';
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png'; 

import CategoryProgressChart from './CategoryProgressChart';
import WeeklyProgressChart from './WeeklyProgressChart'
import RecentReports from './RecentReports'
import HospitalReports from './HospitalReports'
import DoctorReports from './DoctorReports'
import ReportTypePriority from './ReportTypePriority'

import DoctorWallet from './DoctorWallet'
import HospitalWallets from './HospitalWallets'

var statsData = [
  {
    title: 'Total Hospitals ',
    value: 0,
    decimal: false,
    suffix: '',
    prefix: '',
    valueClassName: 'text-warning',
    badgeBg: 'warning',
    badgeText: '-0.23%',
    link: '/hospital/list',
    linkText: 'See all',
    image: bg1, 
    baseborder: 'border-bottom border-warning border-bottom-2'
  },
  {
    title: 'Total Doctors ',
    value: 0,
    decimal: false,
    suffix: '',
    prefix: '',
    valueClassName: 'text-info',
    badgeBg: 'info',
    badgeText: '-0.23%',
    link: '/doctor/list',
    linkText: 'See all',
    image: bg2, 
    baseborder: 'border-bottom border-info border-bottom-2'
  },
  {
    title: 'Total Patients',
    value: 0,
    decimal: false,
    suffix: '',
    prefix: '',
    valueClassName: 'text-success',
    badgeBg: 'success',
    badgeText: '0.0%',
    link: '/patient/list',
    linkText: 'See all',
    image: bg1, 
    baseborder: 'border-bottom border-success border-bottom-2'
  },
  {
    title: 'Total Reports',
    value: 0,
    decimal: false,
    suffix: '',
    prefix: '',
    valueClassName: 'text-danger',
    badgeBg: 'danger',
    badgeText: '0.0%',
    link: '/report/list',
    linkText: 'See all',
    image: bg2, 
    baseborder: 'border-bottom border-danger border-bottom-2'
  }  
];  

const Dashboard = () => {

  const navigate = useNavigate();
  const baseurl = BaseUrl();
  const controller = new AbortController();
  const [data, setData] = React.useState([]);
  const [isPageloading, setPageLoad] = React.useState(false);
  
  React.useEffect(() => {
 
         onLoad();
      //   deleteUnUsedFiles();

    return () => {
        // Anything in here is fired on component unmount.
        //    console.log('Unmount Component')
        controller.abort();
    }

}, [])

  const onLoad = async () => {
    setPageLoad(true)
     const data = {};
     const result = await ApiCall(baseurl + "/dashboard/count", "post",  1,0, 0, data, '', controller);
    if (result) {
        setPageLoad(false)
        if (result.status == true) {                   
           //     console.log(result.data.patientCount)

                  statsData = [
                  {
                    title: 'Total Hospitals',
                    value: result.data.hospitalCount,
                    decimal: false,
                    suffix: '',
                    prefix: '',
                    valueClassName: 'text-warning',
                    badgeBg: 'warning',
                    badgeText: '-0.23%',
                    link: '/hospital/list',
                    linkText: 'See all',
                    image: bg1, 
                    baseborder: 'border-bottom border-warning border-bottom-2'
                  },
                  {
                    title: 'Total Doctors ',
                    value: result.data.doctorCount,
                    decimal: false,
                    suffix: '',
                    prefix: '',
                    valueClassName: 'text-info',
                    badgeBg: 'info',
                    badgeText: '-0.23%',
                    link: '/doctor/list',
                    linkText: 'See all',
                    image: bg2, 
                    baseborder: 'border-bottom border-info border-bottom-2'
                  },
                  {
                    title: 'Total Patients',
                    value: result.data.patientCount,
                    decimal: false,
                    suffix: '',
                    prefix: '',
                    valueClassName: 'text-success',
                    badgeBg: 'success',
                    badgeText: '0.0%',
                    link: '/patient/list',
                    linkText: 'See all',
                    image: bg1, 
                    baseborder: 'border-bottom border-success border-bottom-2'
                  }, 
                  {
                    title: 'Total Reports',
                    value: result.data.reportCount,
                    decimal: false,
                    suffix: '',
                    prefix: '',
                    valueClassName: 'text-danger',
                    badgeBg: 'danger',
                    badgeText:  '0.0%',
                    link: '/report/list',
                    linkText: 'See all',
                    image: bg1, 
                    baseborder: 'border-bottom border-danger border-bottom-2'
                  }  
                 ]; 

        } else {
          //  navigate('/');
          toast.info(result.message);
        }
    } else {           
        setPageLoad(false);
      ///  toast.error(`Some error occured, try again later` );
        //navigate('/');
    }
}

const deleteUnUsedFiles = async () => {
  //setPageLoad(true)
   const data = {};
   const result = await ApiCall(baseurl + "/deletefiles", "post",  1,0, 0, data, '', controller);
  if (result) {
     // setPageLoad(false)
      if (result.status == true) { 
        //toast.success(result.message);
      } else {
        //  navigate('/');
       // toast.error(result.message);
      }
  } else {           
     // setPageLoad(false);
     // toast.error(`Some error occured, try again later` );
      //navigate('/');
  }
}

  return (
    <>

      <Row className="g-3 mb-3">
     
        <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
        
          <Row className="g-3 mb-3"> 
            {statsData.slice(0, 4).map(stat => (
              <Col key={stat.title} sm={3}>
                <StatisticsCard stat={stat} style={{ minWidth: '12rem' }} />
              </Col>
            ))}
          </Row>
       
        </Col>

        <Col sm={12} md={12} lg={12} xl={6} xxl={6}>
              <CategoryProgressChart/>
        </Col>

        <Col sm={12} md={12} lg={12} xl={6} xxl={6}>
              <WeeklyProgressChart/>
        </Col>

        <Col sm={12} md={12} lg={12} xl={6} xxl={6}>
              <RecentReports/>
        </Col>

        <Col sm={12} md={12} lg={12} xl={3} xxl={3}>
              <HospitalReports/>
        </Col>

        <Col sm={12} md={12} lg={12} xl={3} xxl={3}>
              <DoctorReports/>
        </Col>

        <Col sm={12} md={12} lg={12} xl={4} xxl={4}>
              <ReportTypePriority/>
        </Col>

        <Col sm={12} md={12} lg={12} xl={4} xxl={4}>
              <HospitalWallets/>
        </Col>

        <Col sm={12} md={12} lg={12} xl={4} xxl={4}>
              <DoctorWallet/>
        </Col>

      </Row>
 
    </>
  );
};

export default Dashboard;

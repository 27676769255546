import React, { useState } from 'react';
import { Col, Form, Row ,Image, ProgressBar , OverlayTrigger, Tooltip } from 'react-bootstrap'; 
import { toast } from 'react-toastify';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { Button } from 'react-bootstrap';
import { ApiCall } from '../../../includes/Webservice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate , showFileTypeReport, ValidFileTypeReport ,FileUrlPath , convertReportText ,setFields } from '../../../includes/Helper';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FalconCardHeader from 'components/common/FalconCardHeader';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { nanoid } from 'nanoid'
import Select from 'react-select';
import Flex from 'components/common/Flex';
import Modal from 'react-bootstrap/Modal';
import FalconCloseButton from 'components/common/FalconCloseButton';
import dcm_sample from 'assets/img/dcm_sample.png'
import dcm_sample_incorrect from 'assets/img/dcm_sample_incorrect.jpg'
import Spinner from 'react-bootstrap/Spinner'; 
import { checkTokenFunction } from '../../../includes/CheckToken'; 
import { useDropzone } from 'react-dropzone';
import { getSize } from 'helpers/utils';

const QuickReport = () => { 
    const params = useParams();
    checkTokenFunction();
    return (
        <Row className="g-3">
            <FalconComponentCard>
                <FalconCardHeader
                    title={params.report_id ? "Edit Report" : "Quick Report"}
                    light={false}
                    endEl={<Breadcrumb className='fs-11'>
                        <Breadcrumb.Item active><Link to="/dashboard"> Dashboard </Link></Breadcrumb.Item>
                        <Breadcrumb.Item active><Link to="/report/list"> Report  </Link></Breadcrumb.Item> 
                        <Breadcrumb.Item active>
                       {params.report_id  ? 'Edit' :'Quick Report'}
                        </Breadcrumb.Item>
                    </Breadcrumb>}
                />

                <FalconComponentCard.Body>
                    <FormExample categoryid={params.report_id} />
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </Row>
    );
};

function FormExample({ reportid }) {
 
    const [validated, setValidated] = useState(false);
    const [patient_id, setPatientId] = useState(null);
    const [category_id, setCategoryId] = useState('');
    const [report_id, setReportId] = useState('');
    const [report_type, setReportType] = useState('');
    const [response_type, setResponseType] = useState('response1');
    const [response1, setResponse1] = useState('');
    const [response2, setResponse2] = useState('');
    const [response3, setResponse3] = useState('');
    const [ref_doctor_name, setRefDocName] = useState('');
    const [rediologist_name, setDocName] = useState('');
    const [rediologist_designation, setDegName] = useState('');
    const [patient_array,setPatientArray] = useState([]);
    const [category_array,setCategoryArray] = useState([]);
    const baseurl = BaseUrl();
    const navigate = useNavigate();
    const controller = new AbortController();
    const [isloading, setLoad] = useState(false);
    const [isPageloading, setPageLoad] = React.useState(false);
    const [apicallcount, setApiCallCount] = React.useState(0); 
    const [InputDisable, setInputDisable] = React.useState(false);
    const [wallet_balance,SetWalletBal] =React.useState(0);
    const [upload_seq_active,setUploadOption] =React.useState(0);
    const [RepTypeDisable, setRepTypeDisable] = React.useState(false);
    const [show_rep_type,showRepType] =React.useState(false);
    const [show_res_type,showResType] =React.useState(false);
    const [show_msg,showMsg] =React.useState('');
    const [stop_action,stopAction] =React.useState(false);    
    const [filespathArr,setFilePath] =React.useState([]);  
    const [report_type_array,setReportTypeArray] =React.useState([]); 
    const [modal,hideModal]= React.useState(true);    
    const [zipmodal,hideZipModal]= React.useState(false);    
    const [percentLoad,setpercentLoad]=React.useState(0);
    const [step1,setStep1]= React.useState(0);    
    const [step2,setStep2]= React.useState(0);    
    const [step3,setStep3]= React.useState(0);    
    const [successMsg,setSuccessMsg]= React.useState(false);
    const [medical_history,setMedicalHistory]= React.useState('');
    const [upload_option,setUploadType]= React.useState('');
    const [wallet_id,setWalletId]= React.useState('');    
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const [gender, setGender] = useState('Male');
    const [age, setAge] = useState('');

    const zipfiles = acceptedFiles.map(file => ( 
        <li key={file.path}>        
            {file.path} - {getSize(file.size)} 
        </li>
      
    ));
    
    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const [selectedfile, SetSelectedFile] = useState([]);
    const [Files, SetFiles] = useState([]);

    function doSetTimeout(percent) 
    {
        var i = percent;
        //console.log(i)
        // This block will be executed 100 times.
        setInterval(function() { 
           if (i == 100){ clearInterval(this);}          
           else {                 
            setpercentLoad(i++)
          //  console.log('Currently at ' + (i++));
          }
        }, 1000);
    }

    function doSetTimeoutZip(percent) 
    {
        var i = percent;
        //console.log(i)
        // This block will be executed 100 times.
        setInterval(function() { 
           if (i == 100){ clearInterval(this);}          
           else {                 
            setpercentLoad(i++)
           // console.log('Currently at ' + (i++));
          }
        }, 2500);
    }
    
    React.useEffect(() => {  
        getCategories(); 
    }, [])
  
      const getCategories = async () => {
        setPageLoad(true)
         const data = {};
         const result = await ApiCall(baseurl + "/report/category", "post",  1,0, 0, data, '', controller);
        if (result) {
            setPageLoad(false)
            if (result.status == true) { 
                setCategoryArray(result.data)
                SetWalletBal(result.wallet_balance)
                if(result.upload_seq_active)
                setUploadOption(result.upload_seq_active)
                setReportTypeArray(result.report_type)
               // if(result.rediologist)
                //{ 
                  //  setDocName(result.rediologist.rediologist_name)
//                    setDegName(result.rediologist.rediologist_designation)
  //              }

                if(result.setting_data)
                {
                    if(result.setting_data.setting_value == 1)
                    {
                        navigate('/maintenance');
                    }
                }

            } else {
                //  navigate('/');
                toast.info(result.message);
              }
          } else {           
              setPageLoad(false);
              toast.error(`Some error occured, try again later` );
              //navigate('/');
          }
      }

    const onFileChange = (e) => {
        // --For Multiple File Input
        
        if(selectedfile.length > 19)
        { 
            toast.error('Only 20 Images can be added, if want to add new image remove old one');
            return false;
        } 
        let images = [];
        for (let i = 0; i < e.target.files.length; i++) {
            images.push((e.target.files[i]));
            let reader = new FileReader();
            let file = e.target.files[i];
             
            if (!(ValidFileTypeReport(e.target.files[i].name))) {
               
                toast.error('File type not allowed');
                return false;
            } 

            reader.onloadend = () => {
                SetSelectedFile((preValue) => {
                    return [
                        ...preValue,
                        {
                            id: nanoid(),
                            file: e.target.files[i],
                            filename: e.target.files[i].name,
                            filetype: e.target.files[i].type,
                            fileimage: reader.result,
                            //datetime: e.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
                            filesize: filesizes(e.target.files[i].size),
                            filesizeB: (e.target.files[i].size)
                        }
                    ]
                });
            }
            if (e.target.files[i]) {
                reader.readAsDataURL(file);
            }
        }
    }

    const RemoveFile = (id) => {
        //if(window.confirm("Are you sure you want to remove this file?")){
        const result = selectedfile.filter((data) => data.id !== id);
        SetSelectedFile(result);  
    }

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === true) {
            saveData();
        }
        setValidated(true);
    };
    const calSize=()=>{
        var imagetotalsize = 0;
        for (let index = 0; index < selectedfile.length; index++) {
           // console.log(selectedfile[index].filesizeB)
            imagetotalsize = imagetotalsize + selectedfile[index].filesizeB
        }
        imagetotalsize = (imagetotalsize / (1024*1024)).toFixed(2);
        return imagetotalsize;  
    }
  
    const openZipUpload =()=>{

        if ( category_id.trim() == '') {
            toast.error('Please select category');
            return false;
        }

        if (category_id.trim() == '' || age.trim() == '' || gender.trim() == ''   ) {
            toast.error('Please enter all required fields');
            return false;
        }

        hideZipModal(true) 
    }
    const uploadZipFile= async()=>{
         
        if (category_id.trim() == '') {
            toast.error('Please select category');
            return false;
        }

        if (age.trim() == '' || gender.trim() == '' || category_id.trim() == ''  ) {
            toast.error('Please enter all required fields');
            return false;
        }

        if(acceptedFiles.length > 0 )
        {
          //  console.log(acceptedFiles[0])
        const fileName = acceptedFiles[0].name ; 
        const file_extensionArr = fileName.split('.');
        const fileType = file_extensionArr.at(-1);
       
        const text = ['zip','rar'];
        if (text.includes(fileType)) {
            const fileSize = acceptedFiles[0].size
            const sizeInMB = (fileSize / (1024*1024)).toFixed(2);
            //console.log(sizeInMB)
            if(sizeInMB > 700)
            {
                toast.error(`File sizes must be less than 700 MB`);
                return false;
            }else{
                    // upload file here

                    var url = baseurl + "/quick_uploadzipfile";  
                    
                    setLoad(true);
                    hideZipModal(false) 
                    doSetTimeoutZip(percentLoad)
                    let formData = new FormData()     
                    formData.append('patient_id', '0')    
                    formData.append('category_id', category_id)    
                    formData.append('medical_history', medical_history)   
                    formData.append('gender', gender)
                    formData.append('age', age)
                    const udata =  getUserStorageData();  
                    formData.append('user_id', udata.user_id)
                    formData.append('user_name', udata.user_name)
                    formData.append('user_type', udata.user_type)
                    formData.append('zipfile' , (acceptedFiles[0]))
                    
                    const data = formData
        
                    setInputDisable(true) 
                    setStep1(1)
                    const result = await ApiCall(url, "post",  1, 0, 1, data, '', controller);
              
                    if (result) {
                        
                        setLoad(false); 
                        if (result.status == true) 
                        {
                            setUploadType('Zip Upload')
                            setStep1(2)
                            setFilePath(result.data)    
                            analyzeImage(result.data);              
                        }else{
                            setStep1(3)
                            toast.error(result.message)
                        } 
                    }else{
                        setLoad(false);
                        setStep1(3)
                        toast.error("Some problem occured try again later")
                    }    
            } 
               
            } else {
                toast.error(`Only zip or rar file type supported`);
                return false;
            }   
        }else{
            toast.error(`Please select zip or rar file`);
            return false;
        }
    }
    const uploadFile= async()=>{
        if(apicallcount == 0)
        {          
            const sizeInMB = calSize();
            if(sizeInMB > 18)
            {
                toast.error(`File sizes must be less than 18 MB`);
                return false;
            } 
            
            var url = baseurl + "/quick_uploadfile";   
            setLoad(true);
     
            let formData = new FormData()     
            formData.append('patient_id', '0')    
            formData.append('category_id', category_id)    
            formData.append('medical_history', medical_history)   
            formData.append('gender', gender)
            formData.append('age', age)
            const udata =  getUserStorageData();  
            formData.append('user_id', udata.user_id)
            formData.append('user_name', udata.user_name)
            formData.append('user_type', udata.user_type)
    
            if (selectedfile.length > 0) {
                for (let index = 0; index < selectedfile.length; index++) {
                    formData.append('uploadfile' , (selectedfile[index].file))
                }
            }
            const data = formData

            setInputDisable(true) 
            setStep1(1)
            const result = await ApiCall(url, "post",  1, 0, 1, data, '', controller);
      
            if (result) {
                setLoad(false);
              //  setpercentLoad(40);
                if (result.status == true) 
                {
                    setUploadType('Image Upload')
                    setStep1(2)
                    setFilePath(result.data)    
                    analyzeImage(result.data);
                   /// processImage(result.data);              
                }else{
                    setStep1(3)
                    toast.error(result.message)
                } 
            }else{
                setLoad(false);
                setStep1(3)
                toast.error("Some problem occured try again later")
            }           
        }
    }
  
    const analyzeImage= async(filespath)=>{
        if(apicallcount == 0)
        { 
            const sizeInMB = calSize();
            if(sizeInMB > 18)
            {
                toast.error(`File sizes must be less than 18 MB`);
                return false;
            } 

            setInputDisable(true) 
         //   setpercentLoad(60);
            const api_name = 'REPORT_TYPE_API';     
            const api_url = 'GetCategory';  
            setStep2(1)
           // const result = await callApiLog('REPORT_TYPE_API',url,filespath);
   
            setLoad(true);
    
            let formData = new FormData()  
            formData.append('api_name', api_name)     
            formData.append('api_url', api_url)    
            formData.append('patient_id', '0') 
            formData.append('category_id', category_id)    
            formData.append('medical_history', medical_history)   
            formData.append('gender', gender)
            formData.append('age', age)
            formData.append('filespath', JSON.stringify(filespath)); 
            const udata =  getUserStorageData();  
            formData.append('user_id', udata.user_id)
            formData.append('user_name', udata.user_name)
            formData.append('user_type', udata.user_type) 
            var url = baseurl + "/apicall";  
            const data = formData
            const result = await ApiCall(url, "post",  1, 0, 0, data, '', controller);
    
            if (result) {
                setLoad(false);
                if (result.status == true) {    
                         
                    var output = result.response;
                    setWalletId(result.wallet_id)
                  //  result.API_Response

                    if(output == '')
                        { 
                            setStep2(3) 
                            showRepType(false)
                            showMsg('Sorry, blank response from an server');
                            toast.error('Sorry, blank response from an server'); 
                            clearInterval();
                            stopAction(true) 
                        }
                        else if(output.API_Response == 'Sorry, we do not support sonography')
                        {
                            setStep2(3) 
                            showRepType(false)
                            stopAction(true)
                            showMsg('Sorry, we do not support sonography')
                            toast.error('Sorry, we do not support sonography'); 
                            clearInterval(); 

                        }else if(output.API_Response =='Sorry, we did not recognise the image. Please upload an MRI, CT, or XRAY image')
                        {
                            setStep2(3) 
                            showRepType(true)
                            showMsg('Sorry, we did not recognise the image. Please upload an MRI, CT, or XRAY image, Please select the report type as MRI/CT/XRAY from the dropdown')
                            toast.error('Sorry, we did not recognise the image. Please upload an MRI, CT, or XRAY image');
                            setRepTypeDisable(false)     
                            clearInterval();           
                        }  
                        else
                        { 
                            setStep2(2) 
                            const reporttype = (output.API_Response).toUpperCase();
                            showRepType(true)
                            setRepTypeDisable(true)                       
                            setReportType(reporttype)
                            processImage(filespath);
                        }
                    
                   // return result.response                 
                }else{
                    stopAction(true)
                    setStep2(3);
                    toast.error(result.message);
                    //return false;
                }
            } else {
                stopAction(true)
                setStep2(3); 
                toast.error(result);
                setLoad(false);
               // return false;         
            } 
         
        }
    }
    const processImage= async(filespath)=>{
      
        if(apicallcount < 3)
        {
            const sizeInMB = calSize();
            if(sizeInMB > 18)
            {
                toast.error(`File sizes must be less than 18 MB`);
                return false;
            } 
            const api_name = 'REPORT_REPONSE_API' 
            const api_url = 'GetReponse';
            setStep3(1)
            
           setLoad(true);
    
           let formData = new FormData()  
           formData.append('api_name', api_name)     
           formData.append('api_url', api_url)    
           formData.append('patient_id', '0') 
           formData.append('category_id', category_id)    
           formData.append('medical_history', medical_history)   
           formData.append('gender', gender)
           formData.append('age', age)
           formData.append('filespath', JSON.stringify(filespath)); 
           const udata =  getUserStorageData();  
           formData.append('user_id', udata.user_id)
           formData.append('user_name', udata.user_name)
           formData.append('user_type', udata.user_type) 

           var url = baseurl + "/apicall";
           const data = formData
           const result = await ApiCall(url, "post",  1, 0, 0, data, '', controller);
     
           if (result) {
               setLoad(false);
               if (result.status == true) {               
                  // return result.response    
                   
                   setStep3(2)
                    var content = result.response
                    if(content)
                    {
                    var content = result.response.API_Response
                           
                   content =  convertReportText(content);

                        showResType(true)
                        setpercentLoad(0);
                        setResponse1(content)
                
                        setTimeout(function () {
                            setSuccessMsg(true)  
                        }, 500)
                    }else{
                        toast.error('Sorry, blank response from an server');
                    }
               }else{
                    setStep3(3);  
                    toast.error(result.message);
                  // return false;
               }
           } else {
               setStep3(3); 
               toast.error(result);
               setLoad(false);
              // return false;         
           } 
            
        }
    }
    const manuallyCheck= async (reptype)=>{
        setReportType(reptype)
        setRepTypeDisable(true)  
        doSetTimeout(percentLoad)

        const data ={
            report_type:reptype ,
            patient_id : '0'
        } 
        const result = await ApiCall(baseurl + "/set_report_type", "post",  1,0, 0, data, '', controller);

        if (result) { 
           
            if (result.status == true) {
                
                processImage(filespathArr);
                setWalletId(result.wallet_id)

            } else {
                toast.error(result.message); 
            }
        } else {
            
            toast.error(`Some error occured, try again later `);
        } 
         
    }
    
    const tryAgain =async()=>{
        if (category_id.trim() == '') {
            toast.error('Please select patient & category');
            return false;
        }   
        
        if(selectedfile.length > 0)
        { 
            const apicallcount_n = apicallcount + 1
            setApiCallCount(apicallcount_n)
            doSetTimeout(percentLoad)
            processImage(filespathArr); 
            
        }else{
            toast.error(`Please select file to generate report`);
        }
        
    }
    const generateReport = async()=>{
        
        if ( category_id.trim() == '') {
            toast.error('Please select patient & category');
            return false;
        }

        if (gender.trim() == '' || age.trim() == '' || category_id.trim() == '') {
            toast.error('Please enter all required fields');
            return false;
        }

        if(selectedfile.length > 20)
        { 
            toast.error('Only 20 Images can be added, if want to add new image remove old one');
            return false;
        } 
       
        if(selectedfile.length > 0)
        { 
            const apicallcount_n = apicallcount + 1
            setApiCallCount(apicallcount_n)
            if(apicallcount_n == 1){
                doSetTimeout(percentLoad)
                uploadFile(); // run first time only                
            }else{
                doSetTimeout(percentLoad)
                analyzeImage(filespathArr);  
            }
            
        }else{
            toast.error(`Please select file to generate report`);
        }
    }

    React.useEffect(() => {
       // console.log('successMsg' ,successMsg);
        if (successMsg == true) { 
            saveData();
        }
      }, [successMsg]);
 
    const saveData = async () => {
   
        if ( category_id.trim() == '' || report_type.trim() == '' || response1.trim()=='' || age.trim()=='' || gender.trim()=='' ) 
        {
            toast.error('Please enter all required fields');
            return false;
        }

       // if (report_id == '')
            var url = baseurl + "/report/quick";
     //   else
       //     var url = baseurl + "/report/update";
        
        setLoad(true); 

        var response = ''
        if(response_type == 'response1')
            response = response1
        else  if(response_type == 'response2')
            response = response2
        else  if(response_type == 'response3')
            response = response3

        let formData = new FormData()  
        formData.append('patient_id', '0')
        formData.append('category_id', category_id)     
        formData.append('report_type', report_type)    
        formData.append('age', age)
        formData.append('gender', gender)
        formData.append('response', response)  
        formData.append('show_msg', show_msg)  
        formData.append('medical_history', medical_history)   
        formData.append('upload_option', upload_option)  
        formData.append('wallet_id', setFields(wallet_id)) 
        formData.append('filespath', JSON.stringify(filespathArr)); 
         
        const udata =  getUserStorageData();  
        formData.append('user_id', udata.user_id)
        formData.append('user_name', udata.user_name)
        formData.append('user_type', udata.user_type)
 
        const data = formData
        
        const result = await ApiCall(url, "post",  1,0, 0, data, '', controller);
  
        if (result) {
            setLoad(false);
            if (result.status == true) {               
                toast.success(result.message);
                navigate('/report/list');
            }else{
                toast.error(result.message);
            }
        } else {
            setLoad(false);
            toast.error(`Some error occured, try again later` );
            navigate('/');
        }
    }
    
    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-2">
                
           
                <Form.Group as={Col} md="4" controlId="category_id">
                    <Form.Label>Category Name <span className='text-danger'>*</span></Form.Label>

                    <Form.Select
                        required
                        disabled={InputDisable} 
                        value={category_id}
                        onChange={(e) => setCategoryId(e.target.value)}
                    >
                        <option value={''}>Select</option>
                        {
                            category_array.map((item, index) => (
                                <option key={index + 1} value={item._id}>{item.category_name} </option>
                            ))
                        }
                        </Form.Select>
                   
                </Form.Group>

                <Form.Group as={Col} md="4" controlId="age">
                    <Form.Label>Patient Age <span className='text-danger'>*</span></Form.Label> 
                    <Form.Control
                        required
                        type="text"
                        maxLength={3}
                        placeholder="Age"
                        defaultValue={age}
                        disabled={InputDisable} 
                        onChange={(e) => setAge(e.target.value)}
                    />   
                </Form.Group>

                <Form.Group as={Col} md="4" controlId="gender"> 
                    <Form.Label> Patient Gender <span className='text-danger'>*</span></Form.Label>
                    <Form.Select
                        required
                        defaultValue={gender}
                        disabled={InputDisable} 
                        onChange={(e) => setGender(e.target.value)}
                    >         
                    <option value={''}>Select</option>          
                    <option value={'Male'}>Male</option>
                    <option value={'Female'}>Female</option> 
                    </Form.Select>
                   
                </Form.Group>
                </Row>        
                       
                <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="logo"> 
                <Form.Label>
                    <span className='me-3'>Report Images   </span>
                   
                </Form.Label>
                <Row>
                    
                    <Col className='md-6 text-center'> 
                    
                    {InputDisable ==false &&
                    <>
                    {(upload_seq_active ==1 || getUserStorageData().user_type=='SUPERADMIN') ?
                    
                    <div className="kb-file-upload">
                     <div className="file-upload-box">
                    
                        <span>  <span className="file-link"> 
                        <Button variant='info'  disabled={isloading}  onClick={openZipUpload} className='me-2 mb-1 btn-sm'>Upload MRI/CT Sequences</Button>
                        
                        </span>  <br /><small>( Only zip or rar file type allowed )</small><span className='text-danger'> (1 zip/rar file only) </span></span>

                        </div>
                       </div> 
                    :
                     

                    <div className="kb-file-upload">
                     <div className="file-upload-box">
                   

                    <span>    

                    <Button variant='info' style={{opacity:'0.5'}}    className='me-1 mb-1 btn-sm'>Upload MRI/CT Sequences</Button>
                         <br /> <small className='text-danger'> (Contact our support team in order to enable this option) </small></span>

                        </div>
                       </div> 
 
                    }
                        </>
                        }
                    </Col>

                    <Col className='md-6 text-center'> 
                    
                    {InputDisable ==false &&
                        <div className="kb-file-upload">
                            <div className="file-upload-box">
                                <input accept="image/jpg,image/png,image/jpeg,.dcm" type="file" multiple id="fileupload" className="file-upload-input" onChange={onFileChange}   />

                                <span> <img src={cloudUpload} alt="" width={25} className="me-2" /> Drag and drop or <span className="file-link">Choose your file</span>  <br /><small>{showFileTypeReport()}</small><span className='text-danger'> (Maximum 20 images only) </span></span>
                            </div>
                        </div> 
                     }
                    </Col>
                </Row>
                   
                    <div className="row kb-attach-box mb-3">
                        {
                            selectedfile.map((data, index) => {
                                const { id, filename, fileimage, filesize } = data;
                                return (
                                    <div className="col-md-4 file-atc-box" key={id}>
                                        {
                                            filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                                <div className="file-image"> <img src={fileimage} alt="" /></div> :
                                                <div className="file-image">
                                                    <FontAwesomeIcon icon="file-alt" />
                                                </div>
                                        }
                                        <div className="file-detail">
                                            <h6>{filename}</h6>
                                            <p></p>
                                            <p><span>Size : {filesize}</span>{/*}<span className="ml-2">Modified Time : {datetime}</span>{*/}</p>
                                            <div className="file-actions">
                                             {InputDisable==false &&
                                                <button type="button" className="file-action-btn" onClick={() => RemoveFile(id)}>Remove</button>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    
                  </Form.Group> 
             
                <Row  className="mb-3">
                {show_rep_type==true &&
                    <Form.Group as={Col}  className="mb-2" md="3" controlId="report_type">
                        <Form.Label>Report Type <span className='text-danger'>*</span></Form.Label>
                        <Form.Select
                            disabled={RepTypeDisable}
                            required
                            type="text"           
                            value={report_type}
                            onChange={(e) => manuallyCheck(e.target.value) }
                        >  
                        <option value={''}>Select</option>
                            {
                                report_type_array.map((item, index) => (
                                    <option key={index + 1} value={item.report_type}>{item.report_type} - ({item.wallet} Tokens)</option>
                                ))
                            }
                            </Form.Select>                  
                    </Form.Group>  
                }
                {show_msg != '' && 
                    <Form.Group as={Col}  className="mb-2" md="9" controlId="show_msg">
                        <Form.Label> </Form.Label>                   
                        <p className='text-center text-danger me-2'>{show_msg}</p>                   
                 </Form.Group>   
                }
                </Row>
               
                {show_res_type==true &&
                <Row className="mb-3">
                <Form.Group as={Col} md={12} controlId="response1"> {/* apicallcount == 1 ? 12 : apicallcount == 2 ? 6  : 4  */}
                 <Form.Label> 
                    <Flex>
                    <Form.Check 
                    type='checkbox'
                    id='checkedCheckbox'  
                    checked={response_type== 'response1' ? true : false}
                    className='me-3'
                    onChange={() => setResponseType('response1')}
                  /> Report Text <span className='text-danger'>*</span>
                    </Flex>  
                 </Form.Label>
                 <Form.Control
                        as="textarea"
                        rows={15}
                        required
                        readOnly
                        placeholder="API Response"
                        value={response1} 
                        onChange={(e) => setResponse1(e.target.value)}
                    />
                </Form.Group>
                   
                </Row>
            }

            <Row className="mb-3">
                {isloading  &&
                   <ProgressBar className='mb-2' animated={true} now={percentLoad}/>
                } 
                <Col className='md-3 text-center'> 
                {step1 ==1 &&
                <Spinner animation="grow" size="sm" className='me-2' />
                }
                {step1 ==2 &&
                <FontAwesomeIcon className='text-success me-2' size='lg' icon={'check-circle'} />
                }
                {step1 ==3 &&
                <FontAwesomeIcon className='text-danger me-2' size='lg' icon={'times-circle'} />
                }
                {step1 > 0 &&
                <>Reading images..</>
                }
                </Col>
                <Col className='md-3 text-center'>  
                {step2 ==1 &&
                <Spinner animation="grow" size="sm" className='me-2' />
                }
                {step2 ==2 &&
                <FontAwesomeIcon className='text-success me-2' size='lg' icon={'check-circle'} />
                }
                {step2 ==3 &&
                <FontAwesomeIcon className='text-danger me-2' size='lg' icon={'times-circle'} />
                }
                {step2 > 0 &&
                <>Analysing images..</>
                }
                </Col>
                <Col className='md-3 text-center'>   
                {step3 ==1 &&
                <Spinner animation="grow" size="sm"  className='me-2'/>
                }
                {step3 ==2 &&
                <FontAwesomeIcon className='text-success me-2' size='lg' icon={'check-circle'} />
                }
                {step3 ==3 &&
                <FontAwesomeIcon className='text-danger me-2' size='lg' icon={'times-circle'} />
                }
                 {step3 > 0 &&
                <>Generating report..</>
                 }
                </Col>
                 
                {stop_action == false &&
                <>
                 {((wallet_balance > 100) || getUserStorageData().user_type=='SUPERADMIN') ?
                      <Form.Group className="mb-12 mt-3 text-center">

                      {apicallcount < 3 ?
                        <>
                            {apicallcount == 0 &&
                               <Button onClick={generateReport} disabled={isloading} variant="outline-primary" type="button">  {isloading ? 
                             <>   <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className='me-2'
                                    />
                                    Sit Back and Relax, we are processing...
                                </>
                               
                               :  'Generate Report'  }</Button>
                            }
                            {/*}:  
                            <Button onClick={tryAgain} disabled={isloading} variant="outline-primary" type="button">  {isloading ? 'Please Wait..' : 'Try Again'    }
                            </Button> {*/}                           
                        
                        </>
                        : 'Your API call count is finished for this Report'
                      }
                    </Form.Group>
                      :<div className='text-center text-danger to generate report'>You don't have sufficient wallet balance to generate report
                      <br/>
                      <Link className='me-2 mt-2 btn btn-falcon-primary btn-sm'  to="/paynow"> Add Amount In Wallet </Link>
                      </div>  }
                   </> 
                  } 
                </Row> 

            </Row>
            <>{successMsg}</>
            {stop_action == false &&
                <>
                {show_res_type==true && 
                <Form.Group className="mb-12 text-center">
                    <Button disabled={isloading} variant="outline-primary" type="submit">  {isloading ? 'Loading…' : 'Submit'}</Button>
                </Form.Group>
                }
                </>
            }
       
         <Modal
                size="lg"
                show={modal}
                onHide={() => hideModal(false)}
                aria-labelledby="example-modal-sizes-title-xs"
                centered={true}
            >
            <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-xs">
                         <FontAwesomeIcon icon={'info-circle'} />   Alert For File Upload 
                    </Modal.Title>
                    <FalconCloseButton onClick={() => hideModal(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col className='col-6 fs-9 text-center'> 
                        <p>The user can upload DICOM, JPG, PNG, JPEG images and below is the correct format for image.</p>
                        <p className='text-success'>Correct Image</p>
                        <Image                        
                            fluid 
                            className="shadow-sm"
                            src={dcm_sample}
                            width={180}
                            height={100}
                        />
 
                        </Col>
                        <Col className='col-6 fs-9 text-center'> 
                        <p>Do not upload multiple images embedded in a single image.
                        If you wish to upload 20 images then 20 single image files should be uploaded.</p>
                        <p className='text-danger'>Incorrect Image</p>
                        <Image                        
                            fluid 
                            className="shadow-sm"
                            src={dcm_sample_incorrect}
                            width={180}
                            height={100}
                        />
                        </Col> 
                         
                    </Row> 
                </Modal.Body> 
                <Modal.Footer style={{'textAlign': 'center','display': 'inline'}}>
                    <Button  onClick={() => hideModal(false)} variant="outline-primary"  >
                       Okay, I Agree 
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                size="lg"
                show={zipmodal}
                onHide={() => hideZipModal(false)}
                aria-labelledby="example-modal-sizes-title-xs"
                centered={true}
            >
            <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-xs">
                    <img src={cloudUpload} alt="" width={25} className="me-2" />  Upload MRI/CT Sequences 
                    </Modal.Title>
                    <FalconCloseButton onClick={() => hideZipModal(false)} />
                </Modal.Header>
                <Modal.Body>
                     
                        <>
                        <div {...getRootProps({ className: 'dropzone-area py-2' })}>
                            <input {...getInputProps({multiple:false})} />
                            
                            <img src={cloudUpload} alt="" width={25} className="me-2" />
                            <p className="fs-9 mb-0 text-700">Drop your file here</p>
                           
                        </div>
                        <div className="mt-1 mb-3">
                            {acceptedFiles.length > 0 && (
                            <>
                                <h6>File</h6>
                                <ul>{zipfiles}</ul>
                            </>
                            )}
                        </div>
                        </>
                        
                </Modal.Body> 
                <Modal.Footer style={{'textAlign': 'center','display': 'inline'}}>
                    <Button  disabled={isloading}  onClick={() => uploadZipFile()} variant="outline-primary"  >
                    {isloading ? 'Uploading…' : 'Upload'}   
                    </Button>
                </Modal.Footer>
            </Modal>
        
        </Form>
    );
}

export default QuickReport;

import React, { useState ,useEffect} from 'react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Tab, Nav, Row, Col, Table,Card, Form, Button } from 'react-bootstrap';
import { ApiCall } from './../includes/Webservice';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate ,showFileType, ValidFileType ,FileUrlPath ,states_array ,country_array } from './../includes/Helper';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { checkTokenFunction } from './../includes/CheckToken'; 
const ProfileEdit = () => {
    const [validated, setValidated] = useState(false);
    const controller = new AbortController();
    const [isloading, setLoad] = useState(false);
    const baseurl = BaseUrl();
    const navigate = useNavigate();
    const [isPageloading, setPageLoad] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        country: '',
        pincode: '',
        hospital_name :'',
        user_type:''
      });

      checkTokenFunction();

      useEffect(() => {
        onLoad(); 
        return () => {
          // Anything in here is fired on component unmount.
          //  console.log('Unmount Component')
          //  controller.abort();
        }
    
      }, [])

      const onLoad = async () => {
        setPageLoad(true)
       
        //const extraparam = "&api_page=get_profile.php";
       // const result = await ApiCall(baseurl + "/mobile/taskmanagement/api.php", "get", extraparam, 1, 0, 0, '', controller);
    
       const data ={ } 
        const result = await ApiCall(baseurl + "/user/get", "post",  1,0, 0, data, '', controller);
    
        if (result) { 
          setPageLoad(false)
          if (result.status ==  true) {  
            formData.firstName = result.data.first_name
            formData.lastName = result.data.last_name
            formData.email = result.data.email
            formData.phone = result.data.mobile
            formData.address = result.data.address
            formData.city = result.data.city
            formData.state = result.data.state
            formData.pincode = result.data.pincode
            formData.hospital_name = result.data.hospital_name
            formData.user_type = result.data.user_type
            if(result.data.country)
            formData.country = result.data.country
            else
            formData.country = 'India'
            /*
            setFirstName(result.data.first_name)
            setLastName(result.data.last_name)
            setUserName(result.data.username);
            setUserType(result.data.user_type)
            setEmail(result.data.email)
            setMobile(result.data.mobile)
            setCity(result.data.city)
            setAddress(result.data.address)
            setState(result.data.state)
            setPincode(result.data.pincode) */
    
          } else { 
          }
        } else {
          setPageLoad(false)
        } 
    
      }
    
      const handleChange = e => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
      };
    
      const handleSubmit = e => {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;

        if (form.checkValidity() === true) {
            saveData();
        }

        setValidated(true);
      };

      const saveData = async () => {
 
        if (formData.firstName.trim() == '' || formData.lastName.trim() == '' || formData.email.trim() == '' || formData.phone.trim() == '' || formData.address.trim() == '' || formData.city.trim() == '' || formData.state == '' || formData.country == '' || formData.pincode.trim() == '' ) 
        {
            toast.error('Please enter all required fields');
            return false;
        }

         
        var url = baseurl + "/user/update-Profile";
          
        const data = {
            firstName : formData.firstName,
            lastName : formData.lastName,
            email:formData.email,
            phone : formData.phone,
            address : formData.address,
            city:formData.city,
            state : formData.state,
            country:formData.country,
            pincode : formData.pincode,
            hospital_name : formData.hospital_name  
        } 
        setLoad(true);
        const result = await ApiCall(url, "post",  1, 0, 0, data, '', controller);
        
        if (result) {
            setLoad(false);
            if (result.status == true) {               
                toast.success(result.message); 
                navigate('/myprofile');
            }else{
                toast.error(result.message);
            }
        } else {
            setLoad(false);
            toast.error(`Some error occured, try again later` );
            navigate('/');
        }

    } 
    
  return (  
        <Row>
            <Col lg={12} sm={12}> 
            <Card>
            <FalconCardHeader title="Profile Settings" />
            <Card.Body className="bg-body-tertiary">
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                <Row className="mb-3 g-3">
                    <Form.Group as={Col} lg={6} controlId="firstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                    required
                        type="text"
                        placeholder="First Name"
                        value={formData.firstName}
                        name="firstName"
                        onChange={handleChange}
                    />
                    </Form.Group>

                    <Form.Group as={Col} lg={6} controlId="lastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                    required
                        type="text"
                        placeholder="Last Name"
                        value={formData.lastName}
                        name="lastName"
                        onChange={handleChange}
                    />
                    </Form.Group>
                
                    <Form.Group as={Col} lg={4} controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                    required
                        type="email"
                        placeholder="Email"
                        value={formData.email}
                        name="email"
                        onChange={handleChange}
                    />
                    </Form.Group>

                    <Form.Group as={Col} lg={4} controlId="phone">
                    <Form.Label>Mobile</Form.Label>
                    <Form.Control
                    required
                        type="text"
                        placeholder="Mobile"
                        maxLength={10}
                        value={formData.phone}
                        name="phone"
                        onChange={handleChange}
                    />
                    </Form.Group>
                
                {(formData.user_type == 'HOSPITAL' || formData.user_type == 'DOCTOR') &&
                <Form.Group as={Col} lg={4}  controlId="hospital_name">
                    <Form.Label>Hospital / Clinic Name</Form.Label>
                    <Form.Control
                    required
                    type="text"
                    placeholder="Hospital / Clinic"
                    value={formData.hospital_name}
                    name="hospital_name"
                    onChange={handleChange}
                    />
                </Form.Group>
                }
                <Form.Group as={Col} lg={4}  controlId="address">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                    required
                    type="text"
                    placeholder="Address"
                    value={formData.address}
                    name="address"
                    onChange={handleChange}
                    />
                </Form.Group>
 
                  <Form.Group as={Col} lg={4} controlId="city">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="City"
                        value={formData.city}
                        name="city"
                        onChange={handleChange}
                    />
                    </Form.Group>

                    

                    <Form.Group as={Col} lg={4} controlId="country">
                    <Form.Label>Country</Form.Label>
                      
                    <Form.Select
                        required
                        type="text"                    
                        placeholder="Country"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                    >   
                        {
                            country_array.map((item, index) => (
                                <option key={index + 1} value={item}>{item} </option>
                            ))
                        }
                        </Form.Select> 
                    </Form.Group>

                    <Form.Group as={Col} lg={4} controlId="state">
                    <Form.Label>State/Province</Form.Label>

                     
                    {formData.country=='India' ?
                    <Form.Select
                        required
                        type="text"                    
                        placeholder="State"
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                    >  
                    <option value={''}>Select</option>
                        {
                            states_array.map((item, index) => (
                                <option key={index + 1} value={item}>{item} </option>
                            ))
                        }
                        </Form.Select> 
                        :
                        <Form.Control
                            required
                            type="text"
                            placeholder="State"
                            value={formData.state}
                            name="state"
                            onChange={handleChange}
                        /> 
                        }
                    </Form.Group>

                    <Form.Group as={Col} lg={4} controlId="pincode">
                    <Form.Label>Pincode/Zipcode</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="Pincode"
                        value={formData.pincode}
                        name="pincode"
                        maxLength={6}
                        onChange={handleChange}
                    />
                    </Form.Group>
                </Row>
 
                <Form.Group className="mb-12 text-center">
                    <Button disabled={isloading} variant="outline-primary" type="submit">  {isloading ? 'Loading…' : 'Update Profile'}</Button>
                    </Form.Group>  
                </Form>
            </Card.Body>
            </Card>
            </Col>
          
    </Row> 
  );
};

export default ProfileEdit;

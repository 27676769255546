 
export const dashboardRoutes = {
  label: 'dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      children: [
        {
          name: 'Main',
          to: '/dashboard',
          exact: true,
          active: true
        } 
      ]
    }
  ]
}; 
 
export const masterRoutes = {
  label: 'Pages',
  children: [
    {
      name: 'Setting',
      active: true,
      icon: 'cog',
      to: '/setting',
    },  
    {
      name: 'API Setting',
      active: true,
      icon: 'globe',
      children: [
        {
          name: 'Add',
          to: '/apiurl/add',
          active: true
        },
        {
          name: 'List',
          to: '/apiurl/list',
          active: true
        }  
      ]
    },
    {
      name: 'Categories',
      active: true,
      icon: 'wrench',
      children: [
        {
          name: 'Add',
          to: '/category/add',
          active: true
        },
        {
          name: 'List',
          to: '/category/list',
          active: true
        }  
      ]
    },
    {
      name: 'Report Type',
      active: true,
      icon: 'wrench',
      children: [
        {
          name: 'Add',
          to: '/reporttype/add',
          active: true
        },
        {
          name: 'List',
          to: '/reporttype/list',
          active: true
        }  
      ]
    },
    {
      name: 'Wallet List',
      active: true,
      icon: 'wallet',
      to: '/wallet/list',
    },  
    {
      name: 'Payment Transaction',
      active: true,
      icon: 'wallet',
      to: '/transaction/list',
    },
    {
      name: 'Country Wise User',
      active: true,
      icon: 'flag',
      to: '/countrywise',
    },     
    {
      name: 'Enquiry',
      active: true,
      icon: 'envelope',
      badge: {
        type: 'success',
        text: 'New'
      },
      children: [
        {
          name: 'Identifyx.in',
          to: '/enquiry/identifyx',
          active: true
        },
        {
          name: 'Radsquad.in',
          to: '/enquiry/radsquad',
          active: true
        }  
      ]
    },
    {
      name: 'Hospitals',
      active: true,
      icon: 'users',
      children: [
        {
          name: 'Add',
          to: '/hospital/add',
          active: true
        },
        {
          name: 'List',
          to: '/hospital/list',
          active: true
        }  
      ]
    },
    {
      name: 'Doctors',
      active: true,
      icon: 'users',
      children: [
        {
          name: 'Add',
          to: '/doctor/add',
          active: true
        },
        {
          name: 'List',
          to: '/doctor/list',
          active: true
        }  
      ]
    },
    {
      name: 'Patients',
      active: true,
      icon: 'users',
      children: [
        {
          name: 'Add',
          to: '/patient/add',
          active: true
        },
        {
          name: 'List',
          to: '/patient/list',
          active: true
        }  
      ]
    },
    {
      name: 'Reports',
      active: true,
      icon: 'file',
      children: [
        {
          name: 'Add',
          to: '/report/add',
          active: true
        },
        {
          name: 'List',
          to: '/report/list',
          active: true
        },
        {
          name: 'Log',
          to: '/report/log',
          active: true
        }    
      ]
    },
    {
      name: 'Developers',
      active: true,
      icon: 'code',
      children: [
        {
          name: 'API Status',
          active: true,
          icon: 'link',
          to: '/other',
        },     
      ]
    },
     
  ]
};
 

export default [
  dashboardRoutes,
  masterRoutes 
];

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import createMarkup from 'helpers/createMarkup'; 
import { BaseUrl, showDBdatetime, timeAgo } from '../../includes/Helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const Notification = ({
  entrydate,
  className,
  unread,
  flush,
  task_title,
  notification_id,
  is_extension,
  task_id,
  task_comment_id
}) => (
  <Link
    className={classNames(
      'notification',
      { 'notification-unread': is_extension == 1 ? true : false, 'notification-flush': flush },
      className
    )}
    to={is_extension == 1 ? `/extension_request/view/${task_id}/${task_comment_id}` : `/notification/view/${task_id}/${notification_id}`}
  >

    <div className="notification-avatar">
      {is_extension == 1 ?
        <FontAwesomeIcon icon={faInfoCircle} className="me-2 text-danger  fs-10" />
        :
        <FontAwesomeIcon icon={faBell} className="me-2 text-primary fs-10" />
      }
    </div>
    <div className="notification-body">
      <p className="mb-1 fw-semibold" dangerouslySetInnerHTML={createMarkup(task_title)} />
      <span className="notification-time">
        {timeAgo(entrydate)}
      </span>
    </div>
  </Link>
);

Notification.propTypes = {
  entrydate: PropTypes.string.isRequired,
  className: PropTypes.string,
  unread: PropTypes.bool,
  flush: PropTypes.bool,
  task_title: PropTypes.node,
  notification_id: PropTypes.node,
  is_extension: PropTypes.node,
  task_id: PropTypes.node,
  task_comment_id: PropTypes.node,
};

Notification.defaultProps = { unread: false, flush: false };

export default Notification;

import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { Button } from 'react-bootstrap';
import { ApiCall } from '../../../includes/Webservice';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate } from '../../../includes/Helper';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FalconCardHeader from 'components/common/FalconCardHeader';
const ReportType = () => {
    const params = useParams();

    return (
        <Row className="g-3">
            <FalconComponentCard>
                <FalconCardHeader
                    title={params.type_id ? "Edit Report Type" : "Add Report Type"}
                    light={false}
                    endEl={<Breadcrumb className='fs-11'>
                        <Breadcrumb.Item active><Link to="/dashboard"> Dashboard </Link></Breadcrumb.Item>
                        <Breadcrumb.Item active><Link to="/reporttype/list"> Report Type  </Link></Breadcrumb.Item> 
                        <Breadcrumb.Item active>
                       {params.type_id  ? 'Edit' :'Add'}
                        </Breadcrumb.Item>
                    </Breadcrumb>}
                />

                <FalconComponentCard.Body>
                    <FormExample typeid={params.type_id} />
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </Row>
    );
};

function FormExample({ typeid }) {

    const [validated, setValidated] = useState(false);
    const [report_type, setReportType] = useState('');
    const [wallet, setWallet] = useState('');
    const [type_id, setTypeId] = useState('');
    const baseurl = BaseUrl();
    const navigate = useNavigate();
    const controller = new AbortController();
    const [isloading, setLoad] = useState(false);

    React.useEffect(() => {
        if (typeid)
        editData(typeid);
    }, [])

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === true) {
            saveData();
        }
        setValidated(true);
    };

    const editData = async (type_id) => {
 
        const data ={
            type_id:type_id 
        } 
        const result = await ApiCall(baseurl + "/reporttype/get", "post",  1,0, 0, data, '', controller);

        if (result) {
            // setPageLoad(false)
            if (result.status == true) {             
                setTypeId(result.data._id)
                setReportType(result.data.report_type)
                setWallet(result.data.wallet) 
            } else {
                navigate('/');
                toast.error(result.message);
            }
        } else {
            // setPageLoad(false)
            toast.error(`Some error occured, try again later` );
            navigate('/');
        }

    }

    const saveData = async () => {
 
        if (report_type.trim() == '') {
            toast.error('Please enter all required fields');
            return false;
        }

        if (type_id == '')
            var url = baseurl + "/reporttype/add";
        else
            var url = baseurl + "/reporttype/update";
            
       
        setLoad(true);

        const data ={
            report_type:report_type,
            wallet:wallet,
            type_id : type_id
        } 
        
        const result = await ApiCall(url, "post",  1,0, 0, data, '', controller);
  
        if (result) {
            setLoad(false);
            if (result.status == true) {               
                toast.success(result.message);
                navigate('/reporttype/list');
            }else{
                toast.error(result.message);
            }
        } else {
            setLoad(false);
            toast.error(`Some error occured, try again later` );
            navigate('/');
        }

    } 

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="report_type">
                    <Form.Label>Report Type <span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                        required
                        type="text"
                        maxLength={100}
                        placeholder="Report Type"
                        defaultValue={report_type}
                        onChange={(e) => setReportType(e.target.value)}
                    />
                </Form.Group>
           
                <Form.Group as={Col} md="6" controlId="wallet">
                    <Form.Label>Token  <span className='text-danger'>*</span></Form.Label>

                    <Form.Control
                        required
                        type="number"
                        maxLength={100}
                        placeholder="Enter number only"
                        defaultValue={wallet}
                        onChange={(e) => setWallet(e.target.value)}
                    />
                   
                </Form.Group> 
            </Row>

            <Form.Group className="mb-12 text-center">
                <Button disabled={isloading} variant="outline-primary" type="submit">  {isloading ? 'Loading…' : 'Submit'}</Button>
            </Form.Group>

        </Form>
    );
}

export default ReportType;

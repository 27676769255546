import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import { ApiCall } from './../includes/Webservice';
import { BaseUrl, encryptAES } from './../includes/Helper';
import Spinner from 'react-bootstrap/Spinner'; 
const ForgetPasswordForm = () => {
  // State
  const [username, setUsername] = useState('');
  const baseurl = BaseUrl();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [isloading, setLoad] = useState(false);

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();
    if (username) {
       
      const data = {
        username : username
      }
      setLoad(true);
      const result = await ApiCall(baseurl + "/user/forgot-pass", "post", 0,0,0, data);
  
      if (result) {
        setLoad(false);
        if (result.status == true) {
         
          navigate('/')
  
          toast.success(`${result.message}`, {
            theme: 'colored'
          });
  
        } else {
  
          toast.error(`${result.message}`, {
            theme: 'colored'
          });
  
        }
      } else {
        setLoad(false);
        toast.error(`Some error occured, try again later`, {
          theme: 'colored'
        });
      }


    }
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Username'}
          value={username}
          name="username"
          onChange={({ target }) => setUsername(target.value)}
          type="text"
        />
      </Form.Group>

      <Form.Group className="mb-3">
 
        <Button className="w-100" type="submit" disabled={!username || isloading}>

        {isloading ? 
          <>
          <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className='me-2'
              /> Please wait..</>
            : 'Send New Password'} 

          
        </Button>
      </Form.Group>

      <Link className="fs-10 text-600" to="/">
        I have my username and password, need to login
        <span className="d-inline-block ms-1"> &rarr;</span>
      </Link>
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;

import React from 'react';
import ForgetPasswordForm from './ForgetPasswordForm';
import bgImg from 'assets/img/generic/17.jpg';
import AuthSplitLayout from 'layouts/AuthSplitLayout';

import AuthCardLayout from 'layouts/AuthCardLayout';

const ForgetPassword = () => {
  return (
    <AuthCardLayout>
      <h4 className="mb-0"> Forgot your password?</h4>
      <p className="mb-0">Enter your username and we'll send you a new password.</p>
      <ForgetPasswordForm layout="card" />
    </AuthCardLayout>
  );
};

/*
const ForgetPassword = () => {
  return (
    <AuthSplitLayout
      bgProps={{ image: bgImg, position: '50% 76%', overlay: true }}
    >
      <div className="text-center">
        <h4 className="mb-0"> Forgot your password?</h4>
        <small>Enter your username and we'll send you a new password.</small>
        <ForgetPasswordForm layout="split" />
      </div>
    </AuthSplitLayout>
  );
}; */

export default ForgetPassword;

import React, { useState } from 'react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Tab, Nav, Row, Col, Table,Card, Form, Button } from 'react-bootstrap';
import { ApiCall } from './../includes/Webservice';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate ,showFileType, ValidFileType ,FileUrlPath } from './../includes/Helper';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { checkTokenFunction } from './../includes/CheckToken'; 
const ChangePassword = () => {
  const [validated, setValidated] = useState(false);
  const controller = new AbortController();
  const [isloading, setLoad] = useState(false);
  const baseurl = BaseUrl();
  const navigate = useNavigate();
  checkTokenFunction();
    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      });
    
      const handleChange = e => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
      };
    
      const handleSubmit = e => {
        e.preventDefault(); 
        e.stopPropagation();

        const form = e.currentTarget;

        if (form.checkValidity() === true) {
            saveData();
        }

        setValidated(true);
      };

      const saveData = async () => {
 
        if (formData.oldPassword.trim() == '' || formData.newPassword.trim() == '' || formData.confirmPassword.trim() == '' ) 
        {
            toast.error('Please enter all required fields');
            return false;
        }

        if(formData.newPassword.trim() !=  formData.confirmPassword.trim() )
        {
          toast.error('New password & confirm password does not match ');
          return false;

        }
        var url = baseurl + "/user/change-pass";
          
        const data = {
          current_password : formData.oldPassword,
          new_password : formData.newPassword,
          confirm_password:formData.confirmPassword
        } 
        setLoad(true);
        const result = await ApiCall(url, "post",  1, 0, 0, data, '', controller);
        
        if (result) {
            setLoad(false);
            if (result.status == true) {               
                toast.success(result.message); 
            }else{
                toast.error(result.message);
            }
        } else {
            setLoad(false);
            toast.error(`Some error occured, try again later` );
            navigate('/');
        }

    } 
  return (  
        <Row>
              <Col lg={6} sm={12}> 
            <Card className="mb-3">
                <FalconCardHeader title="Change Password" />
                <Card.Body className="bg-body-tertiary">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="oldPassword">
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control
                        required
                        type="password"
                        value={formData.oldPassword}
                        name="oldPassword"
                        onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="newPassword">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                        required
                        type="password"
                        value={formData.newPassword}
                        name="newPassword"
                        onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="confirmPassword">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                        required
                        type="password"
                        value={formData.confirmPassword}
                        name="confirmPassword"
                        onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-12 text-center">
                    <Button disabled={isloading} variant="outline-primary" type="submit">  {isloading ? 'Loading…' : 'Update Password'}</Button>
                    </Form.Group>
                    </Form>
                </Card.Body>
                </Card> 
            </Col>
            <Col lg={6} sm={12}>  </Col>
    </Row> 
  );
};

export default ChangePassword;

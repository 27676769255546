import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import EntryLayout from '../layouts/EntryLayout';
import Login from 'pages/Login';

import MyProfile from 'pages/MyProfile';
import ForgetPassword from 'pages/ForgetPassword';
import ChangePassword from 'pages/ChangePassword';
import ChangeLogo from 'pages/ChangeLogo';
import ProfileEdit from 'pages/ProfileEdit';
import Registration from 'pages/Registration'; 
import PaymentForm from 'pages/PaymentForm';
import AddUser from 'pages/AddUser';

import Dashboard from 'components/identifyx/master/Dashboard';

import CategoryList from 'components/identifyx/pages/CategoryList';
import Category from 'components/identifyx/pages/Category';

import ReportTypeList from 'components/identifyx/pages/ReportTypeList';
import ReportType from 'components/identifyx/pages/ReportType';

import HospitalList from 'components/identifyx/pages/HospitalList';
import Hospital from 'components/identifyx/pages/Hospital';

import DoctorList from 'components/identifyx/pages/DoctorList';
import Doctor from 'components/identifyx/pages/Doctor';

import Patient from 'components/identifyx/pages/Patient';
import PatientList from 'components/identifyx/pages/PatientList';

import WalletList from 'components/identifyx/pages/WalletList';
import MyWalletList from 'components/identifyx/pages/MyWalletList';
import Wallet from 'components/identifyx/pages/Wallet';

import ReportList from 'components/identifyx/pages/ReportList';
import ReportLog from  'components/identifyx/pages/ReportLog';
import Report from 'components/identifyx/pages/Report';
import ReportView from 'components/identifyx/pages/ReportView';

import MyPaymentList from 'components/identifyx/pages/MyPaymentList';
import PaymentList from 'components/identifyx/pages/PaymentList';
import PaymentNow from  'components/identifyx/pages/PaymentNow';
import PaymentStatus from  'components/identifyx/pages/PaymentStatus';

import Contact from 'components/identifyx/pages/Contact';
import Other from 'components/identifyx/pages/Other';
import Video from 'components/identifyx/pages/Video';
import CountryWiseUser from 'components/identifyx/pages/CountryWiseUser';
import ReportLogView from 'components/identifyx/pages/ReportLogView';
import Maintenance from 'pages/Maintenance';
import Setting from 'components/identifyx/pages/Setting';
import ApiUrlList from 'components/identifyx/pages/ApiUrlList';
import ApiUrl from 'components/identifyx/pages/ApiUrl';
import QuickReport from 'components/identifyx/pages/QuickReport';
import IdentifyxEnquiry from 'components/identifyx/pages/IdentifyxEnquiry';
import RadsquadEnquiry from 'components/identifyx/pages/RadsquadEnquiry';

 //http://identifyx.in/
const main = () => { 
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Registration />} />
            <Route path="/forgot-password" element={<ForgetPassword />} />

            <Route element={<MainLayout />}>

                {/* MASTER */}
                <Route path="/dashboard" element={<Dashboard />} /> 
                <Route path="/myprofile" element={<MyProfile />} />
                <Route path="/editprofile" element={<ProfileEdit />} />
                <Route path="/changepass" element={<ChangePassword />} />
                <Route path="/changelogo" element={<ChangeLogo />} />
                <Route path="/adduser" element={<AddUser />} />
                
                <Route path="/category/list" element={<CategoryList />} />
                <Route path="/category/add" element={<Category />} />
                <Route path="/category/edit/:category_id" element={<Category />} />

                <Route path="/apiurl/list" element={<ApiUrlList />} />
                <Route path="/apiurl/add" element={<ApiUrl />} />
                <Route path="/apiurl/edit/:api_id" element={<ApiUrl />} />

                <Route path="/reporttype/list" element={<ReportTypeList />} />
                <Route path="/reporttype/add" element={<ReportType />} />
                <Route path="/reporttype/edit/:type_id" element={<ReportType />} />
               
                <Route path="/hospital/list" element={<HospitalList />} />
                <Route path="/hospital/add" element={<div><Hospital /></div>} />
                <Route path="/hospital/edit/:hospital_id" element={<Hospital />} />

                <Route path="/doctor/list" element={<DoctorList />} />
                <Route path="/doctor/add" element={<div><Doctor /></div>} />
                <Route path="/doctor/edit/:doctor_id" element={<Doctor />} />

                <Route path="/my-wallet-list" element={<MyWalletList />} />
                <Route path="/wallet/list" element={<WalletList />} />
                <Route path="/wallet/add/:hospital_id" element={<Wallet />} />
                
                <Route path="/patient/list" element={<PatientList />} />
                <Route path="/patient/add" element={<div><Patient /></div>} />
                <Route path="/patient/edit/:patient_id" element={<Patient />} />

                <Route path="/report/quick" element={<QuickReport />} />
                <Route path="/report/list" element={<ReportList />} />
                <Route path="/report/add" element={<Report />} /> 
                <Route path="/report/view/:report_id" element={<ReportView/>} /> 
                <Route path="/report/log" element={<ReportLog />} />
                <Route path="/report/log/view/:report_id" element={<ReportLogView/>} /> 

                <Route path="/paystatus/:paymentId" element={<PaymentStatus />} />
                <Route path="/paynow" element={<PaymentNow />} /> 
                <Route path="/my-transaction" element={<MyPaymentList/>} />
                <Route path="/transaction/list" element={<PaymentList />} />

                <Route path="/contact" element={<Contact />} /> 
                <Route path="/other" element={<Other />} /> 
                <Route path="/videos" element={<Video />} /> 
                <Route path="/countrywise" element={<CountryWiseUser />} /> 

                <Route path="/enquiry/identifyx" element={<IdentifyxEnquiry />} /> 
                <Route path="/enquiry/radsquad" element={<RadsquadEnquiry />} /> 

                <Route path="/maintenance" element={<Maintenance />} /> 
                <Route path="/setting" element={<Setting />} /> 
                

            </Route>

            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );
};

export default main;

import React, { useState,useEffect } from 'react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Tab, Nav, Row, Col, Table,Card, Form, Button } from 'react-bootstrap';
import { ApiCall } from './../includes/Webservice';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate ,showFileType, ValidFileType ,FileUrlPath } from './../includes/Helper';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';

import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { nanoid } from 'nanoid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkTokenFunction } from './../includes/CheckToken'; 
const ChangeLogo = () => {
  const [validated, setValidated] = useState(false);
  const controller = new AbortController();
  const [isloading, setLoad] = useState(false);
  const baseurl = BaseUrl();
  const navigate = useNavigate();
  const [selectedfile, SetSelectedFile] = useState([]);
  const [Files, SetFiles] = useState([]);
  const [logo_file, SetLogoFile] = useState('');
  const [logo_name, SetLogoName] = useState('');
  const [isPageloading, setPageLoad] = useState(false);
  checkTokenFunction();
    useEffect(() => {
        onLoad(); 
        return () => {
        // Anything in here is fired on component unmount.
        //  console.log('Unmount Component')
        //  controller.abort();
        }

    }, [])

      const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const onLoad = async () => {
        setPageLoad(true)
        
       const data ={ } 
        const result = await ApiCall(baseurl + "/user/get", "post",  1,0, 0, data, '', controller);
    
        if (result) { 
          setPageLoad(false)
          if (result.status ==  true) {  
                //console.log(result.file_data)  
                if(result.file_data.file) 
                SetLogoFile(result.file_data.file)                
                if(result.file_data.name) 
                SetLogoName(result.file_data.name) 
          } else { 
          }
        } else {
          setPageLoad(false)
        } 
    
      }

      const onFileChange = (e) => {
        // --For Multiple File Input

        if(selectedfile.length > 0)
        {
            toast.error('Only 1 photo can be added, if want to add new photo remove old one');
            return false;
        }
        let images = [];
        for (let i = 0; i < e.target.files.length; i++) {
            images.push((e.target.files[i]));
            let reader = new FileReader();
            let file = e.target.files[i];
            // console.log(e.target.files[i].type)

            if (!(ValidFileType(e.target.files[i].type))) {
                //alert("File type not allowed") 
                toast.error('File type not allowed');
                return false;
            }
            reader.onloadend = () => {
                SetSelectedFile((preValue) => {
                    return [
                        ...preValue,
                        {
                            id: nanoid(),
                            file: e.target.files[i],
                            filename: e.target.files[i].name,
                            filetype: e.target.files[i].type,
                            fileimage: reader.result,
                            //datetime: e.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
                            filesize: filesizes(e.target.files[i].size)
                        }
                    ]
                });
            }
            if (e.target.files[i]) {
                reader.readAsDataURL(file);
            }
        }
    }

    const RemoveFile = (id) => {
        //if(window.confirm("Are you sure you want to remove this file?")){
        const result = selectedfile.filter((data) => data.id !== id);
        SetSelectedFile(result);  
    }
    
      const handleSubmit = e => {
        e.preventDefault(); 
        e.stopPropagation();
 
        if( selectedfile.length > 0)
        {
            saveData();
        }else{
           toast.error("Please select file")    ;
        }
         

        setValidated(true);
      };

      const saveData = async () => {
 
        
        var url = baseurl + "/user/update-logo";

        let formData = new FormData()  
        formData.append('filupload', selectedfile.length)
        formData.append('uploadfile', selectedfile[0].file)

        const udata =  getUserStorageData();  
        formData.append('user_id', udata.user_id)
        formData.append('user_name', udata.user_name)
        formData.append('user_type', udata.user_type)

        const data = formData;   

        setLoad(true);
        const result = await ApiCall(url, "post",  1, 0, 1, data, '', controller);
        
        if (result) {
            setLoad(false);
            if (result.status == true) {               
                toast.success(result.message); 
                 onLoad()
                 SetSelectedFile([])
            }else{
                toast.error(result.message);
            }
        } else {
            setLoad(false);
            toast.error(`Some error occured, try again later` );
            navigate('/');
        }

    } 
  return (  
        <Row>
              <Col lg={6} sm={12}> 
            <Card className="mb-3">
                <FalconCardHeader title="Change Logo" />
                <Card.Body className="bg-body-tertiary">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                     

                     <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="logo"> 
                <Form.Label>Hospital Logo </Form.Label>

                {logo_file != '' ?
                        <div className="kb-attach-box mb-3">
                            
                                <div className="file-atc-box"  >
                                    {
                                        logo_name.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                            <div className="file-image">     <img   src={`${logo_file}`}   />  
                                            </div> :
                                            <div className="file-image">  <FontAwesomeIcon icon="file-alt" /></div>
                                    }
                                    <div className="file-detail">
                                        <h6>{logo_name}</h6>
                                        <p></p>
                                        <div className="file-actions">
                                            
                                            <a target='_blank'  href={logo_file} className="file-action-btn" download={logo_name}>Download</a>
                                        </div>
                                    </div>
                                </div>
                                     
                        </div>
                        : ''}
                
                    <div className="kb-file-upload">
                        <div className="file-upload-box">
                            <input type="file" id="fileupload" className="file-upload-input" onChange={onFileChange}   />

                            <span> <img src={cloudUpload} alt="" width={25} className="me-2" /> Drag and drop or <span className="file-link">Choose your file</span> <br /><small>{showFileType()}</small></span>
                        </div>
                    </div>
                
                
                    <div className="row kb-attach-box mb-3">
                        {
                            selectedfile.map((data, index) => {
                                const { id, filename, fileimage, filesize } = data;
                                return (
                                    <div className="col-md-12 file-atc-box" key={id}>
                                        {
                                            filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                                <div className="file-image"> <img src={fileimage} alt="" /></div> :
                                                <div className="file-image">
                                                    <FontAwesomeIcon icon="file-alt" />
                                                </div>
                                        }
                                        <div className="file-detail">
                                            <h6>{filename}</h6>
                                            <p></p>
                                            <p><span>Size : {filesize}</span>{/*}<span className="ml-2">Modified Time : {datetime}</span>{*/}</p>
                                            <div className="file-actions">
                                                <button type="button" className="file-action-btn" onClick={() => RemoveFile(id)}>Remove</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    
                   
                    
                        </Form.Group>
                </Row>  
                    
                    <Form.Group className="mb-12 text-center">
                    <Button disabled={isloading} variant="outline-primary" type="submit">  {isloading ? 'Loading…' : 'Upload'}</Button>
                    </Form.Group>
                    </Form>
                </Card.Body>
                </Card> 
            </Col>
            <Col lg={6} sm={12}>  </Col>
    </Row> 
  );
};

export default ChangeLogo;

import { BaseUrl } from './Helper'; 
import { ApiCall } from './Webservice'; 
import {  useNavigate } from 'react-router-dom';
export async function checkTokenFunction(){ 
    const navigate = useNavigate();
    const token = localStorage.getItem('abs_token'); 
    const data = {
      token : token 
    }
    const baseurl =BaseUrl()
    const result = await ApiCall(baseurl + "/user/checktoken", "post", 0,0,0, data);

    if (result) {
    /// console.log(result)
      if (result.status == false) { 
        localStorage.clear(); 
        navigate('/');       
      }
    } else { 
      localStorage.clear(); 
      navigate('/');      
    } 
}


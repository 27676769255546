import React, { useState ,useEffect} from 'react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Tab, Nav, Row, Col, Table,Card, Form, Button } from 'react-bootstrap';
import { ApiCall } from './../includes/Webservice';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate ,showFileType, ValidFileType ,FileUrlPath ,states_array ,country_array } from './../includes/Helper';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { checkTokenFunction } from './../includes/CheckToken'; 
const AddUser = () => {
    const [validated, setValidated] = useState(false);
    const controller = new AbortController();
    const [isloading, setLoad] = useState(false);
    const baseurl = BaseUrl();
    const navigate = useNavigate();
    const [isPageloading, setPageLoad] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '', 
        state: '',
        country: '', 
        hospital_name :'',        
        usertype:'MARKETING_USER',
        username:'',
        password:''
      });

      checkTokenFunction();

      useEffect(() => {
       
        return () => {
          // Anything in here is fired on component unmount.
          //  console.log('Unmount Component')
          //  controller.abort();
        }
    
      }, [])
 
      const handleChange = e => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
      };
    
      const handleSubmit = e => {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;

        if (form.checkValidity() === true) {
            saveData();
        }

        setValidated(true);
      };

      const saveData = async () => {
 
        if (formData.firstName.trim() == '' || formData.lastName.trim() == '' || formData.email.trim() == '' || formData.mobile.trim() == '' || formData.state == '' || formData.country == '' || formData.username.trim() == '' || formData.password.trim() == '' ) 
        {
            toast.error('Please enter all required fields');
            return false;
        }

         
        var url = baseurl + "/user/add";
          
        const data = {
            first_name : formData.firstName,
            last_name : formData.lastName,
            email:formData.email,
            mobile : formData.mobile,             
            state : formData.state,
            country:formData.country, 
            hospital_name : formData.firstName  ,
            usertype: formData.usertype,
            username:formData.username,
            password:formData.password,
        } 
        setLoad(true);
        const result = await ApiCall(url, "post",  1, 0, 0, data, '', controller);
        
        if (result) {
            setLoad(false);
            if (result.status == true) {               
                toast.success(result.message); 
                navigate('/dashboard');
            }else{
                toast.error(result.message);
            }
        } else {
            setLoad(false);
            toast.error(`Some error occured, try again later` );
            navigate('/');
        }

    } 
    
  return (  
        <Row>
            <Col lg={12} sm={12}> 
            <Card>
            <FalconCardHeader title="Add User" />
            <Card.Body className="bg-body-tertiary">
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                <Row className="mb-3 g-3">
                    <Form.Group as={Col} lg={6} controlId="firstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                    required
                        type="text"
                        placeholder="First Name"
                        value={formData.firstName}
                        name="firstName"
                        onChange={handleChange}
                    />
                    </Form.Group>

                    <Form.Group as={Col} lg={6} controlId="lastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                    required
                        type="text"
                        placeholder="Last Name"
                        value={formData.lastName}
                        name="lastName"
                        onChange={handleChange}
                    />
                    </Form.Group>
                
                    <Form.Group as={Col} lg={4} controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                    required
                        type="email"
                        placeholder="Email"
                        value={formData.email}
                        name="email"
                        onChange={handleChange}
                    />
                    </Form.Group>

                    <Form.Group as={Col} lg={4} controlId="mobile">
                    <Form.Label>Mobile</Form.Label>
                    <Form.Control
                    required
                        type="text"
                        placeholder="Mobile"
                        maxLength={10}
                        value={formData.mobile}
                        name="mobile"
                        onChange={handleChange}
                    />
                    </Form.Group>
                
               

                    <Form.Group as={Col} lg={4} controlId="country">
                    <Form.Label>Country</Form.Label>
                      
                    <Form.Select
                        required
                        type="text"                    
                        placeholder="Country"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                    >   
                        {
                            country_array.map((item, index) => (
                                <option key={index + 1} value={item}>{item} </option>
                            ))
                        }
                        </Form.Select> 
                    </Form.Group>

                    <Form.Group as={Col} lg={4} controlId="state">
                    <Form.Label>State/Province</Form.Label>

                     
                    {formData.country=='India' ?
                    <Form.Select
                        required
                        type="text"                    
                        placeholder="State"
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                    >  
                    <option value={''}>Select</option>
                        {
                            states_array.map((item, index) => (
                                <option key={index + 1} value={item}>{item} </option>
                            ))
                        }
                        </Form.Select> 
                        :
                        <Form.Control
                            required
                            type="text"
                            placeholder="State"
                            value={formData.state}
                            name="state"
                            onChange={handleChange}
                        /> 
                        }
                    </Form.Group>
 
                    <Form.Group as={Col} lg={4} controlId="usertype">
                    <Form.Label>User Type</Form.Label>
                      
                    <Form.Select
                        required
                        type="text"                    
                        placeholder="User Type"
                        name="usertype"
                        value={formData.usertype}
                        onChange={handleChange}
                    >                           
                            <option key={1} value={'MARKETING_USER'}>{'MARKETING_USER'} </option>
                            <option key={2} value={'SUPERADMIN'}>{'SUPERADMIN'} </option>
                           
                        </Form.Select> 
                    </Form.Group>

                    <Form.Group  as={Col} lg={4}  controlId="username">
                    <Form.Label>Username <span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name='username'
                        maxLength={200}
                        placeholder="Username"
                        value={formData.username}
                        onChange={handleChange}
                    />                   
                </Form.Group>
            
                <Form.Group as={Col} lg={4}   controlId="password">
                    <Form.Label>Password <span className='text-danger'>*</span></Form.Label>
                    <Form.Control 
                        required
                        type="text"
                        maxLength={200}
                        name='password'
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleChange}
                    />                   
                </Form.Group>

                </Row>
 
                <Form.Group className="mb-12 text-center">
                    <Button disabled={isloading} variant="outline-primary" type="submit">  {isloading ? 'Loading…' : 'Submit'}</Button>
                    </Form.Group>  
                </Form>
            </Card.Body>
            </Card>
            </Col>
          
    </Row> 
  );
};

export default AddUser;

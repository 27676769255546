import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { Button } from 'react-bootstrap';
import { ApiCall } from '../../../includes/Webservice';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate } from '../../../includes/Helper';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FalconCardHeader from 'components/common/FalconCardHeader';
import WalletList from './WalletList';

const Wallet = () => {
    const params = useParams();

    return (
        <Row className="g-3">
            <FalconComponentCard>
                <FalconCardHeader
                    title={params.wallet_id ? "Edit Hospital / Doctor Wallet" : "Add Hospital / Doctor Wallet"}
                    light={false}
                  
                />

                <FalconComponentCard.Body>
                    <FormExample walletid={params.wallet_id} hospitalid={params.hospital_id} />
                </FalconComponentCard.Body>
                <FalconComponentCard.Body>
                    <WalletList hospitalid={params.hospital_id} />
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </Row>
    );
};

function FormExample({ walletid ,hospitalid }) {
   // console.log(hospitalid)
    const [validated, setValidated] = useState(false);
    const [wallet_balance, setWalletBalnce] = useState(0);
    const [remark, setRemark] = useState('');
    const [no_of_patients, setNoOfPatients] = useState(0);
    const [hospital_id, setHospitalId] = useState('');
    const [wallet_id, setWalletId] = useState('');
    const baseurl = BaseUrl();
    const navigate = useNavigate();
    const controller = new AbortController();
    const [isloading, setLoad] = useState(false);
    const [hospital_array,setHospitalArray] = useState([]);
    const [isPageloading, setPageLoad] = React.useState(false);
    React.useEffect(() => {
        onLoad();
        if(hospitalid)
            setHospitalId(hospitalid)
        if (walletid)
        editData(walletid);
    }, [])

    const onLoad = async () => {
        setPageLoad(true)
         const data = {};
         const result = await ApiCall(baseurl + "/wallet/hospital", "post",  1,0, 0, data, '', controller);
        if (result) {
            setPageLoad(false)
            if (result.status == true) {                   
                console.log(result.data)
                 setHospitalArray(result.data)
            } else {
              //  navigate('/');
              toast.info(result.message);
            }
        } else {           
            setPageLoad(false)
            toast.error(`Some error occured, try again later` );
            navigate('/');
        }
    }

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === true) {
            saveData();
        }
        setValidated(true);
    };

    const editData = async (wallet_id) => {
 
        const data ={
            wallet_id:wallet_id 
        } 
        const result = await ApiCall(baseurl + "/wallet/get", "post",  1,0, 0, data, '', controller);

        if (result) {
            // setPageLoad(false)
            if (result.status == true) {             
                setWalletId(result.data._id)
                setHospitalId(result.data.user_id)
                setWalletBalnce(result.data.wallet_balance)
                setNoOfPatients(result.data.no_of_patients) 
                setRemark(result.data.remark)
            } else {
                navigate('/');
                toast.error(result.message);
            }
        } else {
            // setPageLoad(false)
            toast.error(`Some error occured, try again later` );
            navigate('/');
        }

    }

    const saveData = async () => {
 
        if (wallet_balance.toString().trim() == '' || hospital_id.toString()=='' || remark.trim()=='') {
            toast.error('Please enter all required fields');
            return false;
        }

        if (wallet_id == '')
            var url = baseurl + "/wallet/add";
        else
            var url = baseurl + "/wallet/update";
             
        setLoad(true);

        const data ={
            wallet_balance:wallet_balance, 
            hospital_id : hospital_id,
            remark:remark
        } 
        
        const result = await ApiCall(url, "post",  1,0, 0, data, '', controller);
  
        if (result) {
            setLoad(false);
            if (result.status == true) {               
                toast.success(result.message);
                navigate('/wallet/list');
            }else{
                toast.error(result.message);
            }
        } else {
            setLoad(false);
            toast.error(`Some error occured, try again later` );
            navigate('/');
        } 
    } 

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
                
                 <Form.Group as={Col}  md="4" controlId="hospital_id">
                    <Form.Label>Hospital <span className='text-danger'>*</span></Form.Label>
                    <Form.Select
                        required
                        disabled={hospital_id && true}
                        type="text" 
                        placeholder="Hospital"
                        value={hospital_id}
                        onChange={(e) => setHospitalId(e.target.value)}
                    >
                        <option value={''}>Select</option>
                        {
                            hospital_array.map((item, index) => (
                                <option key={index + 1} value={item._id}>{
                                     item.hospital_name  } </option>
                            ))
                        }
                        </Form.Select>
                </Form.Group>

                <Form.Group as={Col} md="4" controlId="wallet_balance">
                    <Form.Label>Tokens <span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                        required
                        type="number"
                        maxLength={5}
                        placeholder="Wallet Balance"
                        defaultValue={wallet_balance}
                        onChange={(e) => setWalletBalnce(e.target.value)}
                    />
                </Form.Group>

                <Form.Group as={Col} md="4" controlId="remark">
                    <Form.Label> Remark <span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                        required
                        type="text"
                        maxLength={250}
                        placeholder="Remark"
                        defaultValue={remark}
                        onChange={(e) => setRemark(e.target.value)}
                    />
                </Form.Group>
                        {/*}
                <Form.Group as={Col} md="6" controlId="no_of_patients">
                    <Form.Label>No Of Patients <span className='text-danger'>*</span></Form.Label>

                    <Form.Control
                        required
                        type="number"
                        maxLength={100}
                        placeholder="No Of Patients"
                        defaultValue={no_of_patients}
                        onChange={(e) => setNoOfPatients(e.target.value)}
                    />
                   
                </Form.Group> {*/}
            </Row>

            <Form.Group className="mb-12 text-center">
                <Button disabled={isloading} variant="outline-primary" type="submit">  {isloading ? 'Loading…' : 'Submit'}</Button>
            </Form.Group>

        </Form>
    );
}

export default Wallet;

import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { Button } from 'react-bootstrap';
import { ApiCall } from '../../../includes/Webservice';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate ,showFileType, ValidFileType ,FileUrlPath,states_array ,country_array ,setFields ,setNumFields} from '../../../includes/Helper';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FalconCardHeader from 'components/common/FalconCardHeader';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { nanoid } from 'nanoid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'react-bootstrap/Spinner'; 
const Hospital = () => {
    const params = useParams();

    return (
        <Row className="g-3">
            <FalconComponentCard>
                <FalconCardHeader
                    title={params.doctor_id ? "Edit Doctor" : "Add Doctor"}
                    light={false} 
                    endEl={<Breadcrumb className='fs-11'>
                        <Breadcrumb.Item active><Link to="/dashboard"> Dashboard </Link></Breadcrumb.Item>
                        <Breadcrumb.Item active><Link to="/doctor/list"> Doctor  </Link></Breadcrumb.Item> 
                        <Breadcrumb.Item active>
                       {params.doctor_id  ? 'Edit' :'Add'}
                        </Breadcrumb.Item>
                    </Breadcrumb>}
                />

                <FalconComponentCard.Body>
                    <FormExample hospitalid={params.doctor_id} />
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </Row>
    );
};

function FormExample({ hospitalid }) {

    const [validated, setValidated] = useState(false);
    const [first_name, setFirstName] = useState('');
    const [last_name, setlastName] = useState('');
    const [hospital_name, setHospitalName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [pincode, setPincode] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [is_active, setIsActive] = useState(1);
    const [upload_seq_active, setUpSeqActive] = useState(0);
    const [doctor_id, setHospitalId] = useState('');
    const baseurl = BaseUrl();
    const fileurl = FileUrlPath();
    const navigate = useNavigate();
    const controller = new AbortController();
    const [isloading, setLoad] = useState(false);

    const [selectedfile, SetSelectedFile] = useState([]);
    const [Files, SetFiles] = useState([]);
    const [logo_file, SetLogoFile] = useState('');
    const [logo_name, SetLogoName] = useState('');
    
    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    React.useEffect(() => {
        if (hospitalid)
        editData(hospitalid);
    }, [])

    const onFileChange = (e) => {
        // --For Multiple File Input

        if(selectedfile.length > 0)
        {
             
            toast.error('Only 1 photo can be added, if want to add new photo remove old one');
            return false;
        }
        let images = [];
        for (let i = 0; i < e.target.files.length; i++) {
            images.push((e.target.files[i]));
            let reader = new FileReader();
            let file = e.target.files[i];
            // console.log(e.target.files[i].type)

            if (!(ValidFileType(e.target.files[i].type))) {
                //alert("File type not allowed") 
                toast.error('File type not allowed');
                return false;
            }
            reader.onloadend = () => {
                SetSelectedFile((preValue) => {
                    return [
                        ...preValue,
                        {
                            id: nanoid(),
                            file: e.target.files[i],
                            filename: e.target.files[i].name,
                            filetype: e.target.files[i].type,
                            fileimage: reader.result,
                            //datetime: e.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
                            filesize: filesizes(e.target.files[i].size)
                        }
                    ]
                });
            }
            if (e.target.files[i]) {
                reader.readAsDataURL(file);
            }
        }
    }

    const RemoveFile = (id) => {
        //if(window.confirm("Are you sure you want to remove this file?")){
        const result = selectedfile.filter((data) => data.id !== id);
        SetSelectedFile(result);  
    }

    const DeleteFile = async () => {
        if (window.confirm("Are you sure you want to delete this file?")) {
           
            const data = {doctor_id : hospitalid};
            const result = await ApiCall(baseurl + "/doctor/deletefile", "post",  1,0, 0, data, '', controller);
            setLoad(true)
            if (result) {
                setLoad(false)
                if (result.status == true) {
                        console.log(result)
                        toast.success(result.message);
                        editData(hospitalid);
                        SetLogoName('');
                        SetLogoFile('');
                } else {
                  //  navigate('/');
                }
            } else {
                setLoad(false);
                toast.error(`Some error occured, try again later`, {
                    theme: 'colored'
                });
               // navigate('/');
            }
           
        } 
    }

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === true) {
            saveData();
        }
        setValidated(true);
    };

    const editData = async (doctor_id) => {
 
        const data ={
            doctor_id:doctor_id 
        } 
        const result = await ApiCall(baseurl + "/doctor/get", "post",  1,0, 0, data, '', controller);

        if (result) {
            // setPageLoad(false)
            if (result.status == true) {
                
                setHospitalId(result.data._id)
                setFirstName(result.data.first_name)
                setlastName(result.data.last_name)
                setHospitalName(result.data.hospital_name)
                setEmail(result.data.email)
                setMobile(result.data.mobile)
                setAddress(result.data.address)
                setCity(result.data.city)
                setState(result.data.state)
                setCountry(result.data.country)
                setPincode(result.data.pincode)
                setUsername(result.data.username)                
                setIsActive(result.data.is_active) 
                setUpSeqActive(result.data.upload_seq_active) 
                SetLogoFile(result.file_data.file)                
                SetLogoName(result.file_data.name)
                
            } else {
                toast.error(result.message);
                navigate('/');
            }
        } else {
            // setPageLoad(false)
            navigate('/');
            toast.error(`Some error occured, try again later`, {
                theme: 'colored'
              });
        }

    }

    const saveData = async () => {
 
        if (hospital_name.trim() == '' || email.trim() == '' || mobile.trim() == ''|| username.trim() == '' || country =='') {
            toast.error('Please enter all required fields');
            return false;
        }

        if (doctor_id == '')
            var url = baseurl + "/doctor/add";        
        else
            var url = baseurl + "/doctor/update";
        
         
        let formData = new FormData()
        formData.append('doctor_id', doctor_id)
        formData.append('hospital_name', hospital_name)
        formData.append('first_name', first_name)
        formData.append('last_name', last_name)
        formData.append('address', setFields(address))
        formData.append('city',setFields(city))
        formData.append('state', setFields(state)) 
        formData.append('country', country) 
        formData.append('pincode', setFields(pincode))
        formData.append('email', email)
        formData.append('mobile', mobile)
        formData.append('username', username)
        formData.append('password', password)
        formData.append('is_active', setNumFields(is_active))
        formData.append('upload_seq_active', setNumFields(upload_seq_active))        
        formData.append('filupload', selectedfile.length)
        if(selectedfile.length > 0)
        formData.append('uploadfile', selectedfile[0].file)
 
        const udata =  getUserStorageData();  
        formData.append('user_id', udata.user_id)
        formData.append('user_name', udata.user_name)
        formData.append('user_type', udata.user_type)

        const data = formData;   
        
        const result = await ApiCall(url, "post",  1, 0, 1, data, '', controller);
        setLoad(true);
        if (result) {
            setLoad(false);
            if (result.status == true) {               
                toast.success(result.message);
                navigate('/doctor/list');
            }else{
                toast.error(result.message);
            }
        } else {
            setLoad(false);
            toast.error(`Some error occured, try again later`, {
                theme: 'colored'
              });
            navigate('/');
        }

    } 

    const setActive = () => {
        if (is_active == 0)
            setIsActive(1);
        else
            setIsActive(0);
    }

    const setUploadOption = () => {
        if (upload_seq_active == 0)
            setUpSeqActive(1);
        else
            setUpSeqActive(0);
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
                 
                <Form.Group as={Col}  className="mb-2" md="6" controlId="first_name">
                    <Form.Label>Doctor First Name <span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                        required
                        type="text"
                        maxLength={300}
                        placeholder="First Name"
                        defaultValue={first_name}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                </Form.Group>

                <Form.Group as={Col}  className="mb-2" md="6" controlId="last_name">
                    <Form.Label>Doctor Last Name <span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                        required
                        type="text"
                        maxLength={300}
                        placeholder="Last Name"
                        defaultValue={last_name}
                        onChange={(e) => setlastName(e.target.value)}
                    />
                </Form.Group>
                <Form.Group as={Col}  className="mb-2" md="4" controlId="hospital_name">
                    <Form.Label>Hospital/Clinic Name <span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                        required
                        type="text"
                        maxLength={300}
                        placeholder="Hospital/Clinic Name"
                        defaultValue={hospital_name}
                        onChange={(e) => setHospitalName(e.target.value)}
                    />
                </Form.Group>
             
                <Form.Group as={Col}  className="mb-2" md="4" controlId="email">
                    <Form.Label>Email <span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                        required
                        type="email"
                        maxLength={250}
                        placeholder="Email"
                        defaultValue={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />                   
                </Form.Group>
            
                <Form.Group as={Col}  className="mb-2" md="4" controlId="mobile">
                    <Form.Label>Mobile <span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                        required
                        type="text"
                        maxLength={10}
                        placeholder="Mobile"
                        defaultValue={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                    />                   
                </Form.Group>
            
                <Form.Group as={Col}  className="mb-2" md="8" controlId="address">
                    <Form.Label>Address </Form.Label>
                    <Form.Control 
                        type="text"
                        maxLength={500}
                        placeholder="Address"
                        defaultValue={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />                   
                </Form.Group>
           
                <Form.Group as={Col}  className="mb-2" md="4" controlId="city">
                    <Form.Label>City </Form.Label>
                    <Form.Control
                        type="text"
                        maxLength={300}
                        placeholder="City"
                        defaultValue={city}
                        onChange={(e) => setCity(e.target.value)}
                    />                   
                </Form.Group>

                <Form.Group as={Col}  className="mb-2" md="4" controlId="country">
                    <Form.Label>Country <span className='text-danger'>*</span></Form.Label>
                    <Form.Select
                        required
                        type="text"                    
                        placeholder="Country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                    >  
                    <option value={''}>Select</option>
                        {
                            country_array.map((item, index) => (
                                <option key={index + 1} value={item}>{item} </option>
                            ))
                        }
                        </Form.Select>                  
                </Form.Group>
             
                <Form.Group as={Col}  className="mb-2" md="4" controlId="state">
                    <Form.Label>State/Province </Form.Label>
                    {country=='India' ?
                    <Form.Select
                        type="text"                    
                        placeholder="State"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                    >  
                    <option value={''}>Select</option>
                        {
                            states_array.map((item, index) => (
                                <option key={index + 1} value={item}>{item} </option>
                            ))
                        }
                        </Form.Select>   
                    :
                         <Form.Control
                            type="text"
                            maxLength={300}
                            placeholder="State"
                            defaultValue={state}
                            onChange={(e) => setState(e.target.value)}
                        />  
                    }              
                </Form.Group>

                
            
                <Form.Group as={Col}  className="mb-2" md="4" controlId="pincode">
                    <Form.Label>Pincode/Zipcode </Form.Label>
                    <Form.Control 
                        type="text"
                        maxLength={6}
                        placeholder="Pincode"
                        defaultValue={pincode}
                        onChange={(e) => setPincode(e.target.value)}
                    />                   
                </Form.Group>

                <Form.Group as={Col} md="3" controlId="is_active">
                    <Form.Label> Active <span className='text-danger'>*</span></Form.Label>
                    <Form.Check
                            type='switch'
                            id='is_active'
                            label='Tick for Active'
                            onChange={setActive}
                            checked={is_active==1 ? true : false}
                        />                 
                </Form.Group> 

                <Form.Group as={Col} md="3" controlId="upload_seq_active">
                    <Form.Label> Upload MRI/CT Sequences <span className='text-danger'>*</span></Form.Label>
                    <Form.Check
                            type='switch'
                            id='upload_seq_active'
                            label='Tick for Active'
                            onChange={setUploadOption}
                            checked={upload_seq_active==1 ? true : false}
                        />                 
                </Form.Group> 
             
                <Form.Group as={Col} md="3" controlId="username">
                    <Form.Label>Username <span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                        required
                        type="text"
                        maxLength={100}
                        placeholder="Username"
                        defaultValue={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />                   
                </Form.Group>
            
                <Form.Group as={Col} md="3" controlId="password">
                    <Form.Label>Password<small>(If you need to change, then enter only)</small><span className='text-danger'>*</span></Form.Label>
                    <Form.Control 
                        type="text"
                        maxLength={100}
                        placeholder="Password"
                        defaultValue={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />                   
                </Form.Group>
            </Row>
 
            <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="logo"> 
                <Form.Label>Hospital/Clinic Logo </Form.Label>
                {logo_file == '' &&
                    <div className="kb-file-upload">
                        <div className="file-upload-box">
                            <input type="file" id="fileupload" className="file-upload-input" onChange={onFileChange}   />

                            <span> <img src={cloudUpload} alt="" width={25} className="me-2" /> Drag and drop or <span className="file-link">Choose your file</span> <br /><small>{showFileType()}</small></span>
                        </div>
                    </div>
                }
                {logo_file == '' &&
                    <div className="row kb-attach-box mb-3">
                        {
                            selectedfile.map((data, index) => {
                                const { id, filename, fileimage, filesize } = data;
                                return (
                                    <div className="col-md-12 file-atc-box" key={id}>
                                        {
                                            filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                                <div className="file-image"> <img src={fileimage} alt="" /></div> :
                                                <div className="file-image">
                                                    <FontAwesomeIcon icon="file-alt" />
                                                </div>
                                        }
                                        <div className="file-detail">
                                            <h6>{filename}</h6>
                                            <p></p>
                                            <p><span>Size : {filesize}</span>{/*}<span className="ml-2">Modified Time : {datetime}</span>{*/}</p>
                                            <div className="file-actions">
                                                <button type="button" className="file-action-btn" onClick={() => RemoveFile(id)}>Remove</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    }
                   
                    {logo_file != '' ?
                        <div className="kb-attach-box">
                            
                                <div className="file-atc-box"  >
                                    {
                                        logo_name.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                            <div className="file-image">     <img   src={`${logo_file}`}   />  
                                            </div> :
                                            <div className="file-image">  <FontAwesomeIcon icon="file-alt" /></div>
                                    }
                                    <div className="file-detail">
                                        <h6>{logo_name}</h6>
                                        <p></p>
                                        <div className="file-actions">
                                            <button type='button' className="file-action-btn" onClick={() => DeleteFile()}>Delete</button>
                                            <a target='_blank'  href={logo_file} className="file-action-btn" download={logo_name}>Download</a>
                                        </div>
                                    </div>
                                </div>
                                     
                        </div>
                        : ''}
                        </Form.Group>
                </Row> 

            <Form.Group className="mb-12 text-center">
                <Button disabled={isloading} variant="outline-primary" type="submit">   
                {isloading ? 
                <>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className='me-2'
                    /> Loading… </>
                 : 'Submit'}
                
                </Button>
            </Form.Group>

        </Form>
    );
}

export default Hospital;

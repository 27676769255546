import React, { Component } from 'react'
import Default from 'components/dashboards/default';
import HospitalDashboard from 'components/dashboards/default/HospitalDashboard';
import MarketingDashboard from 'components/dashboards/default/MarketingDashboard';
import {  getUserStorageData } from '../../../includes/Helper';
import { checkTokenFunction } from '../../../includes/CheckToken'; 
const Dashboard = () => {

    checkTokenFunction();
    
    if((getUserStorageData().user_type) == 'SUPERADMIN')
    {
        return ( 
                <Default />   
        )
    }else if((getUserStorageData().user_type) == 'HOSPITAL' || (getUserStorageData().user_type) == 'DOCTOR'){
        return ( 
            <HospitalDashboard />  
        
    )

    }else{
          return ( 
                <MarketingDashboard />  
            
        )
    }
}

export default Dashboard;
import axios from 'axios';  
import {getUserStorageData } from './../includes/Helper';

export async function ApiCall(url,method,withtoken,restype,uploadfile=0,data='',controller='') 
{   
    //withtoken  =0,1
    
    var token = ''; 
      
   const userdata = localStorage.getItem('abs_userdata');   
   
   if(userdata && withtoken==1)
   {
     const udata =  getUserStorageData();  
     data.user_id = udata.user_id;
     data.user_name = udata.user_name;   
     data.user_type = udata.user_type;   
   }   

   if(localStorage.getItem('abs_token'))
      token =localStorage.getItem('abs_token');
   
   url = url ;
   var cntType = '';
    if(uploadfile == 1)
         cntType= 'multipart/form-data';
    else    
         cntType= 'application/json';

    if( withtoken == 0 )
    {
        var headers = { 
            'Content-Type': cntType 
        } ;
    }else{
        var headers = { 
            'Content-Type': cntType,
            'Authorization' : 'bearer '+token 
        } ;
    }
    
    //'Content-Type': 'multipart/form-data' for file upload

    if(restype ==0 || restype =='')
        var restype_ = 'json';
     else   
        var restype_  = 'blob';
 
    //const controller = new AbortController();
    
    try{
      const response = await axios({            
            url :url, 
            method:method,
            responseType: restype_, 
            data:data,
            timeout: 25 * 60 * 1000,   // 25 min
            headers: headers, // if this open then it tms api will not work
            withCredentials: true, // Now this is was the missing piece in the client side 
            signal:controller.signal  
             })  
            .then((response)=>{ 
                return response.data;
            })
            .catch(function (error) {
                // handle error  
                return error.response.data; 
            })
            .finally(function () {
                
            });    

      if(response.status==200)
            return response.data;
       else 
            return response;
        
    } catch (err) {
       //  console.log(err); 
    }   
       
}  
import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';

import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { BaseUrl, getUserStorageData, showDBdatetime, convertDBdate, timeAgoSinceDate } from '../../../includes/Helper';
import { ApiCall } from '../../../includes/Webservice';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, Link } from 'react-router-dom';

function DoctorReports() {

    const navigate = useNavigate();
    const baseurl = BaseUrl();
    const controller = new AbortController(); 
    const [data, setData] = React.useState([]);
    const [isPageloading, setPageLoad] = React.useState(true);

    React.useEffect(() => {

        getData();
        return () => {
            
            controller.abort();
        }

    }, [])

    const getData = async () => {
        setPageLoad(true)
 
        const data = {};
        const result = await ApiCall(baseurl + "/dashboard/doctor_report", "post",  1,0, 0, data, '', controller);

        if (result) {
            setPageLoad(false)
            if (result.status == true) { 
              //  console.log(result.data)
                setData(result.data)  
            } else { 
            }
        } else {
            setPageLoad(false)
        }
    }

    const columns = [          
        
        { 
            accessor: 'first_name',
            Header: 'First Name',
            headerProps: { className: 'text-900' }  
        },
        { 
            accessor: 'last_name',
            Header: 'Last Name',
            headerProps: { className: 'text-900' }  
        },
        {
            accessor: 'no_of_reports',
            Header: 'No. of Report',
            headerProps: { className: 'text-900' } 
        }, 
         
        
    ];  

    return (
        <> 
            <AdvanceTableWrapper
                columns={columns}
                data={data}
                sortable
                pagination
                perPage={10}
            >
                <Card className="h-100">
                    <Card.Header>
                        <Row className="flex-between-center mb-3">
                            <Col xs="auto" sm={6} lg={7}>
                                <h6 className="mb-0 text-nowrap py-2 py-xl-0">
                                Doctors Generated Reports
                                </h6>
                            </Col>
                            <Col xs="auto" sm={6} lg={5}>
                              {/*}  <AdvanceTableSearchBox table placeholder="Search..." />
                              {*/}
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="p-0">
                        {isPageloading == true ?
                            <div className='text-center mb-5 mt-5'>
                                <Spinner animation="grow" />
                            </div>
                            :
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs-10 mb-0 overflow-hidden'
                                }}
                            />
                        }
                    </Card.Body>
                    {/*}
                    <Card.Footer>
                        <div className="mt-3">
                            <AdvanceTableFooter
                                rowCount={data.length}
                                table
                                rowInfo
                                navButtons
                                rowsPerPageSelection
                            />
                        </div>
                    </Card.Footer> {*/}
                </Card>
            </AdvanceTableWrapper >

        </>
    );
}


export default DoctorReports;
